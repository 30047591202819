import {Status as ApiStatus} from '@/gen/api';
import {Status as StatusModel} from '@/models/Status';

export function toStatus(apiStatus: ApiStatus): StatusModel {
    switch (apiStatus) {
        case ApiStatus.Active:
            return 'ACTIVE';
        case ApiStatus.Inactive:
            return 'INACTIVE';
        default:
            return 'UNKNOWN';
    }
}

export function toApiStatus(status: StatusModel): ApiStatus {
    switch (status) {
        case 'ACTIVE':
            return ApiStatus.Active;
        case 'INACTIVE':
            return ApiStatus.Inactive;
        default:
            return ApiStatus.Inactive;
    }
}
