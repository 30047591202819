/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeWindow
 */
export interface TimeWindow {
    /**
     * 
     * @type {Date}
     * @memberof TimeWindow
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeWindow
     */
    end: Date;
}

/**
 * Check if a given object implements the TimeWindow interface.
 */
// @ts-ignore
export function instanceOfTimeWindow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

    return isInstance;
}

// @ts-ignore
export function TimeWindowFromJSON(json: any): TimeWindow {
    return TimeWindowFromJSONTyped(json, false);
}

// @ts-ignore
export function TimeWindowFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeWindow {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

// @ts-ignore
export function TimeWindowToJSON(value?: TimeWindow | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
    };
}

