/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Dimensions } from './Dimensions';
// @ts-ignore
import {
// @ts-ignore
    DimensionsFromJSON,
// @ts-ignore
    DimensionsFromJSONTyped,
// @ts-ignore
    DimensionsToJSON,
} from './Dimensions';
// @ts-ignore
import type { Image } from './Image';
// @ts-ignore
import {
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageFromJSONTyped,
// @ts-ignore
    ImageToJSON,
} from './Image';
// @ts-ignore
import type { PriceCreate } from './PriceCreate';
// @ts-ignore
import {
// @ts-ignore
    PriceCreateFromJSON,
// @ts-ignore
    PriceCreateFromJSONTyped,
// @ts-ignore
    PriceCreateToJSON,
} from './PriceCreate';
// @ts-ignore
import type { ProductCreate } from './ProductCreate';
// @ts-ignore
import {
// @ts-ignore
    ProductCreateFromJSON,
// @ts-ignore
    ProductCreateFromJSONTyped,
// @ts-ignore
    ProductCreateToJSON,
} from './ProductCreate';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { Weight } from './Weight';
// @ts-ignore
import {
// @ts-ignore
    WeightFromJSON,
// @ts-ignore
    WeightFromJSONTyped,
// @ts-ignore
    WeightToJSON,
} from './Weight';

/**
 * 
 * @export
 * @interface SkuCreateRequest
 */
export interface SkuCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SkuCreateRequest
     */
    description: string;
    /**
     * 
     * @type {Dimensions}
     * @memberof SkuCreateRequest
     */
    dimensions?: Dimensions;
    /**
     * 
     * @type {Weight}
     * @memberof SkuCreateRequest
     */
    weight?: Weight;
    /**
     * 
     * @type {number}
     * @memberof SkuCreateRequest
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SkuCreateRequest
     */
    moq?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof SkuCreateRequest
     */
    images?: Array<Image>;
    /**
     * 
     * @type {Status}
     * @memberof SkuCreateRequest
     */
    status?: Status;
    /**
     * 
     * @type {ProductCreate}
     * @memberof SkuCreateRequest
     */
    product?: ProductCreate;
    /**
     * 
     * @type {PriceCreate}
     * @memberof SkuCreateRequest
     */
    price?: PriceCreate;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SkuCreateRequest
     */
    customData?: { [key: string]: string; };
}

/**
 * Check if a given object implements the SkuCreateRequest interface.
 */
// @ts-ignore
export function instanceOfSkuCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

// @ts-ignore
export function SkuCreateRequestFromJSON(json: any): SkuCreateRequest {
    return SkuCreateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SkuCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuCreateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'supplierId': json['supplier_id'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'name': json['name'],
        'description': json['description'],
        'dimensions': !exists(json, 'dimensions') ? undefined : DimensionsFromJSON(json['dimensions']),
        'weight': !exists(json, 'weight') ? undefined : WeightFromJSON(json['weight']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'product': !exists(json, 'product') ? undefined : ProductCreateFromJSON(json['product']),
        'price': !exists(json, 'price') ? undefined : PriceCreateFromJSON(json['price']),
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
    };
}

// @ts-ignore
export function SkuCreateRequestToJSON(value?: SkuCreateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'product_id': value.productId,
        'supplier_id': value.supplierId,
        'external_code': value.externalCode,
        'name': value.name,
        'description': value.description,
        'dimensions': DimensionsToJSON(value.dimensions),
        'weight': WeightToJSON(value.weight),
        'quantity': value.quantity,
        'moq': value.moq,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'status': StatusToJSON(value.status),
        'product': ProductCreateToJSON(value.product),
        'price': PriceCreateToJSON(value.price),
        'custom_data': value.customData,
    };
}

