/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { OrderCreate } from './OrderCreate';
// @ts-ignore
import {
// @ts-ignore
    OrderCreateFromJSON,
// @ts-ignore
    OrderCreateFromJSONTyped,
// @ts-ignore
    OrderCreateToJSON,
} from './OrderCreate';

/**
 * 
 * @export
 * @interface BulkOrderCreateRequest
 */
export interface BulkOrderCreateRequest {
    /**
     * 
     * @type {Array<OrderCreate>}
     * @memberof BulkOrderCreateRequest
     */
    orders: Array<OrderCreate>;
}

/**
 * Check if a given object implements the BulkOrderCreateRequest interface.
 */
// @ts-ignore
export function instanceOfBulkOrderCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orders" in value;

    return isInstance;
}

// @ts-ignore
export function BulkOrderCreateRequestFromJSON(json: any): BulkOrderCreateRequest {
    return BulkOrderCreateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function BulkOrderCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkOrderCreateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': ((json['orders'] as Array<any>).map(OrderCreateFromJSON)),
    };
}

// @ts-ignore
export function BulkOrderCreateRequestToJSON(value?: BulkOrderCreateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': ((value.orders as Array<any>).map(OrderCreateToJSON)),
    };
}

