import CalendarsApi from '@/api/CalendarsApi';
import {toCalendar, toCalendarEvent} from '@/api/mappers/mapCalendar';
import AbstractApi from '@/api/mocks/AbstractApi';
import {mockCalendarEventsData, mockCalendarsData} from '@/api/mocks/data/calendars';
import {calculatePagination} from '@/api/mocks/Pagination';
import {
    Calendar,
    CalendarCreate,
    Calendars,
    CalendarUpdate,
} from '@/models/Calendar';
import {
    CalendarEvent,
    CalendarEventCreate,
    CalendarEvents,
    CalendarEventUpdate,
} from '@/models/CalendarEvent';

export default class MockCalendarApiImpl extends AbstractApi implements CalendarsApi {
    async listCalendars(offset: number = 0, limit: number = 10): Promise<Calendars> {
        await this.sleep();
        return calculatePagination(mockCalendarsData.map(toCalendar), offset, limit);
    }

    async findCalendars(query?: string, supplierId?: string, offset: number = 0, limit: number = 10): Promise<Calendars> {
        await this.sleep();

        let items = [...mockCalendarsData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(calendar => calendar.name.toLowerCase().includes(queryLowerCase));
        }

        if (supplierId) {
            items = items.filter(calendar => calendar.supplierId === supplierId);
        }

        return calculatePagination(items.map(toCalendar), offset, limit);
    }

    async createCalendar(_calendar: CalendarCreate): Promise<void> {
        await this.sleep();
    }

    async getCalendar(id: string): Promise<Calendar | undefined> {
        await this.sleep();
        const calendar = mockCalendarsData.find(calendar => calendar.id === id);
        if (calendar) {
            return toCalendar(calendar);
        }
        return undefined;
    }

    async updateCalendar(_id: string, _calendar: CalendarUpdate): Promise<void> {
        await this.sleep();
    }

    async listCalendarEvents(calendarId?: string, offset: number = 0, limit: number = 10): Promise<CalendarEvents> {
        await this.sleep();

        let items = [...mockCalendarEventsData];

        if (calendarId) {
            items = items.filter(calendarEvent => calendarEvent.calendarId === calendarId);
        }

        return calculatePagination(items.map(toCalendarEvent), offset, limit);
    }

    async findCalendarEvents(query?: string, calendarId?: string, offset: number = 0, limit: number = 10): Promise<CalendarEvents> {
        await this.sleep();

        let items = [...mockCalendarEventsData];

        if (calendarId) {
            items = items.filter(calendarEvent => calendarEvent.calendarId === calendarId);
        }

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(calendarEvent => calendarEvent.name.toLowerCase().includes(queryLowerCase));
        }

        return calculatePagination(items.map(toCalendarEvent), offset, limit);
    }

    async createCalendarEvent(_event: CalendarEventCreate): Promise<void> {
        await this.sleep();
    }

    async getCalendarEvent(id: string): Promise<CalendarEvent | undefined> {
        await this.sleep();
        const calendarEvent = mockCalendarEventsData.find(calendarEvent => calendarEvent.id === id);
        if (calendarEvent) {
            return toCalendarEvent(calendarEvent);
        }
        return undefined;
    }

    async updateCalendarEvent(_id: string, _event: CalendarEventUpdate): Promise<void> {
        await this.sleep();
    }
}
