import {
    Calendar as ApiCalendar,
    CalendarEvent as ApiCalendarEvent,
    CalendarEventType as ApiCalendarEventType,
    CalendarEventFrequencyEnum,
} from '@/gen/api';
import {Calendar as CalendarModel} from '@/models/Calendar';
import {CalendarEventFrequency, CalendarEvent as CalendarEventModel, CalendarEventType} from '@/models/CalendarEvent';

const toCalendarEventType = (apiCalendarEventType: ApiCalendarEventType): CalendarEventType => {
    switch (apiCalendarEventType) {
        case 'NON_WORKING_DAY':
            return CalendarEventType.NON_WORKING_DAY;
    }
    return CalendarEventType.DEFAULT;
};

export const toCalendar = (apiCalendar: ApiCalendar): CalendarModel => {
    return {
        ...apiCalendar,
    };
};

const toCalendarEventFrequency = (apiFrequency?: CalendarEventFrequencyEnum): CalendarEventFrequency | undefined => {
    return apiFrequency;
};

export const toCalendarEvent = (apiCalenderEvent: ApiCalendarEvent): CalendarEventModel => {
    return {
        ...apiCalenderEvent,
        type: toCalendarEventType(apiCalenderEvent.type),
        frequency: toCalendarEventFrequency(apiCalenderEvent.frequency),
    };
};
