/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { ProductCategoryCreate } from './ProductCategoryCreate';
// @ts-ignore
import {
// @ts-ignore
    ProductCategoryCreateFromJSON,
// @ts-ignore
    ProductCategoryCreateFromJSONTyped,
// @ts-ignore
    ProductCategoryCreateToJSON,
} from './ProductCategoryCreate';

/**
 * 
 * @export
 * @interface ProductCreate
 */
export interface ProductCreate {
    /**
     * 
     * @type {string}
     * @memberof ProductCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreate
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreate
     */
    gtin?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreate
     */
    vatRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreate
     */
    productCategoryId?: string;
    /**
     * 
     * @type {ProductCategoryCreate}
     * @memberof ProductCreate
     */
    productCategory?: ProductCategoryCreate;
}

/**
 * Check if a given object implements the ProductCreate interface.
 */
// @ts-ignore
export function instanceOfProductCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

// @ts-ignore
export function ProductCreateFromJSON(json: any): ProductCreate {
    return ProductCreateFromJSONTyped(json, false);
}

// @ts-ignore
export function ProductCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCreate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'productCategoryId': !exists(json, 'product_category_id') ? undefined : json['product_category_id'],
        'productCategory': !exists(json, 'product_category') ? undefined : ProductCategoryCreateFromJSON(json['product_category']),
    };
}

// @ts-ignore
export function ProductCreateToJSON(value?: ProductCreate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'gtin': value.gtin,
        'vat_rate': value.vatRate,
        'product_category_id': value.productCategoryId,
        'product_category': ProductCategoryCreateToJSON(value.productCategory),
    };
}

