/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Notification } from './Notification';
// @ts-ignore
import {
// @ts-ignore
    NotificationFromJSON,
// @ts-ignore
    NotificationFromJSONTyped,
// @ts-ignore
    NotificationToJSON,
} from './Notification';

/**
 * 
 * @export
 * @interface NotificationListResponse
 */
export interface NotificationListResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationListResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationListResponse
     */
    estimatedTotalCount: number;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationListResponse
     */
    items: Array<Notification>;
}

/**
 * Check if a given object implements the NotificationListResponse interface.
 */
// @ts-ignore
export function instanceOfNotificationListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimatedTotalCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

// @ts-ignore
export function NotificationListResponseFromJSON(json: any): NotificationListResponse {
    return NotificationListResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function NotificationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationListResponse {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
        'estimatedTotalCount': json['estimated_total_count'],
        'items': ((json['items'] as Array<any>).map(NotificationFromJSON)),
    };
}

// @ts-ignore
export function NotificationListResponseToJSON(value?: NotificationListResponse | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_count': value.totalCount,
        'estimated_total_count': value.estimatedTotalCount,
        'items': ((value.items as Array<any>).map(NotificationToJSON)),
    };
}

