import {Configuration} from '@/api';
import {toProductCategory} from '@/api/mappers/mapProductCategory';
import {toApiStatus} from '@/api/mappers/mapStatus';
import {ProductCategoriesApi as ApiProductCategoriesApi} from '@/gen/api';
import {ProductCategories, ProductCategory, ProductCategoryCreate, ProductCategoryUpdate} from '@/models/ProductCategory';

export interface ProductCategoryFilters {
    supplierIds?: string[];
}

interface ProductCategoriesApi {
    listProductCategories(offset?: number, limit?: number): Promise<ProductCategories>;
    findProductCategories(query?: string, filters?: ProductCategoryFilters, offset?: number, limit?: number): Promise<ProductCategories>;
    deleteProductCategory(id: string): Promise<void>;
    getProductCategory(id: string): Promise<ProductCategory | undefined>;
    createProductCategory(request: ProductCategoryCreate): Promise<void>;
    updateProductCategory(id: string, request: ProductCategoryUpdate): Promise<void>;
}

export class ProductCategoriesApiImpl implements ProductCategoriesApi {
    private productCategoriesApi: ApiProductCategoriesApi;

    constructor(configuration: Configuration) {
        this.productCategoriesApi = new ApiProductCategoriesApi(configuration);
    }

    async listProductCategories(offset?: number, limit?: number): Promise<ProductCategories> {
        try {
            const response = await this.productCategoriesApi.listProductCategories({
                offset,
                limit,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toProductCategory),
            };
        } catch (error) {
            console.error('Error listing product categories:', error);
            throw error;
        }
    }

    async findProductCategories(query?: string, filters?: ProductCategoryFilters, offset?: number, limit?: number): Promise<ProductCategories> {
        try {
            const response = await this.productCategoriesApi.listProductCategories({
                query,
                supplierId: filters?.supplierIds,
                offset,
                limit,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toProductCategory),
            };
        } catch (error) {
            console.error('Error finding product categories:', error);
            throw error;
        }
    }

    async deleteProductCategory(id: string): Promise<void> {
        try {
            await this.productCategoriesApi.deleteProductCategory({id});
        } catch (error) {
            console.error('Error deleting product category:', error);
            throw error;
        }
    }

    async getProductCategory(id: string): Promise<ProductCategory | undefined> {
        try {
            const response = await this.productCategoriesApi.getProductCategory({id});
            return toProductCategory(response);
        } catch (error) {
            console.error('Error getting product category:', error);
            throw error;
        }
    }

    async createProductCategory(productCategory: ProductCategoryCreate): Promise<void> {
        try {
            await this.productCategoriesApi.createProductCategory({
                productCategoryCreateRequest: {
                    ...productCategory,
                    status: toApiStatus(productCategory.status),
                },
            });
        } catch (error) {
            console.error('Error creating product category:', error);
            throw error;
        }
    }

    async updateProductCategory(id: string, productCategory: ProductCategoryUpdate): Promise<void> {
        try {
            await this.productCategoriesApi.updateProductCategory({
                id,
                productCategoryUpdateRequest: {
                    ...productCategory,
                    status: toApiStatus(productCategory.status),
                },
            });
        } catch (error) {
            console.error('Error updating product category:', error);
            throw error;
        }
    }
}

export default ProductCategoriesApi;
