import {Promotion, Status} from '@/gen/api';

export const mockPromotionsData: Promotion[] = [
    {
        id: '1',
        name: 'Fixed Amount',
        effectivePeriod: {
            start: new Date('2024-03-01T00:00:00Z'),
            end: new Date('2024-03-31T23:59:59Z'),
        },
        discountType: 'fixed_amount',
        fixedAmounts: [
            {
                amount: {
                    currencyCode: 'USD',
                    amount: 5.0,
                },
            },
        ],
        currencyCode: 'USD',
        minimumAmount: {
            currencyCode: 'USD',
            amount: 50.0,
        },
        minimumQuantity: 1,
        supplierId: '1',
        skuId: '133654',
        status: Status.Active,
        createdAt: new Date('2024-02-15T08:00:00Z'),
    },
    {
        id: '2',
        name: 'Percentage',
        effectivePeriod: {
            start: new Date('2024-03-01T00:00:00Z'),
            end: new Date('2024-03-31T23:59:59Z'),
        },
        discountType: 'percentage',
        percentages: [
            {
                percentage: 10,
            },
        ],
        currencyCode: 'USD',
        minimumAmount: {
            currencyCode: 'USD',
            amount: 50.0,
        },
        minimumQuantity: 1,
        supplierId: '1',
        skuId: '113655',
        status: Status.Active,
        createdAt: new Date('2024-02-15T08:00:00Z'),
    },
];
