/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Geolocation } from './Geolocation';
// @ts-ignore
import {
// @ts-ignore
    GeolocationFromJSON,
// @ts-ignore
    GeolocationFromJSONTyped,
// @ts-ignore
    GeolocationToJSON,
} from './Geolocation';

/**
 * 
 * @export
 * @interface StoreAddress
 */
export interface StoreAddress {
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    building: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    street1: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    street2: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    countryCode: string;
    /**
     * 
     * @type {Geolocation}
     * @memberof StoreAddress
     */
    location: Geolocation;
    /**
     * 
     * @type {string}
     * @memberof StoreAddress
     */
    additionalDescription: string;
}

/**
 * Check if a given object implements the StoreAddress interface.
 */
// @ts-ignore
export function instanceOfStoreAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storeId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "building" in value;
    isInstance = isInstance && "street1" in value;
    isInstance = isInstance && "street2" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "additionalDescription" in value;

    return isInstance;
}

// @ts-ignore
export function StoreAddressFromJSON(json: any): StoreAddress {
    return StoreAddressFromJSONTyped(json, false);
}

// @ts-ignore
export function StoreAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreAddress {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'storeId': json['store_id'],
        'type': json['type'],
        'building': json['building'],
        'street1': json['street1'],
        'street2': json['street2'],
        'postalCode': json['postal_code'],
        'region': json['region'],
        'city': json['city'],
        'countryCode': json['country_code'],
        'location': GeolocationFromJSON(json['location']),
        'additionalDescription': json['additional_description'],
    };
}

// @ts-ignore
export function StoreAddressToJSON(value?: StoreAddress | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'store_id': value.storeId,
        'type': value.type,
        'building': value.building,
        'street1': value.street1,
        'street2': value.street2,
        'postal_code': value.postalCode,
        'region': value.region,
        'city': value.city,
        'country_code': value.countryCode,
        'location': GeolocationToJSON(value.location),
        'additional_description': value.additionalDescription,
    };
}

