interface PaginatedList<T> {
    items: T[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export const calculatePagination = <T>(items: T[], offset: number, limit: number): PaginatedList<T> => {
    const totalCount = items.length;
    const paginatedItems = items.slice(offset, offset + limit);

    const nextItemCount = totalCount <= offset + limit ? 0 : Math.min(totalCount - offset - limit, 30);
    const estimatedTotalCount = offset + paginatedItems.length + nextItemCount;

    return {
        items: paginatedItems,
        totalCount: totalCount === estimatedTotalCount ? totalCount : undefined,
        estimatedTotalCount: totalCount !== estimatedTotalCount ? estimatedTotalCount : undefined,
    };
};
