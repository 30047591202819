export interface DeliveryWindow {
    id: string;
    start: Date;
    end: Date;

    equals(other: DeliveryWindow): boolean;
}

export class DeliveryWindowImpl {
    readonly id: string;
    readonly start: Date;
    readonly end: Date;

    constructor(start: Date, end: Date) {
        this.start = start;
        this.end = end;
        this.id = this.generateId();
    }

    equals(other: DeliveryWindow): boolean {
        return (
            this.id === other.id &&
            this.start.getTime() === other.start.getTime() &&
            this.end.getTime() === other.end.getTime()
        );
    }

    private generateId(): string {
        return `${this.start.getTime()}${this.end.getTime()}`;
    }

    static fromObject(obj: { start: Date | string; end: Date | string }): DeliveryWindowImpl {
        const startDate = typeof obj.start === 'string' ? new Date(obj.start) : obj.start;
        const endDate = typeof obj.end === 'string' ? new Date(obj.end) : obj.end;
        return new DeliveryWindowImpl(startDate, endDate);
    }
}
