/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import type { SkuUpdate } from './SkuUpdate';
// @ts-ignore
import {
// @ts-ignore
    instanceOfSkuUpdate,
// @ts-ignore
    SkuUpdateFromJSON,
// @ts-ignore
    SkuUpdateFromJSONTyped,
// @ts-ignore
    SkuUpdateToJSON,
} from './SkuUpdate';

/**
 * @type SkuUpdateRequest
 * 
 * @export
 */
export type SkuUpdateRequest = SkuUpdate;

// @ts-ignore
export function SkuUpdateRequestFromJSON(json: any): SkuUpdateRequest {
    return SkuUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SkuUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...SkuUpdateFromJSONTyped(json, true) };
}

// @ts-ignore
export function SkuUpdateRequestToJSON(value?: SkuUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfSkuUpdate(value)) {
        return SkuUpdateToJSON(value as SkuUpdate);
    }

    return {};
}

