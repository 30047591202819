import Configuration from '@/api/Configuration';

export default abstract class AbstractApi {
    private readonly _minWait: number | undefined;
    private readonly _maxWait: number | undefined;

    constructor(_configuration?: Configuration, minWait?: number, maxWait?: number) {
        this._minWait = minWait;
        this._maxWait = maxWait;
    }

    protected async sleep(minWait?: number, maxWait?: number) {
        await sleep(minWait ? minWait : this._minWait, maxWait ? maxWait : this._maxWait);
    }
}

export const sleep = (minWait: number = 0.2, maxWait: number = 1): Promise<void> => {
    const durationInSeconds = Math.random() * (maxWait - minWait) + minWait;
    return new Promise((resolve) => setTimeout(resolve, durationInSeconds * 1000));
};
