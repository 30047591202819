/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  AddOrRemoveStoresToSupplier,
// @ts-ignore
  CustomerListResponse,
// @ts-ignore
  DeliveryWindowsResponse,
// @ts-ignore
  StoreListResponse,
// @ts-ignore
  Supplier,
// @ts-ignore
  SupplierCreateRequest,
// @ts-ignore
  SupplierListResponse,
// @ts-ignore
  SupplierUpdateRequest,
// @ts-ignore
  WarehouseListResponse,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    AddOrRemoveStoresToSupplierFromJSON,
// @ts-ignore
    AddOrRemoveStoresToSupplierToJSON,
// @ts-ignore
    CustomerListResponseFromJSON,
// @ts-ignore
    CustomerListResponseToJSON,
// @ts-ignore
    DeliveryWindowsResponseFromJSON,
// @ts-ignore
    DeliveryWindowsResponseToJSON,
// @ts-ignore
    StoreListResponseFromJSON,
// @ts-ignore
    StoreListResponseToJSON,
// @ts-ignore
    SupplierFromJSON,
// @ts-ignore
    SupplierToJSON,
// @ts-ignore
    SupplierCreateRequestFromJSON,
// @ts-ignore
    SupplierCreateRequestToJSON,
// @ts-ignore
    SupplierListResponseFromJSON,
// @ts-ignore
    SupplierListResponseToJSON,
// @ts-ignore
    SupplierUpdateRequestFromJSON,
// @ts-ignore
    SupplierUpdateRequestToJSON,
// @ts-ignore
    WarehouseListResponseFromJSON,
// @ts-ignore
    WarehouseListResponseToJSON,
} from '../models/index';

export interface AddOrRemoveStoresToSupplierRequest {
    supplierId: string;
    addOrRemoveStoresToSupplier: AddOrRemoveStoresToSupplier;
}

export interface CreateSupplierRequest {
    supplierCreateRequest: SupplierCreateRequest;
}

export interface DeleteSupplierRequest {
    id: string;
}

export interface GetDeliveryWindowsRequest {
    storeId?: string;
    supplierId?: Array<string>;
    lookAheadInWeeks?: number;
}

export interface GetSupplierRequest {
    id: string;
}

export interface GetSupplierCustomerStoresRequest {
    supplierId: string;
    customerId: string;
}

export interface ListSupplierCustomersRequest {
    id: string;
}

export interface ListSupplierStoresRequest {
    id: string;
}

export interface ListSupplierWarehousesRequest {
    id: string;
}

export interface ListSuppliersRequest {
    query?: string;
    limit?: number;
    offset?: number;
    supplierId?: Array<string>;
    products?: boolean;
    createdByStoreId?: string;
}

export interface UpdateSupplierRequest {
    id: string;
    supplierUpdateRequest: SupplierUpdateRequest;
}

/**
 * 
 */
export class SuppliersApi extends runtime.BaseAPI {

    /**
     * Add or remove stores to/from a supplier
     */
    async addOrRemoveStoresToSupplierRaw(requestParameters: AddOrRemoveStoresToSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling addOrRemoveStoresToSupplier.');
        }

        if (requestParameters.addOrRemoveStoresToSupplier === null || requestParameters.addOrRemoveStoresToSupplier === undefined) {
            throw new runtime.RequiredError('addOrRemoveStoresToSupplier','Required parameter requestParameters.addOrRemoveStoresToSupplier was null or undefined when calling addOrRemoveStoresToSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/suppliers/{supplierId}/stores`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemoveStoresToSupplierToJSON(requestParameters.addOrRemoveStoresToSupplier),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add or remove stores to/from a supplier
     */
    async addOrRemoveStoresToSupplier(requestParameters: AddOrRemoveStoresToSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addOrRemoveStoresToSupplierRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new supplier
     */
    async createSupplierRaw(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Supplier>> {
        if (requestParameters.supplierCreateRequest === null || requestParameters.supplierCreateRequest === undefined) {
            throw new runtime.RequiredError('supplierCreateRequest','Required parameter requestParameters.supplierCreateRequest was null or undefined when calling createSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/suppliers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierCreateRequestToJSON(requestParameters.supplierCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierFromJSON(jsonValue));
    }

    /**
     * Create a new supplier
     */
    async createSupplier(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Supplier> {
        const response = await this.createSupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a supplier by ID
     */
    async deleteSupplierRaw(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a supplier by ID
     */
    async deleteSupplier(requestParameters: DeleteSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSupplierRaw(requestParameters, initOverrides);
    }

    /**
     * Get possible delivery and pickup windows
     */
    async getDeliveryWindowsRaw(requestParameters: GetDeliveryWindowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryWindowsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.storeId !== undefined) {
            queryParameters['storeId'] = requestParameters.storeId;
        }

        if (requestParameters.supplierId) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        if (requestParameters.lookAheadInWeeks !== undefined) {
            queryParameters['lookAheadInWeeks'] = requestParameters.lookAheadInWeeks;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/delivery-windows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryWindowsResponseFromJSON(jsonValue));
    }

    /**
     * Get possible delivery and pickup windows
     */
    async getDeliveryWindows(requestParameters: GetDeliveryWindowsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryWindowsResponse> {
        const response = await this.getDeliveryWindowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a supplier by ID
     */
    async getSupplierRaw(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Supplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierFromJSON(jsonValue));
    }

    /**
     * Get a supplier by ID
     */
    async getSupplier(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Supplier> {
        const response = await this.getSupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get stores for a specific supplier and customer
     */
    async getSupplierCustomerStoresRaw(requestParameters: GetSupplierCustomerStoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreListResponse>> {
        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling getSupplierCustomerStores.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getSupplierCustomerStores.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{supplierId}/customers/{customerId}/stores`.replace(`{${"supplierId"}}`, encodeURIComponent(String(requestParameters.supplierId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreListResponseFromJSON(jsonValue));
    }

    /**
     * Get stores for a specific supplier and customer
     */
    async getSupplierCustomerStores(requestParameters: GetSupplierCustomerStoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreListResponse> {
        const response = await this.getSupplierCustomerStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all customers related to a supplier
     */
    async listSupplierCustomersRaw(requestParameters: ListSupplierCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listSupplierCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{id}/customers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerListResponseFromJSON(jsonValue));
    }

    /**
     * Get all customers related to a supplier
     */
    async listSupplierCustomers(requestParameters: ListSupplierCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerListResponse> {
        const response = await this.listSupplierCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all stores related to the supplier
     */
    async listSupplierStoresRaw(requestParameters: ListSupplierStoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listSupplierStores.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{id}/stores`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreListResponseFromJSON(jsonValue));
    }

    /**
     * Get all stores related to the supplier
     */
    async listSupplierStores(requestParameters: ListSupplierStoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreListResponse> {
        const response = await this.listSupplierStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all warehouses related to the supplier
     */
    async listSupplierWarehousesRaw(requestParameters: ListSupplierWarehousesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WarehouseListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listSupplierWarehouses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers/{id}/warehouses`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WarehouseListResponseFromJSON(jsonValue));
    }

    /**
     * Get all warehouses related to the supplier
     */
    async listSupplierWarehouses(requestParameters: ListSupplierWarehousesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WarehouseListResponse> {
        const response = await this.listSupplierWarehousesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all suppliers
     */
    async listSuppliersRaw(requestParameters: ListSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.supplierId) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        if (requestParameters.products !== undefined) {
            queryParameters['products'] = requestParameters.products;
        }

        if (requestParameters.createdByStoreId !== undefined) {
            queryParameters['createdByStoreId'] = requestParameters.createdByStoreId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierListResponseFromJSON(jsonValue));
    }

    /**
     * Get all suppliers
     */
    async listSuppliers(requestParameters: ListSuppliersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierListResponse> {
        const response = await this.listSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a supplier by ID
     */
    async updateSupplierRaw(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSupplier.');
        }

        if (requestParameters.supplierUpdateRequest === null || requestParameters.supplierUpdateRequest === undefined) {
            throw new runtime.RequiredError('supplierUpdateRequest','Required parameter requestParameters.supplierUpdateRequest was null or undefined when calling updateSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierUpdateRequestToJSON(requestParameters.supplierUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a supplier by ID
     */
    async updateSupplier(requestParameters: UpdateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSupplierRaw(requestParameters, initOverrides);
    }

}
