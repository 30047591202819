import {DeliveryWindow, Status, Supplier} from '@/gen/api';

export const mockSuppliersData: Supplier[] = [
    {
        id: '1',
        code: '1',
        organizationId: '1',
        name: 'Espresso Comrades Corporation',
        email: '',
        externalCode: 'EC-001',
        creditTerm: '30d',
        status: Status.Active,
        addresses: [
            {
                id: 'a1f4c8b2-92c9-4ed5-9739-92e8b1831d9f',
                supplierId: '1',
                type: 'BUSINESS',
                building: 'Building A',
                street1: '123 Espresso Lane',
                street2: '',
                postalCode: '10001',
                region: 'New York',
                city: 'New York',
                countryCode: 'US',
                location: {
                    latitude: 40.712776,
                    longitude: -74.005974,
                },
                additionalDescription: 'Near Central Park',
            },
        ],
        deliveryWindows: [],
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '2',
        code: '2',
        organizationId: '2',
        name: 'FashionFusion',
        email: '',
        externalCode: 'FF-002',
        creditTerm: '30d',
        status: Status.Active,
        addresses: [
            {
                id: '08bc25d3-6f51-4fc2-b8f8-27b8c183cf7f',
                supplierId: '2',
                type: 'BUSINESS',
                building: 'Suite 300',
                street1: '456 Exotic Blvd',
                street2: '',
                postalCode: '33101',
                region: 'Florida',
                city: 'Miami',
                countryCode: 'US',
                location: {
                    latitude: 25.761681,
                    longitude: -80.191788,
                },
                additionalDescription: 'Near South Beach',
            },
        ],
        deliveryWindows: [],
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '3',
        code: '3',
        organizationId: '3',
        name: 'BoltMaster Supply Co.',
        email: '',
        externalCode: 'BOLT-001',
        creditTerm: '60d',
        status: Status.Active,
        addresses: [
            {
                id: '32ddb90b-1d46-4cec-8a1f-72a9ad47d59f',
                supplierId: '3',
                type: 'BUSINESS',
                building: 'Building D',
                street1: '789 Aqua Lane',
                street2: '',
                postalCode: '85001',
                region: 'Arizona',
                city: 'Phoenix',
                countryCode: 'US',
                location: {
                    latitude: 33.448376,
                    longitude: -112.074036,
                },
                additionalDescription: 'Near Desert Botanical Garden',
            },
        ],
        deliveryWindows: [],
        createdAt: new Date('2024-01-07T10:00:00Z'),
    },
    {
        id: '4',
        code: '4',
        organizationId: '4',
        name: 'FrostyDelight Culinary Ventures',
        email: '',
        externalCode: 'FDCV-004',
        creditTerm: '30d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-12T10:00:00Z'),
    },
    {
        id: '5',
        code: '5',
        organizationId: '5',
        name: 'Motorcycle Parts Inc.',
        email: '',
        externalCode: 'MCPI-005',
        creditTerm: '45d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-13T10:00:00Z'),
    },
    {
        id: '6',
        code: '6',
        organizationId: '6',
        name: 'Oriental Horizon Merchants',
        email: '',
        externalCode: 'OHM-006',
        creditTerm: '60d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-05T10:00:00Z'),
    },
    {
        id: '7',
        code: '7',
        organizationId: '7',
        name: 'FlavorFusion Gastronomy Enterprises',
        email: '',
        externalCode: 'FFE-007',
        creditTerm: '30d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-12T10:00:00Z'),
    },
    {
        id: '8',
        code: '8',
        organizationId: '8',
        name: 'ExoticTrade Commercial Ventures',
        email: '',
        externalCode: 'ETCV-008',
        creditTerm: '45d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-02T10:00:00Z'),
    },
    {
        id: '9',
        code: '9',
        organizationId: '9',
        name: 'AquaHarmony Hydration Corporation',
        email: '',
        externalCode: 'AHC-009',
        creditTerm: '60d',
        status: Status.Active,
        addresses: [],
        deliveryWindows: [],
        createdAt: new Date('2024-01-03T10:00:00Z'),
    },
];

export const mockDeliveryWindowsData: DeliveryWindow[] = [
    {
        id: '2024-01-14T10:00:00Z-2024-01-14T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 14, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 14, 16, 0)),
    },
    {
        id: '2024-01-15T10:00:00Z-2024-01-15T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 15, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 15, 16, 0)),
    },
    {
        id: '2024-01-16T10:00:00Z-2024-01-16T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 16, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 16, 16, 0)),
    },
    {
        id: '2024-01-17T10:00:00Z-2024-01-17T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 17, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 17, 16, 0)),
    },
    {
        id: '2024-01-18T10:00:00Z-2024-01-18T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 18, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 18, 16, 0)),
    },
    {
        id: '2024-01-19T10:00:00Z-2024-01-19T16:00:00Z',
        start: new Date(Date.UTC(2024, 0, 19, 10, 0)),
        end: new Date(Date.UTC(2024, 0, 19, 16, 0)),
    },
];

export const mockSupplierToCustomerData = [
    {
        supplierId: '1',
        customerId: '1',
    },
    {
        supplierId: '2',
        customerId: '1',
    },
    {
        supplierId: '3',
        customerId: '1',
    },
    {
        supplierId: '4',
        customerId: '1',
    },
];
