import {Image as ApiImage, ImageSize as ApiImageSize, ImageTypeEnum} from '@/gen/api';
import {Image as ImageModel, ImageSize as ImageSizeModel, ImageType, ImageType as ImageTypeModel} from '@/models/Image';

const toImageType = (apiImageType: ImageTypeEnum): ImageTypeModel => {
    switch (apiImageType) {
        case 'original':
            return ImageType.Original;
        case 'thumbnail':
            return ImageType.Thumbnail;
        case 'resized':
            return ImageType.Resized;
        default:
            return ImageType.Unknown;
    }
};

const toImageSize = (apiImageSize: ApiImageSize): ImageSizeModel => {
    return {
        ...apiImageSize,
        type: toImageType(apiImageSize.type),
    };
};

export const toImage = (apiImage: ApiImage): ImageModel => {
    return {
        ...apiImage,
        type: toImageType(apiImage.type),
        sizes: apiImage.sizes?.map(toImageSize) || [],
    };
};
