import Configuration from '@/api/Configuration';
import {toNotification} from '@/api/mappers/mapNotification';
import {NotificationsApi as ApiNotificationsApi, ListNotificationsRequest} from '@/gen/api';
import {Notifications} from '@/modules/notification/models/Notification';

interface NotificationsApi {
    listNotifications(offset: number, limit: number): Promise<Notifications>;
    markNotificationsAsRead(id: string | string[]): Promise<void>;
}

export class NotificationsApiImpl implements NotificationsApi {
    private notificationsApi: ApiNotificationsApi;

    constructor(configuration: Configuration) {
        this.notificationsApi = new ApiNotificationsApi(configuration);
    }

    async listNotifications(offset: number = 0, limit: number = 10): Promise<Notifications> {
        try {
            const params: ListNotificationsRequest = {offset, limit};
            const response = await this.notificationsApi.listNotifications(params);
            return {
                items: response.items.map(toNotification),
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
            };
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    }

    async markNotificationsAsRead(id: string | string[]): Promise<void> {
        const ids = Array.isArray(id) ? id : [id];
        try {
            await this.notificationsApi.markNotificationsAsRead({
                notificationMarkAsReadRequest: {notificationIds: ids},
            });
        } catch (error) {
            console.error('Error marking notifications as read:', error);
            throw error;
        }
    }
}

export default NotificationsApi;
