import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {User as ApiUser, UserCreate as ApiUserCreate, UserUpdate as ApiUserUpdate} from '@/gen/api';
import {User as UserModel, UserRole} from '@/models/User';

const parseUserRole = (roleString: string): UserRole => {
    switch (roleString.toUpperCase()) {
        case 'ADMIN':
            return UserRole.Admin;
        case 'CUSTOMER':
            return UserRole.Customer;
        case 'SUPPLIER':
            return UserRole.Supplier;
        case 'RETAILER':
            return UserRole.Retailer;
        default:
            return UserRole.Unknown; // Return Unknown for any other role string
    }
};

export const toUser = (apiUser: ApiUser): UserModel => {
    return {
        ...apiUser,
        role: parseUserRole(apiUser.role),
        status: toStatus(apiUser.status),
    };
};

export const toApiUserCreate = (user: UserModel): ApiUserCreate => {
    return {
        ...user,
        status: toApiStatus(user.status),
    };
};

export const toApiUserUpdate = (user: UserModel): ApiUserUpdate => {
    return {
        ...user,
        status: toApiStatus(user.status),
    };
};
