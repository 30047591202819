/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Image } from './Image';
// @ts-ignore
import {
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageFromJSONTyped,
// @ts-ignore
    ImageToJSON,
} from './Image';
// @ts-ignore
import type { ProductCategoryUpdate } from './ProductCategoryUpdate';
// @ts-ignore
import {
// @ts-ignore
    ProductCategoryUpdateFromJSON,
// @ts-ignore
    ProductCategoryUpdateFromJSONTyped,
// @ts-ignore
    ProductCategoryUpdateToJSON,
} from './ProductCategoryUpdate';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface ProductUpdate
 */
export interface ProductUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    gtin?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdate
     */
    vatRate?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof ProductUpdate
     */
    images?: Array<Image>;
    /**
     * 
     * @type {Status}
     * @memberof ProductUpdate
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdate
     */
    productCategoryId?: string;
    /**
     * 
     * @type {ProductCategoryUpdate}
     * @memberof ProductUpdate
     */
    productCategory?: ProductCategoryUpdate;
}

/**
 * Check if a given object implements the ProductUpdate interface.
 */
// @ts-ignore
export function instanceOfProductUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

// @ts-ignore
export function ProductUpdateFromJSON(json: any): ProductUpdate {
    return ProductUpdateFromJSONTyped(json, false);
}

// @ts-ignore
export function ProductUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductUpdate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'description': json['description'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'supplierId': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'productCategoryId': !exists(json, 'product_category_id') ? undefined : json['product_category_id'],
        'productCategory': !exists(json, 'product_category') ? undefined : ProductCategoryUpdateFromJSON(json['product_category']),
    };
}

// @ts-ignore
export function ProductUpdateToJSON(value?: ProductUpdate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'external_code': value.externalCode,
        'description': value.description,
        'gtin': value.gtin,
        'vat_rate': value.vatRate,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'status': StatusToJSON(value.status),
        'supplier_id': value.supplierId,
        'product_category_id': value.productCategoryId,
        'product_category': ProductCategoryUpdateToJSON(value.productCategory),
    };
}

