import {Image} from '@/models/Image';
import {OrderPredictionItem} from '@/models/OrderPredictionItem';
import {Amount} from '@/models/Price';
import {CalculatedPrice} from '@/modules/retailer/models/Price';

export interface CartItem {
    id: number;
    productId?: string;
    skuId: string;
    supplierId: string;
    quantity: number;
    prediction?: OrderPredictionItem;
    checked?: boolean;
}

export interface CartItemDetails extends CartItem {
    sku: {
        code: string;
        name: string;
        image?: Image;
    };
    price: Amount;
    unitPrice: CalculatedPrice;
    lineItemPrice: CalculatedPrice;
    vatRate: number;
    vatAmount: Amount;
}


export class CartItemImpl implements CartItem {
    id: number = 0;
    readonly productId?: string;
    readonly skuId: string;
    readonly supplierId: string;
    readonly quantity: number;
    readonly prediction?: OrderPredictionItem;
    checked?: boolean;

    constructor(
        skuId: string,
        supplierId: string,
        quantity: number,
        productId?: string,
        prediction?: OrderPredictionItem,
        checked?: boolean,
    ) {
        this.productId = productId;
        this.skuId = skuId;
        this.supplierId = supplierId;
        this.quantity = quantity;
        this.prediction = prediction;
        this.checked = checked;
    }

    static fromObject(obj: CartItem): CartItemImpl {
        const cartItem = new CartItemImpl(
            obj.skuId,
            obj.supplierId,
            obj.quantity,
            obj.productId,
            obj.prediction,
            obj.checked,
        );

        cartItem.id = obj.id;

        return cartItem;
    }
}
