/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { DeliveryWindow } from './DeliveryWindow';
// @ts-ignore
import {
// @ts-ignore
    DeliveryWindowFromJSON,
// @ts-ignore
    DeliveryWindowFromJSONTyped,
// @ts-ignore
    DeliveryWindowToJSON,
} from './DeliveryWindow';
// @ts-ignore
import type { PickupWindow } from './PickupWindow';
// @ts-ignore
import {
// @ts-ignore
    PickupWindowFromJSON,
// @ts-ignore
    PickupWindowFromJSONTyped,
// @ts-ignore
    PickupWindowToJSON,
} from './PickupWindow';

/**
 * 
 * @export
 * @interface DeliveryWindowsResponse
 */
export interface DeliveryWindowsResponse {
    /**
     * 
     * @type {Array<DeliveryWindow>}
     * @memberof DeliveryWindowsResponse
     */
    deliveryWindows: Array<DeliveryWindow>;
    /**
     * 
     * @type {Array<PickupWindow>}
     * @memberof DeliveryWindowsResponse
     */
    pickupWindows: Array<PickupWindow>;
}

/**
 * Check if a given object implements the DeliveryWindowsResponse interface.
 */
// @ts-ignore
export function instanceOfDeliveryWindowsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryWindows" in value;
    isInstance = isInstance && "pickupWindows" in value;

    return isInstance;
}

// @ts-ignore
export function DeliveryWindowsResponseFromJSON(json: any): DeliveryWindowsResponse {
    return DeliveryWindowsResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function DeliveryWindowsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryWindowsResponse {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryWindows': ((json['delivery_windows'] as Array<any>).map(DeliveryWindowFromJSON)),
        'pickupWindows': ((json['pickup_windows'] as Array<any>).map(PickupWindowFromJSON)),
    };
}

// @ts-ignore
export function DeliveryWindowsResponseToJSON(value?: DeliveryWindowsResponse | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_windows': ((value.deliveryWindows as Array<any>).map(DeliveryWindowToJSON)),
        'pickup_windows': ((value.pickupWindows as Array<any>).map(PickupWindowToJSON)),
    };
}

