/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Image } from './Image';
// @ts-ignore
import {
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageFromJSONTyped,
// @ts-ignore
    ImageToJSON,
} from './Image';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * 
     * @type {Status}
     * @memberof Product
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    gtin?: string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Product
     */
    images?: Array<Image>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    vatRate?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    supplierId: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Product interface.
 */
// @ts-ignore
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

// @ts-ignore
export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'description': json['description'],
        'status': StatusFromJSON(json['status']),
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'productCategoryId': !exists(json, 'product_category_id') ? undefined : json['product_category_id'],
        'supplierId': json['supplier_id'],
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function ProductToJSON(value?: Product | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'external_code': value.externalCode,
        'description': value.description,
        'status': StatusToJSON(value.status),
        'gtin': value.gtin,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'vat_rate': value.vatRate,
        'product_category_id': value.productCategoryId,
        'supplier_id': value.supplierId,
        'created_at': (value.createdAt.toISOString()),
    };
}

