/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Organization } from './Organization';
// @ts-ignore
import {
// @ts-ignore
    OrganizationFromJSON,
// @ts-ignore
    OrganizationFromJSONTyped,
// @ts-ignore
    OrganizationToJSON,
} from './Organization';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name: string;
    /**
     * 
     * @type {Status}
     * @memberof Customer
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    externalCode?: string;
    /**
     * 
     * @type {Organization}
     * @memberof Customer
     */
    organization?: Organization;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Customer interface.
 */
// @ts-ignore
export function instanceOfCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

// @ts-ignore
export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organizationId': json['organization_id'],
        'name': json['name'],
        'status': StatusFromJSON(json['status']),
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization']),
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function CustomerToJSON(value?: Customer | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organization_id': value.organizationId,
        'name': value.name,
        'status': StatusToJSON(value.status),
        'external_code': value.externalCode,
        'organization': OrganizationToJSON(value.organization),
        'created_at': (value.createdAt.toISOString()),
    };
}

