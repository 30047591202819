/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { NotificationSettings } from './NotificationSettings';
// @ts-ignore
import {
// @ts-ignore
    NotificationSettingsFromJSON,
// @ts-ignore
    NotificationSettingsFromJSONTyped,
// @ts-ignore
    NotificationSettingsToJSON,
} from './NotificationSettings';

/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {NotificationSettings}
     * @memberof UserSettings
     */
    notifications?: NotificationSettings;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
// @ts-ignore
export function instanceOfUserSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function UserSettingsFromJSON(json: any): UserSettings {
    return UserSettingsFromJSONTyped(json, false);
}

// @ts-ignore
export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifications': !exists(json, 'notifications') ? undefined : NotificationSettingsFromJSON(json['notifications']),
    };
}

// @ts-ignore
export function UserSettingsToJSON(value?: UserSettings | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications': NotificationSettingsToJSON(value.notifications),
    };
}

