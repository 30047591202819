import {toStatus} from '@/api/mappers/mapStatus';
import {ProductCategory as ApiProductCategory} from '@/gen/api';
import {ProductCategory as ProductCategoryModel} from '@/models/ProductCategory';

export const toProductCategory = (apiProductCategory: ApiProductCategory): ProductCategoryModel => {
    return {
        ...apiProductCategory,
        id: apiProductCategory.id as string,
        status: toStatus(apiProductCategory.status),
        createdAt: apiProductCategory.createdAt || new Date(0),
    };
};
