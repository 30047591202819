/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof Dimensions
     */
    unit: DimensionsUnitEnum;
}


/**
 * @export
 */
export const DimensionsUnitEnum = {
    Cm: 'cm'
} as const;
export type DimensionsUnitEnum = typeof DimensionsUnitEnum[keyof typeof DimensionsUnitEnum];


/**
 * Check if a given object implements the Dimensions interface.
 */
// @ts-ignore
export function instanceOfDimensions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "length" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

// @ts-ignore
export function DimensionsFromJSON(json: any): Dimensions {
    return DimensionsFromJSONTyped(json, false);
}

// @ts-ignore
export function DimensionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dimensions {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
        'unit': json['unit'],
    };
}

// @ts-ignore
export function DimensionsToJSON(value?: Dimensions | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'length': value.length,
        'width': value.width,
        'height': value.height,
        'unit': value.unit,
    };
}

