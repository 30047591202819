export enum ImageType {
    Original = 'ORIGINAL',
    Thumbnail = 'THUMBNAIL',
    Resized = 'RESIZED',
    Unknown = 'UNKNOWN',
}

export interface ImageSize {
    url: string;
    width: number;
    height: number;
    type: ImageType;
}

export interface Image {
    url: string;
    width: number;
    height: number;
    type: ImageType;
    sizes: ImageSize[];
}
