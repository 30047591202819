/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DayOfTheWeek = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
} as const;
export type DayOfTheWeek = typeof DayOfTheWeek[keyof typeof DayOfTheWeek];


// @ts-ignore
export function DayOfTheWeekFromJSON(json: any): DayOfTheWeek {
    return DayOfTheWeekFromJSONTyped(json, false);
}

// @ts-ignore
export function DayOfTheWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayOfTheWeek {
    return json as DayOfTheWeek;
}

// @ts-ignore
export function DayOfTheWeekToJSON(value?: DayOfTheWeek | null): any {
    return value as any;
}

