import {toPriceList} from '@/api/mappers/mapPriceList';
import {toSku} from '@/api/mappers/mapSku';
import {toStatus} from '@/api/mappers/mapStatus';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {Price as ApiPrice} from '@/gen/api/models/Price';
import {Price as PriceModel} from '@/models/Price';

export const toPrice = (apiPrice: ApiPrice): PriceModel => {
    return {
        ...apiPrice,
        status: apiPrice.status ? toStatus(apiPrice.status) : 'UNKNOWN',
        priceList: apiPrice.priceList ? toPriceList(apiPrice.priceList) : undefined,
        sku: apiPrice.sku ? toSku(apiPrice.sku) : undefined,
        supplier: apiPrice.supplier ? toSupplier(apiPrice.supplier) : undefined,
    };
};
