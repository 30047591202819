import {DeliveryWindow, DeliveryWindowImpl} from '@/models/DeliveryWindow';
import {PickupWindow, PickupWindowImpl} from '@/models/PickupWindow';
import {CartItem, CartItemDetails, CartItemImpl} from '@/modules/retailer/models/CartItem';
import {CalculatedPrice} from '@/modules/retailer/models/Price';

export interface Cart {
    supplierId: string;
    items: CartItem[];
    predictionId?: string;
    deliveryWindow?: DeliveryWindow;
    pickupWindow?: PickupWindow;

    addItem(item: CartItem | CartItem[]): void;
    withItems(item: CartItem | CartItem[]): Cart;
}

type CartWithoutMethods = Omit<Cart, 'addItem' | 'withItems'>;

export interface CartDetails extends CartWithoutMethods {
    supplierName: string;
    currencyCode: string;
    items: CartItemDetails[];
    predictionId?: string;
    deliveryWindow?: DeliveryWindow;
    pickupWindow?: PickupWindow;
    subtotal: CalculatedPrice;
    grandTotal: CalculatedPrice;
}

export class CartImpl implements Cart {
    readonly supplierId: string;
    readonly items: CartItem[];
    readonly deliveryWindow?: DeliveryWindow;
    readonly pickupWindow?: PickupWindow;

    constructor(
        supplierId: string,
        item?: CartItem | CartItem[],
        deliveryWindow?: DeliveryWindow,
        pickupWindow?: PickupWindow,
    ) {
        this.supplierId = supplierId;
        this.items = [];
        this.deliveryWindow = deliveryWindow;
        this.pickupWindow = pickupWindow;

        if (item) {
            this.addItem(item);
        }
    }

    predictionId?: string | undefined;

    addItem(item: CartItem | CartItem[]) {
        if (Array.isArray(item)) {
            item.forEach(singleItem => this.addItem(singleItem));
            return;
        }

        const existingIndex = this.items
            .findIndex(existingItem => existingItem.skuId === item.skuId);

        if (existingIndex > -1) {
            this.items[existingIndex].quantity += item.quantity;
        } else {
            this.items.push(item);
        }
    }

    static fromObject(obj: Cart): CartImpl {
        const items: CartItem[] = obj.items.map(CartItemImpl.fromObject);

        const deliveryWindow = obj.deliveryWindow !== undefined
                ? DeliveryWindowImpl.fromObject(obj.deliveryWindow)
                : undefined;

        const pickupWindow = obj.pickupWindow !== undefined
                ? PickupWindowImpl.fromObject(obj.pickupWindow)
                : undefined;

        return new CartImpl(
            obj.supplierId,
            items,
            deliveryWindow,
            pickupWindow,
        );
    }

    withItems(item: CartItem | CartItem[]): Cart {
        return new CartImpl(this.supplierId, item, this.deliveryWindow, this.pickupWindow);
    }
}
