/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { UserSettings } from './UserSettings';
// @ts-ignore
import {
// @ts-ignore
    UserSettingsFromJSON,
// @ts-ignore
    UserSettingsFromJSONTyped,
// @ts-ignore
    UserSettingsToJSON,
} from './UserSettings';

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    mobileCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    role: string;
    /**
     * 
     * @type {Status}
     * @memberof UserUpdate
     */
    status: Status;
    /**
     * 
     * @type {UserSettings}
     * @memberof UserUpdate
     */
    settings?: UserSettings;
}

/**
 * Check if a given object implements the UserUpdate interface.
 */
// @ts-ignore
export function instanceOfUserUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "timezone" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

// @ts-ignore
export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

// @ts-ignore
export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'fullName': json['full_name'],
        'mobileCountryCode': !exists(json, 'mobile_country_code') ? undefined : json['mobile_country_code'],
        'mobileNumber': !exists(json, 'mobile_number') ? undefined : json['mobile_number'],
        'currencyCode': json['currency_code'],
        'languageCode': json['language_code'],
        'timezone': json['timezone'],
        'role': json['role'],
        'status': StatusFromJSON(json['status']),
        'settings': !exists(json, 'settings') ? undefined : UserSettingsFromJSON(json['settings']),
    };
}

// @ts-ignore
export function UserUpdateToJSON(value?: UserUpdate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'full_name': value.fullName,
        'mobile_country_code': value.mobileCountryCode,
        'mobile_number': value.mobileNumber,
        'currency_code': value.currencyCode,
        'language_code': value.languageCode,
        'timezone': value.timezone,
        'role': value.role,
        'status': StatusToJSON(value.status),
        'settings': UserSettingsToJSON(value.settings),
    };
}

