/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';
// @ts-ignore
import type { DiscountFixedAmount } from './DiscountFixedAmount';
// @ts-ignore
import {
// @ts-ignore
    DiscountFixedAmountFromJSON,
// @ts-ignore
    DiscountFixedAmountFromJSONTyped,
// @ts-ignore
    DiscountFixedAmountToJSON,
} from './DiscountFixedAmount';
// @ts-ignore
import type { DiscountPercentage } from './DiscountPercentage';
// @ts-ignore
import {
// @ts-ignore
    DiscountPercentageFromJSON,
// @ts-ignore
    DiscountPercentageFromJSONTyped,
// @ts-ignore
    DiscountPercentageToJSON,
} from './DiscountPercentage';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { TimeWindow } from './TimeWindow';
// @ts-ignore
import {
// @ts-ignore
    TimeWindowFromJSON,
// @ts-ignore
    TimeWindowFromJSONTyped,
// @ts-ignore
    TimeWindowToJSON,
} from './TimeWindow';

/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    name: string;
    /**
     * 
     * @type {TimeWindow}
     * @memberof Promotion
     */
    effectivePeriod?: TimeWindow;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    discountType: PromotionDiscountTypeEnum;
    /**
     * 
     * @type {Array<DiscountFixedAmount>}
     * @memberof Promotion
     */
    fixedAmounts?: Array<DiscountFixedAmount>;
    /**
     * 
     * @type {Array<DiscountPercentage>}
     * @memberof Promotion
     */
    percentages?: Array<DiscountPercentage>;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    currencyCode?: string;
    /**
     * 
     * @type {Amount}
     * @memberof Promotion
     */
    minimumAmount?: Amount;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    minimumQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    skuId?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    productCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    productListId?: string;
    /**
     * 
     * @type {Status}
     * @memberof Promotion
     */
    status: Status;
    /**
     * 
     * @type {Date}
     * @memberof Promotion
     */
    createdAt: Date;
}


/**
 * @export
 */
export const PromotionDiscountTypeEnum = {
    FixedAmount: 'fixed_amount',
    Percentage: 'percentage'
} as const;
export type PromotionDiscountTypeEnum = typeof PromotionDiscountTypeEnum[keyof typeof PromotionDiscountTypeEnum];


/**
 * Check if a given object implements the Promotion interface.
 */
// @ts-ignore
export function instanceOfPromotion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "discountType" in value;
    isInstance = isInstance && "minimumQuantity" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function PromotionFromJSON(json: any): Promotion {
    return PromotionFromJSONTyped(json, false);
}

// @ts-ignore
export function PromotionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Promotion {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'effectivePeriod': !exists(json, 'effective_period') ? undefined : TimeWindowFromJSON(json['effective_period']),
        'discountType': json['discount_type'],
        'fixedAmounts': !exists(json, 'fixed_amounts') ? undefined : ((json['fixed_amounts'] as Array<any>).map(DiscountFixedAmountFromJSON)),
        'percentages': !exists(json, 'percentages') ? undefined : ((json['percentages'] as Array<any>).map(DiscountPercentageFromJSON)),
        'currencyCode': !exists(json, 'currency_code') ? undefined : json['currency_code'],
        'minimumAmount': !exists(json, 'minimum_amount') ? undefined : AmountFromJSON(json['minimum_amount']),
        'minimumQuantity': json['minimum_quantity'],
        'supplierId': json['supplier_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'skuId': !exists(json, 'sku_id') ? undefined : json['sku_id'],
        'productCategoryId': !exists(json, 'product_category_id') ? undefined : json['product_category_id'],
        'productListId': !exists(json, 'product_list_id') ? undefined : json['product_list_id'],
        'status': StatusFromJSON(json['status']),
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function PromotionToJSON(value?: Promotion | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'effective_period': TimeWindowToJSON(value.effectivePeriod),
        'discount_type': value.discountType,
        'fixed_amounts': value.fixedAmounts === undefined ? undefined : ((value.fixedAmounts as Array<any>).map(DiscountFixedAmountToJSON)),
        'percentages': value.percentages === undefined ? undefined : ((value.percentages as Array<any>).map(DiscountPercentageToJSON)),
        'currency_code': value.currencyCode,
        'minimum_amount': AmountToJSON(value.minimumAmount),
        'minimum_quantity': value.minimumQuantity,
        'supplier_id': value.supplierId,
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'sku_id': value.skuId,
        'product_category_id': value.productCategoryId,
        'product_list_id': value.productListId,
        'status': StatusToJSON(value.status),
        'created_at': (value.createdAt.toISOString()),
    };
}

