import {DayOfTheWeek as ApiDayOfTheWeek} from '@/gen/api';
import {AllDaysOfTheWeek, DayOfTheWeek} from '@/models/DayOfTheWeek';

export const mapDayOfTheWeek = (dayOfTheWeek: string): DayOfTheWeek | undefined => {
    return AllDaysOfTheWeek.find((day: DayOfTheWeek) => String(day) === dayOfTheWeek);
};

export const toApiDayOfTheWeek = (day: DayOfTheWeek): ApiDayOfTheWeek => {
    return DayOfTheWeek[day];
};
