import {toPrice} from '@/api/mappers/mapPrice';
import AbstractApi from '@/api/mocks/AbstractApi';
import {addAssociationsToPrice, mockPricesData} from '@/api/mocks/data/prices';
import {calculatePagination} from '@/api/mocks/Pagination';
import PricesApi from '@/api/PricesApi';
import {Price, PriceAssociation, PriceCreate, Prices} from '@/models/Price';

class MockPricesApiImpl extends AbstractApi implements PricesApi {
    async listPrices(priceListId?: string, offset: number = 0, limit: number = 10, include?: PriceAssociation[]): Promise<Prices> {
        await this.sleep();

        const data = include
            ? mockPricesData.map((price) => addAssociationsToPrice(price, include))
            : mockPricesData;

        const filteredItems = priceListId
            ? data.filter((price) => price.priceListId === priceListId)
            : data;

        return calculatePagination(filteredItems.map(toPrice), offset, limit);
    }

    async findPrices(query?: string, priceListId?: string, offset: number = 0, limit: number = 10, include?: PriceAssociation[]): Promise<Prices> {
        await this.sleep();

        const data = include
            ? mockPricesData.map((price) => addAssociationsToPrice(price, include))
            : mockPricesData;

        let items = [...data];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(price => price.skuId.toLowerCase().includes(queryLowerCase));
        }

        const filteredItems = priceListId
            ? items.filter((price) => price.priceListId === priceListId)
            : items;

        return calculatePagination(filteredItems.map(toPrice), offset, limit);
    }

    async createPrice(_price: PriceCreate): Promise<void> {
        await this.sleep();
    }

    async bulkUpdatePrices(_prices: Price[]): Promise<void> {
        await this.sleep();
    }

    async getPrice(id: string, include?: PriceAssociation[]): Promise<Price | undefined> {
        await this.sleep();

        const data = include
            ? mockPricesData.map((price) => addAssociationsToPrice(price, include))
            : mockPricesData;

        const price = data.find(price => price.id === id);
        if (price) {
            return toPrice(price);
        }
        return undefined;
    }

    async updatePrice(_id: string, _price: Price): Promise<void> {
        await this.sleep();
    }

    async deletePrice(_id: string): Promise<void> {
        await this.sleep();
    }
}

export default MockPricesApiImpl;
