/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  User,
// @ts-ignore
  UserCreate,
// @ts-ignore
  UserListResponse,
// @ts-ignore
  UserUpdate,
// @ts-ignore
  UserVerificationRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    UserFromJSON,
// @ts-ignore
    UserToJSON,
// @ts-ignore
    UserCreateFromJSON,
// @ts-ignore
    UserCreateToJSON,
// @ts-ignore
    UserListResponseFromJSON,
// @ts-ignore
    UserListResponseToJSON,
// @ts-ignore
    UserUpdateFromJSON,
// @ts-ignore
    UserUpdateToJSON,
// @ts-ignore
    UserVerificationRequestFromJSON,
// @ts-ignore
    UserVerificationRequestToJSON,
} from '../models/index';

export interface CreateUserRequest {
    userCreate: UserCreate;
}

export interface GetUserRequest {
    id: string;
}

export interface ListUsersRequest {
    query?: string;
    limit?: number;
    offset?: number;
}

export interface UpdateCurrentUserRequest {
    userUpdate: UserUpdate;
}

export interface UpdateUserRequest {
    id: string;
    userUpdate: UserUpdate;
}

export interface VerifyUserRequest {
    id: string;
    userVerificationRequest: UserVerificationRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Create a new user
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new user
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUserRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve details of the authenticated user
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Retrieve details of the authenticated user
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve details of a specific user
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Retrieve details of a specific user
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of users
     */
    async listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of users
     */
    async listUsers(requestParameters: ListUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListResponse> {
        const response = await this.listUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save details of the authenticated user
     */
    async updateCurrentUserRaw(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling updateCurrentUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/current`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save details of the authenticated user
     */
    async updateCurrentUser(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCurrentUserRaw(requestParameters, initOverrides);
    }

    /**
     * Update details of a specific user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUser.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update details of a specific user
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserRaw(requestParameters, initOverrides);
    }

    /**
     * Verify a user with a verification code
     */
    async verifyUserRaw(requestParameters: VerifyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verifyUser.');
        }

        if (requestParameters.userVerificationRequest === null || requestParameters.userVerificationRequest === undefined) {
            throw new runtime.RequiredError('userVerificationRequest','Required parameter requestParameters.userVerificationRequest was null or undefined when calling verifyUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/verification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserVerificationRequestToJSON(requestParameters.userVerificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify a user with a verification code
     */
    async verifyUser(requestParameters: VerifyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyUserRaw(requestParameters, initOverrides);
    }

}
