/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import type { OrderCreate } from './OrderCreate';
// @ts-ignore
import {
// @ts-ignore
    instanceOfOrderCreate,
// @ts-ignore
    OrderCreateFromJSON,
// @ts-ignore
    OrderCreateFromJSONTyped,
// @ts-ignore
    OrderCreateToJSON,
} from './OrderCreate';

/**
 * @type OrderCreateRequest
 * 
 * @export
 */
export type OrderCreateRequest = OrderCreate;

// @ts-ignore
export function OrderCreateRequestFromJSON(json: any): OrderCreateRequest {
    return OrderCreateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...OrderCreateFromJSONTyped(json, true) };
}

// @ts-ignore
export function OrderCreateRequestToJSON(value?: OrderCreateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfOrderCreate(value)) {
        return OrderCreateToJSON(value as OrderCreate);
    }

    return {};
}

