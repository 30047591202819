/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Weight
 */
export interface Weight {
    /**
     * 
     * @type {number}
     * @memberof Weight
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof Weight
     */
    unit: WeightUnitEnum;
}


/**
 * @export
 */
export const WeightUnitEnum = {
    Kg: 'kg'
} as const;
export type WeightUnitEnum = typeof WeightUnitEnum[keyof typeof WeightUnitEnum];


/**
 * Check if a given object implements the Weight interface.
 */
// @ts-ignore
export function instanceOfWeight(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

// @ts-ignore
export function WeightFromJSON(json: any): Weight {
    return WeightFromJSONTyped(json, false);
}

// @ts-ignore
export function WeightFromJSONTyped(json: any, ignoreDiscriminator: boolean): Weight {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

// @ts-ignore
export function WeightToJSON(value?: Weight | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'unit': value.unit,
    };
}

