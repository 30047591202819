/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResult
 */
export interface LoginResult {
    /**
     * 
     * @type {string}
     * @memberof LoginResult
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResult
     */
    idToken?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResult
     */
    refreshToken?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResult
     */
    expiresIn?: number;
}

/**
 * Check if a given object implements the LoginResult interface.
 */
// @ts-ignore
export function instanceOfLoginResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function LoginResultFromJSON(json: any): LoginResult {
    return LoginResultFromJSONTyped(json, false);
}

// @ts-ignore
export function LoginResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResult {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
        'idToken': !exists(json, 'id_token') ? undefined : json['id_token'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'expiresIn': !exists(json, 'expires_in') ? undefined : json['expires_in'],
    };
}

// @ts-ignore
export function LoginResultToJSON(value?: LoginResult | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'id_token': value.idToken,
        'refresh_token': value.refreshToken,
        'expires_in': value.expiresIn,
    };
}

