import {Configuration} from '@/api';
import {toApiStoreCreateRequest, toApiStoreUpdateRequest, toStore} from '@/api/mappers/mapStore';
import {StoresApi as ApiStoresApi} from '@/gen/api/apis/StoresApi';
import {Store, StoreAssociation, StoreCreate, Stores, StoreUpdate} from '@/models/Store';

export interface StoreFilters {
    customerId: string;
    supplierId: string;
}

interface StoresApi {
    listStores(offset?: number, limit?: number, include?: StoreAssociation[]): Promise<Stores>;
    findStores(query?: string, filters?: Partial<StoreFilters>, offset?: number, limit?: number, include?: StoreAssociation[]): Promise<Stores>;
    getStore(id: string, include?: StoreAssociation[]): Promise<Store | undefined>;
    createStore(store: StoreCreate): Promise<void>;
    updateStore(id: string, store: StoreUpdate): Promise<void>;
    deleteStore(id: string): Promise<void>;
}

export class StoresApiImpl implements StoresApi {
    private storesApi: ApiStoresApi;

    constructor(configuration: Configuration) {
        this.storesApi = new ApiStoresApi(configuration);
    }

    async listStores(offset: number = 0, limit: number = 10, include?: StoreAssociation[]): Promise<Stores> {
        try {
            const response = await this.storesApi.listStores({offset, limit, include});
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toStore),
            };
        } catch (error) {
            console.error('Error listing stores:', error);
            throw error;
        }
    }

    async findStores(query?: string, filters?: Partial<StoreFilters>, offset?: number, limit?: number, include?: StoreAssociation[]): Promise<Stores> {
        try {
            const response = await this.storesApi.listStores({
                query,
                offset,
                limit,
                include,
                ...filters,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toStore),
            };
        } catch (error) {
            console.error('Error finding stores:', error);
            throw error;
        }
    }

    async getStore(id: string, include?: StoreAssociation[]): Promise<Store | undefined> {
        try {
            const response = await this.storesApi.getStore({
                id,
                include,
            });
            return toStore(response);
        } catch (error) {
            console.error('Error getting store:', error);
            throw error;
        }
    }

    async createStore(store: StoreCreate): Promise<void> {
        try {
            await this.storesApi.createStore({
                storeCreateRequest: toApiStoreCreateRequest(store),
            });
        } catch (error) {
            console.error('Error creating store:', error);
            throw error;
        }
    }

    async updateStore(id: string, store: StoreUpdate): Promise<void> {
        try {
            await this.storesApi.updateStore({
                id,
                storeUpdateRequest: toApiStoreUpdateRequest(store),
            });
        } catch (error) {
            console.error('Error updating store:', error);
            throw error;
        }
    }

    async deleteStore(id: string): Promise<void> {
        try {
            await this.storesApi.deleteStore({id});
        } catch (error) {
            console.error('Error deleting store:', error);
            throw error;
        }
    }
}

export default StoresApi;
