/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountPercentage
 */
export interface DiscountPercentage {
    /**
     * 
     * @type {number}
     * @memberof DiscountPercentage
     */
    percentage: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountPercentage
     */
    quantityFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountPercentage
     */
    quantityTo?: number;
}

/**
 * Check if a given object implements the DiscountPercentage interface.
 */
// @ts-ignore
export function instanceOfDiscountPercentage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "percentage" in value;

    return isInstance;
}

// @ts-ignore
export function DiscountPercentageFromJSON(json: any): DiscountPercentage {
    return DiscountPercentageFromJSONTyped(json, false);
}

// @ts-ignore
export function DiscountPercentageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountPercentage {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'percentage': json['percentage'],
        'quantityFrom': !exists(json, 'quantity_from') ? undefined : json['quantity_from'],
        'quantityTo': !exists(json, 'quantity_to') ? undefined : json['quantity_to'],
    };
}

// @ts-ignore
export function DiscountPercentageToJSON(value?: DiscountPercentage | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'percentage': value.percentage,
        'quantity_from': value.quantityFrom,
        'quantity_to': value.quantityTo,
    };
}

