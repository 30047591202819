/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { UserSettings } from './UserSettings';
// @ts-ignore
import {
// @ts-ignore
    UserSettingsFromJSON,
// @ts-ignore
    UserSettingsFromJSONTyped,
// @ts-ignore
    UserSettingsToJSON,
} from './UserSettings';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobileCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: string;
    /**
     * 
     * @type {Status}
     * @memberof User
     */
    status: Status;
    /**
     * 
     * @type {UserSettings}
     * @memberof User
     */
    settings?: UserSettings;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    storeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the User interface.
 */
// @ts-ignore
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "timezone" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

// @ts-ignore
export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'fullName': json['full_name'],
        'mobileCountryCode': !exists(json, 'mobile_country_code') ? undefined : json['mobile_country_code'],
        'mobileNumber': !exists(json, 'mobile_number') ? undefined : json['mobile_number'],
        'currencyCode': json['currency_code'],
        'languageCode': json['language_code'],
        'timezone': json['timezone'],
        'role': json['role'],
        'status': StatusFromJSON(json['status']),
        'settings': !exists(json, 'settings') ? undefined : UserSettingsFromJSON(json['settings']),
        'organizationId': json['organization_id'],
        'supplierId': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function UserToJSON(value?: User | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'full_name': value.fullName,
        'mobile_country_code': value.mobileCountryCode,
        'mobile_number': value.mobileNumber,
        'currency_code': value.currencyCode,
        'language_code': value.languageCode,
        'timezone': value.timezone,
        'role': value.role,
        'status': StatusToJSON(value.status),
        'settings': UserSettingsToJSON(value.settings),
        'organization_id': value.organizationId,
        'supplier_id': value.supplierId,
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'created_at': (value.createdAt.toISOString()),
    };
}

