/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface PriceUpdate
 */
export interface PriceUpdate {
    /**
     * 
     * @type {string}
     * @memberof PriceUpdate
     */
    priceListId?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdate
     */
    skuId?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdate
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdate
     */
    storeId?: string;
    /**
     * 
     * @type {Amount}
     * @memberof PriceUpdate
     */
    price: Amount;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdate
     */
    quantityFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdate
     */
    quantityTo?: number;
}

/**
 * Check if a given object implements the PriceUpdate interface.
 */
// @ts-ignore
export function instanceOfPriceUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

// @ts-ignore
export function PriceUpdateFromJSON(json: any): PriceUpdate {
    return PriceUpdateFromJSONTyped(json, false);
}

// @ts-ignore
export function PriceUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceUpdate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceListId': !exists(json, 'price_list_id') ? undefined : json['price_list_id'],
        'skuId': !exists(json, 'sku_id') ? undefined : json['sku_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'price': AmountFromJSON(json['price']),
        'quantityFrom': !exists(json, 'quantity_from') ? undefined : json['quantity_from'],
        'quantityTo': !exists(json, 'quantity_to') ? undefined : json['quantity_to'],
    };
}

// @ts-ignore
export function PriceUpdateToJSON(value?: PriceUpdate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price_list_id': value.priceListId,
        'sku_id': value.skuId,
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'price': AmountToJSON(value.price),
        'quantity_from': value.quantityFrom,
        'quantity_to': value.quantityTo,
    };
}

