/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  NotificationListResponse,
// @ts-ignore
  NotificationMarkAsReadRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    NotificationListResponseFromJSON,
// @ts-ignore
    NotificationListResponseToJSON,
// @ts-ignore
    NotificationMarkAsReadRequestFromJSON,
// @ts-ignore
    NotificationMarkAsReadRequestToJSON,
} from '../models/index';

export interface ListNotificationsRequest {
    limit?: number;
    offset?: number;
}

export interface MarkNotificationsAsReadRequest {
    notificationMarkAsReadRequest: NotificationMarkAsReadRequest;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Get Notifications
     */
    async listNotificationsRaw(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationListResponseFromJSON(jsonValue));
    }

    /**
     * Get Notifications
     */
    async listNotifications(requestParameters: ListNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationListResponse> {
        const response = await this.listNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark notifications as read
     */
    async markNotificationsAsReadRaw(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationMarkAsReadRequest === null || requestParameters.notificationMarkAsReadRequest === undefined) {
            throw new runtime.RequiredError('notificationMarkAsReadRequest','Required parameter requestParameters.notificationMarkAsReadRequest was null or undefined when calling markNotificationsAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notifications/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationMarkAsReadRequestToJSON(requestParameters.notificationMarkAsReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark notifications as read
     */
    async markNotificationsAsRead(requestParameters: MarkNotificationsAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotificationsAsReadRaw(requestParameters, initOverrides);
    }

}
