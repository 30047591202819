/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { ImageSize } from './ImageSize';
// @ts-ignore
import {
// @ts-ignore
    ImageSizeFromJSON,
// @ts-ignore
    ImageSizeFromJSONTyped,
// @ts-ignore
    ImageSizeToJSON,
} from './ImageSize';

/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * URL of the image
     * @type {string}
     * @memberof Image
     */
    url: string;
    /**
     * Width of the image in pixels
     * @type {number}
     * @memberof Image
     */
    width: number;
    /**
     * Height of the image in pixels
     * @type {number}
     * @memberof Image
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    type: ImageTypeEnum;
    /**
     * 
     * @type {Array<ImageSize>}
     * @memberof Image
     */
    sizes?: Array<ImageSize>;
}


/**
 * @export
 */
export const ImageTypeEnum = {
    Thumbnail: 'thumbnail',
    Original: 'original',
    Resized: 'resized'
} as const;
export type ImageTypeEnum = typeof ImageTypeEnum[keyof typeof ImageTypeEnum];


/**
 * Check if a given object implements the Image interface.
 */
// @ts-ignore
export function instanceOfImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

// @ts-ignore
export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

// @ts-ignore
export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'width': json['width'],
        'height': json['height'],
        'type': json['type'],
        'sizes': !exists(json, 'sizes') ? undefined : ((json['sizes'] as Array<any>).map(ImageSizeFromJSON)),
    };
}

// @ts-ignore
export function ImageToJSON(value?: Image | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'width': value.width,
        'height': value.height,
        'type': value.type,
        'sizes': value.sizes === undefined ? undefined : ((value.sizes as Array<any>).map(ImageSizeToJSON)),
    };
}

