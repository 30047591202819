import {toPromotion} from '@/api/mappers/mapPromotion';
import AbstractApi from '@/api/mocks/AbstractApi';
import {mockPromotionsData} from '@/api/mocks/data/promotions';
import {calculatePagination} from '@/api/mocks/Pagination';
import PromotionsApi from '@/api/PromotionsApi';
import {Promotion, PromotionCreate, Promotions} from '@/models/Promotion';

class MockPromotionsApiImpl extends AbstractApi implements PromotionsApi {
    async listPromotions(offset: number = 0, limit: number = 10): Promise<Promotions> {
        await this.sleep();

        return calculatePagination(mockPromotionsData.map(toPromotion), offset, limit);
    }

    async findPromotions(query?: string, offset: number = 0, limit: number = 10): Promise<Promotions> {
        await this.sleep();

        let items = [...mockPromotionsData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(promotion => promotion.name.toLowerCase().includes(queryLowerCase));
        }

        return calculatePagination(items.map(toPromotion), offset, limit);
    }

    async createPromotion(_promotion: PromotionCreate): Promise<void> {
        await this.sleep();
    }

    async bulkUpdatePromotions(_promotions: Promotion[]): Promise<void> {
        await this.sleep();
    }

    async getPromotion(id: string): Promise<Promotion | undefined> {
        await this.sleep();

        const promotion = mockPromotionsData.find(promotion => promotion.id === id);
        if (promotion) {
            return toPromotion(promotion);
        }
        return undefined;
    }

    async updatePromotion(_id: string, _promotion: Promotion): Promise<void> {
        await this.sleep();
    }

    async deletePromotion(_id: string): Promise<void> {
        await this.sleep();
    }
}

export default MockPromotionsApiImpl;
