import {toStatus} from '@/api/mappers/mapStatus';
import {Supplier as ApiSupplier, SupplierAddress as ApiSupplierAddress} from '@/gen/api';
import {DeliveryWindowImpl} from '@/models/DeliveryWindow';
import {SupplierAddress as SupplierAddressModel, SupplierAddressType, Supplier as SupplierModel} from '@/models/Supplier';

const toSupplierAddressType = (value: string): SupplierAddressType => {
    switch (value.toLowerCase()) {
        case String(SupplierAddressType.BUSINESS):
            return SupplierAddressType.BUSINESS;
        case String(SupplierAddressType.BILLING):
            return SupplierAddressType.BILLING;
    }
    return SupplierAddressType.UNKNOWN;
};

export const toSupplierAddress = (apiAddress: ApiSupplierAddress): SupplierAddressModel => {
    return {
        ...apiAddress,
        type: toSupplierAddressType(apiAddress.type),
    };
};

export const toSupplier = (apiSupplier: ApiSupplier): SupplierModel => {
    return {
        ...apiSupplier,
        id: apiSupplier.id as string,
        status: toStatus(apiSupplier.status),
        addresses: apiSupplier.addresses.map(toSupplierAddress),
        deliveryWindows: apiSupplier.deliveryWindows.map(DeliveryWindowImpl.fromObject),
    };
};
