/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { OrderItemUpdate } from './OrderItemUpdate';
// @ts-ignore
import {
// @ts-ignore
    OrderItemUpdateFromJSON,
// @ts-ignore
    OrderItemUpdateFromJSONTyped,
// @ts-ignore
    OrderItemUpdateToJSON,
} from './OrderItemUpdate';
// @ts-ignore
import type { TimeWindow } from './TimeWindow';
// @ts-ignore
import {
// @ts-ignore
    TimeWindowFromJSON,
// @ts-ignore
    TimeWindowFromJSONTyped,
// @ts-ignore
    TimeWindowToJSON,
} from './TimeWindow';

/**
 * 
 * @export
 * @interface OrderUpdateRequest
 */
export interface OrderUpdateRequest {
    /**
     * 
     * @type {TimeWindow}
     * @memberof OrderUpdateRequest
     */
    confirmedDeliveryWindow?: TimeWindow;
    /**
     * 
     * @type {Array<OrderItemUpdate>}
     * @memberof OrderUpdateRequest
     */
    items: Array<OrderItemUpdate>;
}

/**
 * Check if a given object implements the OrderUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfOrderUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

// @ts-ignore
export function OrderUpdateRequestFromJSON(json: any): OrderUpdateRequest {
    return OrderUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmedDeliveryWindow': !exists(json, 'confirmed_delivery_window') ? undefined : TimeWindowFromJSON(json['confirmed_delivery_window']),
        'items': ((json['items'] as Array<any>).map(OrderItemUpdateFromJSON)),
    };
}

// @ts-ignore
export function OrderUpdateRequestToJSON(value?: OrderUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmed_delivery_window': TimeWindowToJSON(value.confirmedDeliveryWindow),
        'items': ((value.items as Array<any>).map(OrderItemUpdateToJSON)),
    };
}

