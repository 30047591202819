import {toApiCustomer, toCustomer} from '@/api/mappers/mapCustomer';
import {mapDayOfTheWeek} from '@/api/mappers/mapDayOfTheWeek';
import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {toApiWarehouse, toWarehouse} from '@/api/mappers/mapWarehouse';
import {
    Store as ApiStore,
    StoreAddress as ApiStoreAddress,
    StoreReceivingWindow as ApiStoreReceivingWindow,
    StoreCreateRequest,
    StoreUpdateRequest,
} from '@/gen/api';
import {
    StoreAddress as StoreAddressModel,
    StoreAddressType,
    StoreCreate as StoreCreateModel,
    Store as StoreModel,
    StoreReceivingWindow as StoreReceivingWindowModel,
    StoreUpdate as StoreUpdateModel,
} from '@/models/Store';
import {formatTime, TimeImpl} from '@/models/Time';

const toStoreAddressType = (value: string): StoreAddressType => {
    switch (value.toLowerCase()) {
        case String(StoreAddressType.BUSINESS):
            return StoreAddressType.BUSINESS;
        case String(StoreAddressType.BILLING):
            return StoreAddressType.BILLING;
        case String(StoreAddressType.DELIVERY):
            return StoreAddressType.DELIVERY;
    }
    return StoreAddressType.UNKNOWN;
};

export const toStoreAddress = (apiAddress: ApiStoreAddress): StoreAddressModel => {
    return {
        ...apiAddress,
        type: toStoreAddressType(apiAddress.type),
    };
};

const toReceivingWindows = (apiStoreReceivingWindows: ApiStoreReceivingWindow[]): StoreReceivingWindowModel[] => {
    const receivingWindows: (StoreReceivingWindowModel | undefined)[] = apiStoreReceivingWindows.map((apiStoreReceivingWindow) => {
        const day = mapDayOfTheWeek(apiStoreReceivingWindow.day);
        const startTime = TimeImpl.fromString(apiStoreReceivingWindow.startTime);
        const endTime = TimeImpl.fromString(apiStoreReceivingWindow.endTime);

        if (!day || !startTime || !endTime) {
            return undefined;
        }

        return {
            day: day,
            startTime: startTime,
            endTime: endTime,
        };
    });

    return receivingWindows.filter((item) => item !== undefined) as StoreReceivingWindowModel[];
};

const toApiReceivingWindow = (receivingWindow: StoreReceivingWindowModel): ApiStoreReceivingWindow => {
    return {
        ...receivingWindow,
        startTime: formatTime(receivingWindow.startTime),
        endTime: formatTime(receivingWindow.endTime),
    };
};

export const toStore = (apiStore: ApiStore): StoreModel => {
    return {
        ...apiStore,
        id: apiStore.id as string,
        status: toStatus(apiStore.status),
        addresses: apiStore.addresses.map(toStoreAddress),
        createdAt: apiStore.createdAt || new Date(0),
        receivingWindows: toReceivingWindows(apiStore.receivingWindows),
        customer: apiStore.customer ? toCustomer(apiStore.customer) : undefined,
        warehouse: apiStore.warehouse ? toWarehouse(apiStore.warehouse) : undefined,
    };
};

export const toApiStore = (store: StoreModel): ApiStore => {
    return {
        ...store,
        status: toApiStatus(store.status),
        receivingWindows: store.receivingWindows.map(toApiReceivingWindow),
        customer: store.customer ? toApiCustomer(store.customer) : undefined,
        warehouse: store.warehouse ? toApiWarehouse(store.warehouse) : undefined,
    };
};

export const toApiStoreCreateRequest = (store: StoreCreateModel): StoreCreateRequest => {
    return {
        ...store,
        receivingWindows: store.receivingWindows.map(toApiReceivingWindow),
    };
};

export const toApiStoreUpdateRequest = (store: StoreUpdateModel): StoreUpdateRequest => {
    return {
        ...store,
        status: toApiStatus(store.status),
        receivingWindows: store.receivingWindows.map(toApiReceivingWindow),
    };
};
