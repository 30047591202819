/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface ProductCategoryUpdateRequest
 */
export interface ProductCategoryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryUpdateRequest
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductCategoryUpdateRequest
     */
    images?: Array<string>;
    /**
     * 
     * @type {Status}
     * @memberof ProductCategoryUpdateRequest
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryUpdateRequest
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryUpdateRequest
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryUpdateRequest
     */
    supplierId?: string;
}

/**
 * Check if a given object implements the ProductCategoryUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfProductCategoryUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

// @ts-ignore
export function ProductCategoryUpdateRequestFromJSON(json: any): ProductCategoryUpdateRequest {
    return ProductCategoryUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function ProductCategoryUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategoryUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'supplierId': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
    };
}

// @ts-ignore
export function ProductCategoryUpdateRequestToJSON(value?: ProductCategoryUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'images': value.images,
        'status': StatusToJSON(value.status),
        'parent_id': value.parentId,
        'external_code': value.externalCode,
        'supplier_id': value.supplierId,
    };
}

