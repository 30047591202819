/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { DeliveryWindow } from './DeliveryWindow';
// @ts-ignore
import {
// @ts-ignore
    DeliveryWindowFromJSON,
// @ts-ignore
    DeliveryWindowFromJSONTyped,
// @ts-ignore
    DeliveryWindowToJSON,
} from './DeliveryWindow';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { SupplierAddress } from './SupplierAddress';
// @ts-ignore
import {
// @ts-ignore
    SupplierAddressFromJSON,
// @ts-ignore
    SupplierAddressFromJSONTyped,
// @ts-ignore
    SupplierAddressToJSON,
} from './SupplierAddress';

/**
 * 
 * @export
 * @interface SupplierUpdateRequest
 */
export interface SupplierUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    customerCode?: string;
    /**
     * Use the format "1d" for 1 day, "30d" for 30 days, etc.
     * @type {string}
     * @memberof SupplierUpdateRequest
     */
    creditTerm?: string;
    /**
     * 
     * @type {Status}
     * @memberof SupplierUpdateRequest
     */
    status: Status;
    /**
     * 
     * @type {Array<SupplierAddress>}
     * @memberof SupplierUpdateRequest
     */
    addresses: Array<SupplierAddress>;
    /**
     * 
     * @type {Array<DeliveryWindow>}
     * @memberof SupplierUpdateRequest
     */
    deliveryWindows: Array<DeliveryWindow>;
}

/**
 * Check if a given object implements the SupplierUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfSupplierUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "addresses" in value;
    isInstance = isInstance && "deliveryWindows" in value;

    return isInstance;
}

// @ts-ignore
export function SupplierUpdateRequestFromJSON(json: any): SupplierUpdateRequest {
    return SupplierUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SupplierUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierUpdateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'email': json['email'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'customerCode': !exists(json, 'customer_code') ? undefined : json['customer_code'],
        'creditTerm': !exists(json, 'credit_term') ? undefined : json['credit_term'],
        'status': StatusFromJSON(json['status']),
        'addresses': ((json['addresses'] as Array<any>).map(SupplierAddressFromJSON)),
        'deliveryWindows': ((json['delivery_windows'] as Array<any>).map(DeliveryWindowFromJSON)),
    };
}

// @ts-ignore
export function SupplierUpdateRequestToJSON(value?: SupplierUpdateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'email': value.email,
        'external_code': value.externalCode,
        'customer_code': value.customerCode,
        'credit_term': value.creditTerm,
        'status': StatusToJSON(value.status),
        'addresses': ((value.addresses as Array<any>).map(SupplierAddressToJSON)),
        'delivery_windows': ((value.deliveryWindows as Array<any>).map(DeliveryWindowToJSON)),
    };
}

