/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { OrderCreateResult } from './OrderCreateResult';
// @ts-ignore
import {
// @ts-ignore
    OrderCreateResultFromJSON,
// @ts-ignore
    OrderCreateResultFromJSONTyped,
// @ts-ignore
    OrderCreateResultToJSON,
} from './OrderCreateResult';

/**
 * 
 * @export
 * @interface BulkOrderCreateResponse
 */
export interface BulkOrderCreateResponse {
    /**
     * 
     * @type {Array<OrderCreateResult>}
     * @memberof BulkOrderCreateResponse
     */
    orders: Array<OrderCreateResult>;
}

/**
 * Check if a given object implements the BulkOrderCreateResponse interface.
 */
// @ts-ignore
export function instanceOfBulkOrderCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orders" in value;

    return isInstance;
}

// @ts-ignore
export function BulkOrderCreateResponseFromJSON(json: any): BulkOrderCreateResponse {
    return BulkOrderCreateResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function BulkOrderCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkOrderCreateResponse {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': ((json['orders'] as Array<any>).map(OrderCreateResultFromJSON)),
    };
}

// @ts-ignore
export function BulkOrderCreateResponseToJSON(value?: BulkOrderCreateResponse | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': ((value.orders as Array<any>).map(OrderCreateResultToJSON)),
    };
}

