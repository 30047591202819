import {Image} from '@/models/Image';
import type {Price} from '@/models/Price';
import type {Product} from '@/models/Product';
import {ProductCategory} from '@/models/ProductCategory';
import {Promotion} from '@/models/Promotion';
import {Status} from '@/models/Status';
import {Supplier} from '@/models/Supplier';

type DimensionsUnit = 'cm';
type WeightUnit = 'kg';

interface Dimensions {
    length: number;
    width: number;
    height: number;
    unit: DimensionsUnit;
}

interface Weight {
    value: number;
    unit: WeightUnit;
}

export enum SkuAssociation {
    PRICES = 'prices',
    PRODUCT = 'product',
    SUPPLIER = 'supplier',
    PROMOTIONS = 'promotions',
    ALL = 'all',
}

export interface Sku {
    id: string;
    code: string;
    productId: string;
    supplierId: string;
    externalCode?: string;
    name: string;
    description: string;
    lengthCm?: number;
    widthCm?: number;
    heightCm?: number;
    weightKg?: number;
    quantity?: number;
    moq?: number;
    images: Image[];
    customData?: { [key: string]: string };
    status?: Status;
    product?: Product;
    prices?: Price[];
    supplier?: Supplier;
    promotions?: Promotion[];
    createdAt: Date;
}

export interface Skus {
    items: Sku[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export interface SkuCreate {
    code: string;
    externalCode?: string;
    supplierId: string;
    name: string;
    description: string;
    dimensions?: Dimensions;
    weight?: Weight;
    quantity?: number;
    moq?: number;
    status?: Status;
    productId?: string;
    product?: Product;
    productCategoryId?: string;
    productCategoryName?: string;
    productCategory?: ProductCategory;
}

export interface SkuUpdate {
    id: string;
    code: string;
    externalCode?: string;
    productId: string;
    supplierId: string;
    name: string;
    description: string;
    dimensions?: Dimensions;
    weight?: Weight;
    quantity?: number;
    moq?: number;
    status?: Status;
    product?: Product;
}
