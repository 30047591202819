import {Organization as ApiOrganization} from '@/gen/api';
import {Organization as OrganizationModel} from '@/models/Organization';

export const toOrganization = (apiOrganization: ApiOrganization): OrganizationModel => {
    return {
        ...apiOrganization,
        id: apiOrganization.id as string,
        createdAt: apiOrganization.createdAt || new Date(0),
    };
};
