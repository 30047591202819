import {DeliveryWindow} from '@/models/DeliveryWindow';
import {Geolocation} from '@/models/Geolocation';
import {Status} from '@/models/Status';

export interface Supplier {
    id: string;
    code: string;
    organizationId: string;
    name: string;
    email: string;
    externalCode?: string;
    customerCode?: string;
    creditTerm?: string;
    status: Status;
    addresses: SupplierAddress[];
    deliveryWindows: DeliveryWindow[];
    userId?: string;
    createdAt: Date;
}

export interface SupplierCreate {
    code: string;
    organizationId: string;
    name: string;
    email: string;
    externalCode?: string;
    customerCode?: string;
    creditTerm?: string;
    status: Status;
    addresses: SupplierAddress[];
    userId?: string;
}

export interface SupplierUpdate {
    id: string;
    code: string;
    organizationId: string;
    name: string;
    email: string;
    externalCode?: string;
    customerCode?: string;
    creditTerm?: string;
    status: Status;
    addresses: SupplierAddress[];
    deliveryWindows: DeliveryWindow[];
    userId?: string;
}

export interface Suppliers {
    items: Supplier[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export enum SupplierAddressType {
    BUSINESS = 'business',
    BILLING = 'billing',
    UNKNOWN = 'unknown'
}

export interface SupplierAddress {
    id?: string;
    supplierId: string;
    type: SupplierAddressType;
    building: string;
    street1: string;
    street2: string;
    postalCode: string;
    region: string;
    city: string;
    countryCode: string;
    location: Geolocation;
    additionalDescription: string;
}

export function findSupplierAddress(
    addresses?: SupplierAddress[],
    addressTypes: SupplierAddressType[] = [SupplierAddressType.BUSINESS],
): SupplierAddress | undefined {
    if (!addresses) {
        return;
    }
    for (const addressType of addressTypes) {
        const address = addresses.find(address => address.type === addressType);
        if (address) {
            return address;
        }
    }
    return;
}
