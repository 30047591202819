/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddProofOfPaymentRequest
 */
export interface AddProofOfPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof AddProofOfPaymentRequest
     */
    image: string;
}

/**
 * Check if a given object implements the AddProofOfPaymentRequest interface.
 */
// @ts-ignore
export function instanceOfAddProofOfPaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "image" in value;

    return isInstance;
}

// @ts-ignore
export function AddProofOfPaymentRequestFromJSON(json: any): AddProofOfPaymentRequest {
    return AddProofOfPaymentRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function AddProofOfPaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProofOfPaymentRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': json['image'],
    };
}

// @ts-ignore
export function AddProofOfPaymentRequestToJSON(value?: AddProofOfPaymentRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
    };
}

