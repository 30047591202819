import {Notification as ApiNotification} from '@/gen/api';
import {Notification as NotificationModel} from '@/modules/notification/models/Notification';

export const toNotification = (apiNotification: ApiNotification): NotificationModel => {
    const entity = apiNotification.entity?.toLowerCase();
    return  {
        ...apiNotification,
        text: apiNotification.text || '',
        type: apiNotification.type === 'welcome' ? apiNotification.type : undefined,
        entity: entity !== undefined && entity === 'order' ? entity : undefined,
        read: !!apiNotification.readAt,
        readAt: apiNotification.readAt ? new Date(apiNotification.readAt) : undefined,
        createdAt: new Date(apiNotification.createdAt),
    };
};
