import {Amount} from '@/models/Price';
import {Status} from '@/models/Status';
import {TimeWindow} from '@/models/TimeWindow';

export interface DiscountFixedAmount {
    amount: Amount;
    quantityFrom?: number;
    quantityTo?: number;
}

export interface DiscountPercentage {
    percentage: number;
    quantityFrom?: number;
    quantityTo?: number;
}

export enum DiscountType {
    FixedAmount = 'fixed_amount',
    Percentage = 'percentage'
}

export const DiscountTypeLabels: Record<DiscountType, string> = {
    [DiscountType.FixedAmount]: 'Fixed Amount',
    [DiscountType.Percentage]: 'Percentage',
};

export const parseDiscountType = (value: string): DiscountType | undefined => {
    return Object.values(DiscountType).find(discountType => value === discountType);
};

export interface Promotion {
    id: string;
    name: string;
    effectivePeriod?: TimeWindow;
    discountType: DiscountType;
    fixedAmounts?: DiscountFixedAmount[];
    percentages?: DiscountPercentage[];
    minimumAmount?: Amount;
    minimumQuantity: number;
    supplierId: string;
    customerId?: string;
    storeId?: string;
    productCategoryId?: string;
    productListId?: string;
    skuId?: string;
    status: Status;
    createdAt: Date;
}

export interface PromotionCreate {
    name: string;
    effectivePeriod?: TimeWindow;
    discountType: DiscountType;
    fixedAmounts?: DiscountFixedAmount[];
    percentages?: DiscountPercentage[];
    minimumAmount?: Amount;
    minimumQuantity: number;
    supplierId: string;
    customerId?: string;
    storeId?: string;
    productCategoryId?: string;
    productListId?: string;
    skuId?: string;
    status: Status;
}

export interface Promotions {
    items: Promotion[];
    totalCount?: number;
    estimatedTotalCount?: number;
}
