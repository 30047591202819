import {PriceList, Status} from '@/gen/api';

export const mockPriceListsData: PriceList[] = [
    {
        id: '1',
        name: 'Standard Pricing',
        description: 'Our standard pricing for all products.',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: true,
    },
    {
        id: '2',
        name: 'Seasonal Discounts',
        description: 'Special discounts available for seasonal products.',
        supplierId: '2',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '3',
        name: 'VIP Price List',
        description: 'Exclusive prices for our VIP customers.',
        supplierId: '3',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: true,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '4',
        name: 'Limited Time Offers',
        description: 'Hurry up! Limited time offers on selected items.',
        supplierId: '4',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '5',
        name: 'Bulk Purchase Deals',
        description: 'Special deals for bulk purchases.',
        supplierId: '5',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '6',
        name: 'Clearance Sale Prices',
        description: 'Get amazing discounts on clearance sale items.',
        supplierId: '6',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-11-01T10:00:00Z'),
            end: new Date('2024-12-31T23:59:59Z'),
        },
    },
    {
        id: '7',
        name: 'Weekly Specials',
        description: 'New specials every week! Don\'t miss out.',
        supplierId: '7',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '8',
        name: 'Premium Price List',
        description: 'High-quality products at premium prices.',
        supplierId: '8',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '9',
        name: 'Holiday Season Offers',
        description: 'Special offers for the holiday season.',
        supplierId: '9',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
    {
        id: '10',
        name: 'Exclusive Membership Deals',
        description: 'Exclusive deals for our membership subscribers.',
        supplierId: '10',
        createdAt: new Date('2024-01-14T10:00:00Z'),
        status: Status.Active,
        currencyCode: 'USD',
        isDefault: false,
        effectivePeriod: {
            start: new Date('2024-01-01T10:00:00Z'),
            end: new Date('2024-07-31T10:00:00Z'),
        },
    },
];
