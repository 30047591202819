/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Dimensions } from './Dimensions';
// @ts-ignore
import {
// @ts-ignore
    DimensionsFromJSON,
// @ts-ignore
    DimensionsFromJSONTyped,
// @ts-ignore
    DimensionsToJSON,
} from './Dimensions';
// @ts-ignore
import type { Image } from './Image';
// @ts-ignore
import {
// @ts-ignore
    ImageFromJSON,
// @ts-ignore
    ImageFromJSONTyped,
// @ts-ignore
    ImageToJSON,
} from './Image';
// @ts-ignore
import type { Price } from './Price';
// @ts-ignore
import {
// @ts-ignore
    PriceFromJSON,
// @ts-ignore
    PriceFromJSONTyped,
// @ts-ignore
    PriceToJSON,
} from './Price';
// @ts-ignore
import type { Product } from './Product';
// @ts-ignore
import {
// @ts-ignore
    ProductFromJSON,
// @ts-ignore
    ProductFromJSONTyped,
// @ts-ignore
    ProductToJSON,
} from './Product';
// @ts-ignore
import type { Promotion } from './Promotion';
// @ts-ignore
import {
// @ts-ignore
    PromotionFromJSON,
// @ts-ignore
    PromotionFromJSONTyped,
// @ts-ignore
    PromotionToJSON,
} from './Promotion';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { Supplier } from './Supplier';
// @ts-ignore
import {
// @ts-ignore
    SupplierFromJSON,
// @ts-ignore
    SupplierFromJSONTyped,
// @ts-ignore
    SupplierToJSON,
} from './Supplier';
// @ts-ignore
import type { Weight } from './Weight';
// @ts-ignore
import {
// @ts-ignore
    WeightFromJSON,
// @ts-ignore
    WeightFromJSONTyped,
// @ts-ignore
    WeightToJSON,
} from './Weight';

/**
 * 
 * @export
 * @interface Sku
 */
export interface Sku {
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    description: string;
    /**
     * 
     * @type {Dimensions}
     * @memberof Sku
     */
    dimensions?: Dimensions;
    /**
     * 
     * @type {Weight}
     * @memberof Sku
     */
    weight?: Weight;
    /**
     * 
     * @type {number}
     * @memberof Sku
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof Sku
     */
    moq?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Sku
     */
    images: Array<Image>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Sku
     */
    customData?: { [key: string]: string; };
    /**
     * 
     * @type {Status}
     * @memberof Sku
     */
    status: Status;
    /**
     * 
     * @type {Product}
     * @memberof Sku
     */
    product?: Product;
    /**
     * 
     * @type {Array<Price>}
     * @memberof Sku
     */
    prices?: Array<Price>;
    /**
     * 
     * @type {Supplier}
     * @memberof Sku
     */
    supplier?: Supplier;
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof Sku
     */
    promotions?: Array<Promotion>;
    /**
     * 
     * @type {Date}
     * @memberof Sku
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Sku interface.
 */
// @ts-ignore
export function instanceOfSku(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function SkuFromJSON(json: any): Sku {
    return SkuFromJSONTyped(json, false);
}

// @ts-ignore
export function SkuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sku {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'productId': json['product_id'],
        'supplierId': json['supplier_id'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'name': json['name'],
        'description': json['description'],
        'dimensions': !exists(json, 'dimensions') ? undefined : DimensionsFromJSON(json['dimensions']),
        'weight': !exists(json, 'weight') ? undefined : WeightFromJSON(json['weight']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'images': ((json['images'] as Array<any>).map(ImageFromJSON)),
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
        'status': StatusFromJSON(json['status']),
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
        'prices': !exists(json, 'prices') ? undefined : ((json['prices'] as Array<any>).map(PriceFromJSON)),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'promotions': !exists(json, 'promotions') ? undefined : ((json['promotions'] as Array<any>).map(PromotionFromJSON)),
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function SkuToJSON(value?: Sku | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'product_id': value.productId,
        'supplier_id': value.supplierId,
        'external_code': value.externalCode,
        'name': value.name,
        'description': value.description,
        'dimensions': DimensionsToJSON(value.dimensions),
        'weight': WeightToJSON(value.weight),
        'quantity': value.quantity,
        'moq': value.moq,
        'images': ((value.images as Array<any>).map(ImageToJSON)),
        'custom_data': value.customData,
        'status': StatusToJSON(value.status),
        'product': ProductToJSON(value.product),
        'prices': value.prices === undefined ? undefined : ((value.prices as Array<any>).map(PriceToJSON)),
        'supplier': SupplierToJSON(value.supplier),
        'promotions': value.promotions === undefined ? undefined : ((value.promotions as Array<any>).map(PromotionToJSON)),
        'created_at': (value.createdAt.toISOString()),
    };
}

