import {Status, Warehouse} from '@/gen/api';

export const mockWarehousesData: Warehouse[] = [
    {
        id: '1',
        name: 'Pinnacle Distribution Center',
        description: 'Main warehouse for storing products',
        externalCode: 'XYZ789',
        contactNumber: '987-654-3210',
        status: Status.Active,
        supplierId: '1',
        address: {
            id: '1',
            warehouseId: '1',
            building: 'Warehouse A Building',
            street1: '4567 Oak Street',
            street2: '',
            postalCode: '54321',
            city: 'Citytown',
            region: 'Cityregion',
            countryCode: 'US',
            location: {
                latitude: 40.7128,
                longitude: -74.006,
            },
        },
        operatingWindows: [
            {
                id: '1',
                warehouseId: 'warehouseA',
                day: 'Monday',
                startTime: '09:00:00',
                endTime: '17:00:00',
                cutoffTime: '14:00:00',
            },
        ],
        nonWorkingDays: [
            {
                name: 'New Year',
                date: new Date('2024-01-01'),
                isPublicHoliday: true,
            },
        ],
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
];
