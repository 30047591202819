/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    email: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    sms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    orders: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    promotions: boolean;
}

/**
 * Check if a given object implements the NotificationSettings interface.
 */
// @ts-ignore
export function instanceOfNotificationSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "sms" in value;
    isInstance = isInstance && "orders" in value;
    isInstance = isInstance && "promotions" in value;

    return isInstance;
}

// @ts-ignore
export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

// @ts-ignore
export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'sms': json['sms'],
        'orders': json['orders'],
        'promotions': json['promotions'],
    };
}

// @ts-ignore
export function NotificationSettingsToJSON(value?: NotificationSettings | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'sms': value.sms,
        'orders': value.orders,
        'promotions': value.promotions,
    };
}

