import {toProduct} from '@/api/mappers/mapProduct';
import AbstractApi from '@/api/mocks/AbstractApi';
import mockProductsData from '@/api/mocks/data/products';
import {calculatePagination} from '@/api/mocks/Pagination';
import ProductsApi, {ProductFilters} from '@/api/ProductsApi';
import {Product, ProductCreate, Products, ProductUpdate} from '@/models/Product';

export default class MockProductsApiImpl extends AbstractApi implements ProductsApi {
    async getProduct(id: string): Promise<Product | undefined> {
        await this.sleep();
        const product = mockProductsData.find(product => product.id === id);
        return product && toProduct(product);
    }

    async listProducts(offset: number = 0, limit: number = 10): Promise<Products> {
        await this.sleep();
        return calculatePagination(mockProductsData.map(toProduct), offset, limit);
    }

    async findProducts(
        query?: string,
        filters: Partial<ProductFilters> = {},
        offset: number = 0,
        limit: number = 10,
    ): Promise<Products> {
        await this.sleep();

        let filteredItems = [...mockProductsData];
        if (query) {
            const queryLowerCase = query.toLowerCase();
            filteredItems = filteredItems.filter(product => {
                return product.id.toLowerCase().includes(queryLowerCase) ||
                    product.name.toLowerCase().includes(queryLowerCase);
            });
        }

        const {
            productCategoryId,
        } = filters;

        const productCategoryIds = productCategoryId
            ? (Array.isArray(productCategoryId) ? productCategoryId : [productCategoryId])
            : undefined;

        if (productCategoryIds && productCategoryIds.length > 0) {
            filteredItems = filteredItems.filter((product) =>
                productCategoryIds.includes(product.productCategoryId || ''),
            );
        }

        return calculatePagination(filteredItems.map(toProduct), offset, limit);
    }

    async createProduct(_product: ProductCreate): Promise<void> {
        await this.sleep();
    }

    async updateProduct(_id: string, _product: ProductUpdate): Promise<void> {
        await this.sleep();
    }

    async bulkUpdateProducts(_products: Product[]): Promise<void> {
        await this.sleep();
    }
}
