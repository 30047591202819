/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { Supplier } from './Supplier';
// @ts-ignore
import {
// @ts-ignore
    SupplierFromJSON,
// @ts-ignore
    SupplierFromJSONTyped,
// @ts-ignore
    SupplierToJSON,
} from './Supplier';
// @ts-ignore
import type { TimeWindow } from './TimeWindow';
// @ts-ignore
import {
// @ts-ignore
    TimeWindowFromJSON,
// @ts-ignore
    TimeWindowFromJSONTyped,
// @ts-ignore
    TimeWindowToJSON,
} from './TimeWindow';

/**
 * 
 * @export
 * @interface PriceList
 */
export interface PriceList {
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    description: string;
    /**
     * 
     * @type {Status}
     * @memberof PriceList
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    currencyCode: string;
    /**
     * 
     * @type {TimeWindow}
     * @memberof PriceList
     */
    effectivePeriod?: TimeWindow;
    /**
     * 
     * @type {boolean}
     * @memberof PriceList
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    storeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    createdAt: Date;
    /**
     * 
     * @type {Supplier}
     * @memberof PriceList
     */
    supplier?: Supplier;
}

/**
 * Check if a given object implements the PriceList interface.
 */
// @ts-ignore
export function instanceOfPriceList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "isDefault" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function PriceListFromJSON(json: any): PriceList {
    return PriceListFromJSONTyped(json, false);
}

// @ts-ignore
export function PriceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceList {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'status': StatusFromJSON(json['status']),
        'currencyCode': json['currency_code'],
        'effectivePeriod': !exists(json, 'effective_period') ? undefined : TimeWindowFromJSON(json['effective_period']),
        'isDefault': json['is_default'],
        'supplierId': json['supplier_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'createdAt': (new Date(json['created_at'])),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
    };
}

// @ts-ignore
export function PriceListToJSON(value?: PriceList | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'status': StatusToJSON(value.status),
        'currency_code': value.currencyCode,
        'effective_period': TimeWindowToJSON(value.effectivePeriod),
        'is_default': value.isDefault,
        'supplier_id': value.supplierId,
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'created_at': (value.createdAt.toISOString()),
        'supplier': SupplierToJSON(value.supplier),
    };
}

