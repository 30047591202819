/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    description: string;
    /**
     * 
     * @type {Status}
     * @memberof ProductCategory
     */
    status: Status;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductCategory
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    parentId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    left?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    right?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    supplierId: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductCategory
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ProductCategory interface.
 */
// @ts-ignore
export function instanceOfProductCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function ProductCategoryFromJSON(json: any): ProductCategory {
    return ProductCategoryFromJSONTyped(json, false);
}

// @ts-ignore
export function ProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategory {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'status': StatusFromJSON(json['status']),
        'images': json['images'],
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'left': !exists(json, 'left') ? undefined : json['left'],
        'right': !exists(json, 'right') ? undefined : json['right'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'supplierId': json['supplier_id'],
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function ProductCategoryToJSON(value?: ProductCategory | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'status': StatusToJSON(value.status),
        'images': value.images,
        'parent_id': value.parentId,
        'level': value.level,
        'left': value.left,
        'right': value.right,
        'external_code': value.externalCode,
        'supplier_id': value.supplierId,
        'created_at': (value.createdAt.toISOString()),
    };
}

