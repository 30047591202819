import {Organization, Status} from '@/gen/api';


export const mockOrganizationsData: Organization[] = [
    {
        id: '1',
        name: 'Espresso Comrades Corporation',
        status: Status.Active,
        countryCode: 'US',
        timezone: 'America/New_York',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '2',
        name: 'Arctic Bites Provisions Group',
        status: Status.Active,
        countryCode: 'TH',
        timezone: 'Asia/Bangkok',
        createdAt: new Date('2024-01-15T10:00:00Z'),
    },
    {
        id: '3',
        name: 'FancySip Paperware Innovations',
        status: Status.Active,
        countryCode: 'PH',
        timezone: 'Asia/Manila',
        createdAt: new Date('2024-01-16T10:00:00Z'),
    },
    {
        id: '4',
        name: 'FrostyDelight Culinary Ventures',
        status: Status.Active,
        countryCode: 'ID',
        timezone: 'Asia/Jakarta',
        createdAt: new Date('2024-01-17T10:00:00Z'),
    },
    {
        id: '5',
        name: 'BoxMunch International Corporation',
        status: Status.Active,
        countryCode: 'MY',
        timezone: 'Asia/Kuala_Lumpur',
        createdAt: new Date('2024-01-18T10:00:00Z'),
    },
];
