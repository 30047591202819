/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    confirmPassword: string;
}

/**
 * Check if a given object implements the ChangePasswordRequest interface.
 */
// @ts-ignore
export function instanceOfChangePasswordRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "oldPassword" in value;
    isInstance = isInstance && "newPassword" in value;
    isInstance = isInstance && "confirmPassword" in value;

    return isInstance;
}

// @ts-ignore
export function ChangePasswordRequestFromJSON(json: any): ChangePasswordRequest {
    return ChangePasswordRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function ChangePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPassword': json['old_password'],
        'newPassword': json['new_password'],
        'confirmPassword': json['confirm_password'],
    };
}

// @ts-ignore
export function ChangePasswordRequestToJSON(value?: ChangePasswordRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old_password': value.oldPassword,
        'new_password': value.newPassword,
        'confirm_password': value.confirmPassword,
    };
}

