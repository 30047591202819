import {toPriceList} from '@/api/mappers/mapPriceList';
import AbstractApi from '@/api/mocks/AbstractApi';
import {mockPriceListsData} from '@/api/mocks/data/priceLists';
import {calculatePagination} from '@/api/mocks/Pagination';
import PriceListsApi from '@/api/PriceListsApi';
import {PriceList, PriceLists} from '@/models/PriceList';

class MockPriceListsApiImpl extends AbstractApi implements PriceListsApi {

    async listPriceLists(offset: number = 0, limit: number = 10): Promise<PriceLists> {
        await this.sleep();
        return calculatePagination(mockPriceListsData.map(toPriceList), offset, limit);
    }

    async findPriceLists(query?: string, offset: number = 0, limit: number = 10): Promise<PriceLists> {
        await this.sleep();

        let items = [...mockPriceListsData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(productList => productList.name.toLowerCase().includes(queryLowerCase));
        }

        return calculatePagination(items.map(toPriceList), offset, limit);
    }

    async createPriceList(_productList: PriceList): Promise<void> {
        await this.sleep();
    }

    async getPriceList(id: string): Promise<PriceList | undefined> {
        await this.sleep();
        const productList = mockPriceListsData.find(productList => productList.id === id);
        if (productList) {
            return toPriceList(productList);
        }
        return undefined;
    }

    async updatePriceList(_id: string, _productList: PriceList): Promise<void> {
        await this.sleep();
    }

    async deletePriceList(_id: string): Promise<void> {
        await this.sleep();
    }
}

export default MockPriceListsApiImpl;
