import {apiBaseUrl} from '@/config';
import {AuthApi as ApiAuthApi, Configuration} from '@/gen/api';
import {LoginResult} from '@/modules/auth/models/Login';
import {User as SignupUser} from '@/modules/auth/models/Signup';

interface AuthApi {
    login(username: string, password: string): Promise<LoginResult | undefined>;
    refreshToken(refreshToken: string): Promise<LoginResult | undefined>;
    changePassword(id: string, oldPassword: string, newPassword: string, confirmPassword: string): Promise<void>;
    signUp(user: SignupUser): Promise<void>;
}

export class AuthApiImpl implements AuthApi {
    private authApi: ApiAuthApi;

    constructor(configuration?: Configuration) {
        this.authApi = new ApiAuthApi(
            configuration
                ? configuration
                : new Configuration({
                    basePath: apiBaseUrl,
                }),
            );
    }

    async login(username: string, password: string): Promise<LoginResult | undefined> {
        try {
            const response = await this.authApi.login({
                loginRequest: {
                    username: username,
                    password: password,
                },
            });

            return {
                accessToken: response.accessToken || '',
                idToken: response.idToken || '',
                refreshToken: response.refreshToken || '',
                expiresIn: response.expiresIn || 0,
            };
        } catch (error) {
            console.error('Login failed:', error);
            return;
        }
    }

    async refreshToken(refreshToken: string): Promise<LoginResult | undefined> {
        try {
            const response = await this.authApi.refreshToken({
                refreshTokenRequest: {
                    refreshToken,
                },
            });

            return {
                accessToken: response.accessToken || '',
                idToken: response.idToken || '',
                refreshToken: response.refreshToken || '',
                expiresIn: response.expiresIn || 0,
            };
        } catch (error) {
            console.error('Token refresh failed:', error);
            return;
        }
    }

    async changePassword(id: string, oldPassword: string, newPassword: string, confirmPassword: string): Promise<void> {
        try {
            await this.authApi.changePassword({
                id,
                changePasswordRequest: {
                    oldPassword,
                    newPassword,
                    confirmPassword,
                }});
        } catch (error) {
            console.error('Error changing password:', error);
            throw error;
        }
    }

    async signUp(user: SignupUser): Promise<void> {
        try {
            await this.authApi.signUp({
                signUpRequest: {
                    ...user,
                },
            });
        } catch (error) {
            console.error('Error while signing up user:', error);
            throw error;
        }
    }
}

export default AuthApi;
