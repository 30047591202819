import {toSku} from '@/api/mappers/mapSku';
import {toWarehouse} from '@/api/mappers/mapWarehouse';
import AbstractApi from '@/api/mocks/AbstractApi';
import {addAssociationsToSku, mockSkusData} from '@/api/mocks/data/skus';
import {mockWarehousesData} from '@/api/mocks/data/warehouses';
import {calculatePagination} from '@/api/mocks/Pagination';
import WarehousesApi from '@/api/WarehousesApi';
import {SkuAssociation, Skus} from '@/models/Sku';
import {Warehouse, Warehouses} from '@/models/Warehouse';

export default class MockWarehousesApiImpl extends AbstractApi implements WarehousesApi {
    async listWarehouses(offset: number = 0, limit: number = 10): Promise<Warehouses> {
        await this.sleep();
        return calculatePagination(mockWarehousesData.map(toWarehouse), offset, limit);
    }

    async findWarehouses(query?: string, supplierId?: string, offset: number = 0, limit: number = 10): Promise<Warehouses> {
        await this.sleep();

        let items = [...mockWarehousesData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(warehouse => warehouse.name.toLowerCase().includes(queryLowerCase));
        }

        if (supplierId) {
            items = items.filter(warehouse => warehouse.supplierId === supplierId);
        }

        return calculatePagination(items.map(toWarehouse), offset, limit);
    }

    async createWarehouse(_warehouse: Warehouse): Promise<void> {
        await this.sleep();
    }

    async getWarehouse(id: string): Promise<Warehouse | undefined> {
        await this.sleep();
        const warehouse = mockWarehousesData.find(warehouse => warehouse.id === id);
        if (warehouse) {
            return toWarehouse(warehouse);
        }
        return undefined;
    }

    async updateWarehouse(_id: string, _warehouse: Warehouse): Promise<void> {
        await this.sleep();
    }

    async deleteWarehouse(_id: string): Promise<void> {
        await this.sleep();
    }

    async addSkusToWarehouse(_id: string, _skus: string[]): Promise<void> {
        await this.sleep();
    }

    async removeSkusFromWarehouse(_id: string, _skus: string[]): Promise<void> {
        await this.sleep();
    }

    async listWarehouseInventory(
        _id: string,
        include?: SkuAssociation[],
        offset: number = 0,
        limit: number = 10,
    ): Promise<Skus> {
        await this.sleep();

        const data = include
            ? mockSkusData.map((sku) => addAssociationsToSku(sku, include))
            : mockSkusData;

        return calculatePagination(data.map(toSku), offset, limit);
    }
}
