/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';
// @ts-ignore
import type { GoodsReceivedNote } from './GoodsReceivedNote';
// @ts-ignore
import {
// @ts-ignore
    GoodsReceivedNoteFromJSON,
// @ts-ignore
    GoodsReceivedNoteFromJSONTyped,
// @ts-ignore
    GoodsReceivedNoteToJSON,
} from './GoodsReceivedNote';

/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    orderId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    lineNumber: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    skuId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    skuCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    skuName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    confirmedQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    remarks: string;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItem
     */
    unitPrice?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItem
     */
    lineItemPrice?: Amount;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    vatRate?: number;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItem
     */
    vatAmount?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItem
     */
    discountAmount?: Amount;
    /**
     * 
     * @type {GoodsReceivedNote}
     * @memberof OrderItem
     */
    goodsReceivedNote?: GoodsReceivedNote;
}

/**
 * Check if a given object implements the OrderItem interface.
 */
// @ts-ignore
export function instanceOfOrderItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "lineNumber" in value;
    isInstance = isInstance && "skuId" in value;
    isInstance = isInstance && "skuCode" in value;
    isInstance = isInstance && "skuName" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "remarks" in value;

    return isInstance;
}

// @ts-ignore
export function OrderItemFromJSON(json: any): OrderItem {
    return OrderItemFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItem {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderId': json['order_id'],
        'lineNumber': json['line_number'],
        'skuId': json['sku_id'],
        'skuCode': json['sku_code'],
        'skuName': json['sku_name'],
        'quantity': json['quantity'],
        'confirmedQuantity': !exists(json, 'confirmed_quantity') ? undefined : json['confirmed_quantity'],
        'remarks': json['remarks'],
        'unitPrice': !exists(json, 'unit_price') ? undefined : AmountFromJSON(json['unit_price']),
        'lineItemPrice': !exists(json, 'line_item_price') ? undefined : AmountFromJSON(json['line_item_price']),
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'vatAmount': !exists(json, 'vat_amount') ? undefined : AmountFromJSON(json['vat_amount']),
        'discountAmount': !exists(json, 'discount_amount') ? undefined : AmountFromJSON(json['discount_amount']),
        'goodsReceivedNote': !exists(json, 'goods_received_note') ? undefined : GoodsReceivedNoteFromJSON(json['goods_received_note']),
    };
}

// @ts-ignore
export function OrderItemToJSON(value?: OrderItem | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'order_id': value.orderId,
        'line_number': value.lineNumber,
        'sku_id': value.skuId,
        'sku_code': value.skuCode,
        'sku_name': value.skuName,
        'quantity': value.quantity,
        'confirmed_quantity': value.confirmedQuantity,
        'remarks': value.remarks,
        'unit_price': AmountToJSON(value.unitPrice),
        'line_item_price': AmountToJSON(value.lineItemPrice),
        'vat_rate': value.vatRate,
        'vat_amount': AmountToJSON(value.vatAmount),
        'discount_amount': AmountToJSON(value.discountAmount),
        'goods_received_note': GoodsReceivedNoteToJSON(value.goodsReceivedNote),
    };
}

