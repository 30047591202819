import {apiBaseUrl} from '@/config';
import {Configuration as ApiConfiguration} from '@/gen/api/runtime';

interface Headers {
    Authorization: string;
}

type AccessTokenGetter = () => { accessToken?: string };

const defaultConfiguration: Partial<ConfigurationOptions> = {
    basePath: apiBaseUrl,
};

interface ConfigurationOptions {
    basePath: string;
    accessToken: string;
    getAccessToken: AccessTokenGetter;
    headers: Partial<Headers>;
}

export class ConfigurationImpl extends ApiConfiguration {
    constructor(options?: Partial<ConfigurationOptions> | AccessTokenGetter) {
        let basePath: string;
        let accessToken: string | undefined;
        let getAccessToken: AccessTokenGetter | undefined;

        if (typeof options === 'function') {
            getAccessToken = options;
            basePath = defaultConfiguration.basePath || '';
        } else {
            basePath = options?.basePath || defaultConfiguration.basePath || '';
            accessToken = options?.accessToken;
            getAccessToken = options?.getAccessToken;
        }

        if (accessToken === undefined && getAccessToken !== undefined) {
            accessToken = getAccessToken()?.accessToken;
        }

        const headers = accessToken ? {
            Authorization: `Bearer ${accessToken}`,
        } : undefined;

        super(new ApiConfiguration({
            basePath: basePath,
            headers: headers,
        }));
    }
}

export default ConfigurationImpl;
