import {mockPricesData} from '@/api/mocks/data/prices';
import {mockProductsData} from '@/api/mocks/data/products';
import {mockPromotionsData} from '@/api/mocks/data/promotions';
import {mockSuppliersData} from '@/api/mocks/data/suppliers';
import {staticFilesBaseUrl} from '@/config';
import {Image, Sku, Status} from '@/gen/api';
import {SkuAssociation} from '@/models/Sku';

const toImage = (url: string): Image => {
    return {
        url: url,
        type: 'original',
        width: 500,
        height: 500,
        sizes: [{
            url: url,
            type: 'thumbnail',
            width: 500,
            height: 500,
        }],
    };
};

export const mockSkuImageData: Image[] = [
    toImage(staticFilesBaseUrl + '/13.jpg'),
];

export const mockSkusData: Sku[] = [
    /* Motor Parts */
    {
        id: '143654',
        code: '143654',
        productId: '143654',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'M4 GP Series Slip-On Exhaust',
        description: 'M4 GP Series Slip-On Exhaust',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/37.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143655',
        code: '143655',
        productId: '143655',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Yoshimura R-77 Slip-On Exhaust',
        description: 'Yoshimura R-77 Slip-On Exhaust',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/38.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143656',
        code: '143656',
        productId: '143656',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'M4 Standard Full System Exhaust',
        description: 'M4 Standard Full System Exhaust',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/39.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143657',
        code: '143657',
        productId: '143657',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Akrapovic Slip-On Exhaust',
        description: 'Akrapovic Slip-On Exhaust',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/40.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143658',
        code: '143658',
        productId: '143658',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Turner Performance Products Blade Brake Rotor - Front',
        description: 'Turner Performance Products Blade Brake Rotor - Front',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/41.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143659',
        code: '143659',
        productId: '143659',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Turner Performance Products Blade Brake Rotor - Rear',
        description: 'Turner Performance Products Blade Brake Rotor - Rear',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/42.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143660',
        code: '143660',
        productId: '143660',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Turner Performance Products Sport Series Motorcycle Brake Rotor - Front',
        description: 'Turner Performance Products Sport Series Motorcycle Brake Rotor - Front',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/43.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143661',
        code: '143661',
        productId: '143661',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'EBC Standard Brake Pads',
        description: 'EBC Standard Brake Pads',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/44.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143662',
        code: '143662',
        productId: '143662',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Twin Air Air Filter',
        description: 'Twin Air Air Filter',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/49.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143663',
        code: '143663',
        productId: '143663',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Twin Air Pre-Oiled Air Filter',
        description: 'Twin Air Pre-Oiled Air Filter',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/50.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143664',
        code: '143664',
        productId: '143664',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'BMC High Performance Air Filter',
        description: 'BMC High Performance Air Filter',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/51.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143665',
        code: '143665',
        productId: '143665',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'UNI High Capacity Two Stage Pod Filter',
        description: 'Zoom InUNI High Capacity Two Stage Pod Filter',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/52.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143667',
        code: '143667',
        productId: '143667',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Polisport LMX Halogen Headlight',
        description: 'Red CR 2004',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/45.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143668',
        code: '143668',
        productId: '143668',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Acerbis Universal X-LED CE Certified Tail Light',
        description: 'Acerbis Universal X-LED CE Certified Tail Light',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/46.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143669',
        code: '143669',
        productId: '143669',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Dynojet Power Commander 5 And 6 Auto Tune Kit',
        description: 'Dynojet Power Commander 5 And 6 Auto Tune Kit',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/47.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143640',
        code: '143640',
        productId: '143640',
        supplierId: '5',
        externalCode: 'ABC123',
        name: 'Koso DB-01RN Multifunction Gauge',
        description: 'Koso DB-01RN Multifunction Gauge',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/48.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    /* Fashion */
    {
        id: '113654',
        code: '113654',
        productId: '113654',
        supplierId: '2',
        externalCode: 'ABC123',
        name: 'Green Furry Shoes',
        description: 'Stylish green furry shoes',
        dimensions: {
            length: 25,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.7,
            unit: 'kg',
        },
        quantity: 50,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/13.jpg')],
        customData: {
            flavor: 'Classic',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113655',
        code: '113655',
        productId: '113655',
        supplierId: '2',
        externalCode: 'DEF456',
        name: 'Pink Furry Heels',
        description: 'Elegant pink furry heels',
        dimensions: {
            length: 30,
            width: 10,
            height: 16,
            unit: 'cm',
        },
        weight: {
            value: 0.8,
            unit: 'kg',
        },
        quantity: 60,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/14.jpg')],
        customData: {
            flavor: 'Premium',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113656',
        code: '113656',
        productId: '113656',
        supplierId: '2',
        externalCode: 'GHI789',
        name: 'Beige Furry Heels',
        description: 'Chic beige furry heels',
        dimensions: {
            length: 28,
            width: 9,
            height: 14,
            unit: 'cm',
        },
        weight: {
            value: 0.75,
            unit: 'kg',
        },
        quantity: 40,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/15.jpg')],
        customData: {
            flavor: 'Deluxe',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113657',
        code: '113657',
        productId: '113657',
        supplierId: '2',
        externalCode: 'JKL321',
        name: 'Beige Sandals',
        description: 'Comfortable beige sandals',
        dimensions: {
            length: 27,
            width: 8,
            height: 13,
            unit: 'cm',
        },
        weight: {
            value: 0.6,
            unit: 'kg',
        },
        quantity: 30,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/16.jpg')],
        customData: {
            flavor: 'Standard',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113658',
        code: '113658',
        productId: '113658',
        supplierId: '2',
        externalCode: 'MNO654',
        name: 'Beige Leather Heels',
        description: 'Classy beige leather heels',
        dimensions: {
            length: 29,
            width: 11,
            height: 17,
            unit: 'cm',
        },
        weight: {
            value: 0.9,
            unit: 'kg',
        },
        quantity: 35,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/17.jpg')],
        customData: {
            flavor: 'Premium',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113659',
        code: '113659',
        productId: '113659',
        supplierId: '2',
        externalCode: 'PQR987',
        name: 'Beige Leather Sandals',
        description: 'Sophisticated beige leather sandals',
        dimensions: {
            length: 26,
            width: 9,
            height: 16,
            unit: 'cm',
        },
        weight: {
            value: 0.8,
            unit: 'kg',
        },
        quantity: 25,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/18.jpg')],
        customData: {
            flavor: 'Deluxe',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113660',
        code: '113660',
        productId: '113660',
        supplierId: '2',
        externalCode: 'STU159',
        name: 'Light Gray Top with Long Sleeves',
        description: 'Casual light gray top with long sleeves',
        dimensions: {
            length: 50,
            width: 40,
            height: 2,
            unit: 'cm',
        },
        weight: {
            value: 0.3,
            unit: 'kg',
        },
        quantity: 70,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/19.jpg')],
        customData: {
            flavor: 'Casual',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113661',
        code: '113661',
        productId: '113661',
        supplierId: '2',
        externalCode: 'VWX357',
        name: 'Bright Red Cute Top Short Sleeves',
        description: 'Vibrant bright red cute top with short sleeves',
        dimensions: {
            length: 45,
            width: 35,
            height: 3,
            unit: 'cm',
        },
        weight: {
            value: 0.4,
            unit: 'kg',
        },
        quantity: 60,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/20.jpg')],
        customData: {
            flavor: 'Stylish',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113662',
        code: '113662',
        productId: '113662',
        supplierId: '2',
        externalCode: 'YZA753',
        name: 'Pink Furry Bag',
        description: 'Fashionable pink furry bag',
        dimensions: {
            length: 30,
            width: 20,
            height: 10,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 40,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/21.jpg')],
        customData: {
            flavor: 'Trendy',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113663',
        code: '113663',
        productId: '113663',
        supplierId: '2',
        externalCode: 'BCD951',
        name: 'Green Furry Bag',
        description: 'Trendy green furry bag',

        dimensions: {
            length: 35,
            width: 25,
            height: 12,
            unit: 'cm',
        },
        weight: {
            value: 0.6,
            unit: 'kg',
        },
        quantity: 35,
        moq: 5,
        images: [toImage(staticFilesBaseUrl + '/22.jpg')],
        customData: {
            flavor: 'Chic',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113664',
        code: '113664',
        productId: '113664',
        supplierId: '2',
        externalCode: 'EFG852',
        name: 'White Shades',
        description: 'Stylish white shades',
        dimensions: {
            length: 15,
            width: 5,
            height: 5,
            unit: 'cm',
        },
        weight: {
            value: 0.1,
            unit: 'kg',
        },
        quantity: 80,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/23.jpg')],
        customData: {
            flavor: 'Modern',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113665',
        code: '113665',
        productId: '113665',
        supplierId: '2',
        externalCode: 'HIJ456',
        name: 'Brown Shades',
        description: 'Classic brown shades',
        dimensions: {
            length: 16,
            width: 6,
            height: 6,
            unit: 'cm',
        },
        weight: {
            value: 0.2,
            unit: 'kg',
        },
        quantity: 70,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/24.jpg')],
        customData: {
            flavor: 'Vintage',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    /* Bolts and screws */
    {
        id: '133654',
        code: '133654',
        productId: '133654',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M10 x 25mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 25mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/25.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133655',
        code: '133655',
        productId: '133655',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M10 x 40mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 40mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/26.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133656',
        code: '133656',
        productId: '133656',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M12 x 50mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 50mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/27.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133657',
        code: '133657',
        productId: '133657',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 16mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 16mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/28.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133658',
        code: '133658',
        productId: '133658',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 70mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 70mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/29.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133659',
        code: '133659',
        productId: '133659',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 12mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 12mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/30.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133660',
        code: '133660',
        productId: '133660',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 10mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 10mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/31.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133661',
        code: '133661',
        productId: '133661',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 35mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 35mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/32.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133662',
        code: '133662',
        productId: '133662',
        supplierId: '3',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 20mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 20mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/33.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133663',
        code: '133663',
        productId: '133663',
        supplierId: '3',
        name: 'Plain Stainless Steel, Hex Bolt, M12 x 40mm',
        description: 'Plain Stainless Steel Hex Bolt with a length of 40mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/34.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133664',
        code: '133664',
        productId: '133664',
        supplierId: '3',
        name: 'Clear Passivated, Zinc Steel, Hex Bolt, M8 x 90mm',
        description: 'Clear Passivated Zinc Steel Hex Bolt with a length of 90mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/35.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133665',
        code: '133665',
        productId: '133665',
        supplierId: '3',
        name: 'Bright Zinc Plated Steel, Hex Bolt, M6 x 80mm',
        description: 'Bright Zinc Plated Steel Hex Bolt with a length of 80mm',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/36.jpg')],
        status: Status.Active,
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    /* Food and beverages */
    {
        id: '123654',
        code: '123654',
        productId: '123654',
        supplierId: '1',
        externalCode: '123654',
        name: 'Coca-Cola Soda Soft Drink Coke, 20 fl oz',
        description: 'Refreshing Coca-Cola Soda',
        dimensions: {
            length: 10,
            width: 5,
            height: 20,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 100,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/1.jpg')],
        customData: {
            flavor: 'Classic',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123655',
        code: '123655',
        productId: '123655',
        supplierId: '1',
        externalCode: '123655',
        name: '7UP Soda, 2 L Bottle',
        description: 'Crisp and Clear 7UP Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 80,
        moq: 8,
        images: [toImage(staticFilesBaseUrl + '/2.jpg')],
        customData: {
            flavor: 'Regular',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123656',
        code: '123656',
        productId: '123656',
        supplierId: '1',
        externalCode: '123656',
        name: '7UP Cherry Flavored Soda, 2 L Bottle',
        description: 'Sweet and Tangy Cherry Flavored 7UP Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 75,
        moq: 7,
        images: [toImage(staticFilesBaseUrl + '/3.jpg')],
        customData: {
            flavor: 'Cherry',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123657',
        code: '123657',
        productId: '123657',
        supplierId: '1',
        externalCode: '123657',
        name: 'Dr Pepper Original Soda, 2 L Bottle',
        description: 'Bold and Unique Dr Pepper Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 90,
        moq: 9,
        images: [toImage(staticFilesBaseUrl + '/4.jpg')],
        customData: {
            flavor: 'Original',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123658',
        code: '123658',
        productId: '123658',
        supplierId: '1',
        externalCode: '123658',
        name: 'Diet Dr Pepper Soda, 2 L Bottle',
        description: 'Refreshing Diet Dr Pepper Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 85,
        moq: 8,
        images: [toImage(staticFilesBaseUrl + '/5.jpg')],
        customData: {
            flavor: 'Diet',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123663',
        code: '123663',
        productId: '123663',
        supplierId: '1',
        externalCode: '123663',
        name: 'Fanta Strawberry Soda, 2 L Bottle',
        description: 'Sweet and Fruity Strawberry Flavored Fanta Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 100,
        moq: 1,
        images: [toImage(staticFilesBaseUrl + '/6.jpg')],
        customData: {
            flavor: 'Strawberry',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123664',
        code: '123664',
        productId: '123664',
        supplierId: '1',
        externalCode: '123664',
        name: 'Fanta Pineapple Soda, 2 L Bottle',
        description: 'Tropical and Refreshing Pineapple Flavored Fanta Soda',
        dimensions: {
            length: 12,
            width: 6,
            height: 30,
            unit: 'cm',
        },
        weight: {
            value: 1.0,
            unit: 'kg',
        },
        quantity: 180,
        moq: 1,
        images: [toImage(staticFilesBaseUrl + '/7.jpg')],
        customData: {
            flavor: 'Pineapple',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123665',
        code: '123665',
        productId: '123665',
        supplierId: '4',
        externalCode: '123665',
        name: 'Ben & Jerry\'s Chocolate Chip Cookie Dough Ice Cream 16 oz',
        description: 'Delicious Chocolate Chip Cookie Dough flavored ice cream by Ben & Jerry\'s',
        dimensions: {
            length: 12,
            width: 10,
            height: 15,
            unit: 'cm',
        },
        weight: {
            value: 0.8,
            unit: 'kg',
        },
        quantity: 120,
        moq: 10,
        images: [toImage(staticFilesBaseUrl + '/8.jpg')],
        customData: {
            flavor: 'Chocolate Chip Cookie Dough',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123659',
        code: '123659',
        productId: '123659',
        supplierId: '1',
        externalCode: '123659',
        name: 'Lay\'s Classic Potato Chips, 8 oz',
        description: 'Crunchy and Classic Potato Chips',
        dimensions: {
            length: 15,
            width: 10,
            height: 5,
            unit: 'cm',
        },
        weight: {
            value: 0.2,
            unit: 'kg',
        },
        quantity: 120,
        moq: 12,
        images: [toImage(staticFilesBaseUrl + '/9.jpg')],
        customData: {
            flavor: 'Classic',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123660',
        code: '123660',
        productId: '123660',
        supplierId: '1',
        externalCode: '123660',
        name: 'Cheetos Crunchy Cheese Flavored Chips Party Size, 15 Oz',
        description: 'Irresistible Cheese Flavored Cheetos',
        dimensions: {
            length: 20,
            width: 15,
            height: 5,
            unit: 'cm',
        },
        weight: {
            value: 0.5,
            unit: 'kg',
        },
        quantity: 90,
        moq: 9,
        images: [toImage(staticFilesBaseUrl + '/10.jpg')],
        customData: {
            flavor: 'Cheese',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123661',
        code: '123661',
        productId: '123661',
        supplierId: '1',
        externalCode: '123661',
        name: 'Marketside Chocolate Chip Muffins, 14 oz, 4 Count',
        description: 'Delicious Chocolate Chip Muffins',
        dimensions: {
            length: 18,
            width: 15,
            height: 10,
            unit: 'cm',
        },
        weight: {
            value: 0.8,
            unit: 'kg',
        },
        quantity: 60,
        moq: 6,
        images: [toImage(staticFilesBaseUrl + '/11.jpg')],
        customData: {
            flavor: 'Chocolate Chip',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123662',
        code: '123662',
        productId: '123662',
        supplierId: '1',
        externalCode: '123662',
        name: 'Marketside Vanilla Brioche, 16.8 oz',
        description: 'Soft and Fluffy Vanilla Brioche',
        dimensions: {
            length: 20,
            width: 15,
            height: 8,
            unit: 'cm',
        },
        weight: {
            value: 1.2,
            unit: 'kg',
        },
        quantity: 70,
        moq: 7,
        images: [toImage(staticFilesBaseUrl + '/12.jpg')],
        customData: {
            flavor: 'Vanilla',
        },
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
];

export const addAssociationsToSku = (sku: Sku, include: SkuAssociation[]): Sku => {
    const all = include.includes(SkuAssociation.ALL);

    if (all || include.includes(SkuAssociation.PRODUCT)) {
        const product = mockProductsData.find((product) => product.id === sku.productId);
        if (product) {
            sku.product = product;
        }
    }

    if (all || include.includes(SkuAssociation.PRICES)) {
        const prices = mockPricesData.filter((price) => price.skuId === sku.id);
        if (prices.length > 0) {
            sku.prices = prices;
        }
    }

    if (all || include.includes(SkuAssociation.PROMOTIONS)) {
        const promotions = mockPromotionsData.filter((promotion) => promotion.skuId === sku.id);
        if (promotions.length > 0) {
            sku.promotions = promotions;
        }
    }

    if (all || include.includes(SkuAssociation.SUPPLIER)) {
        const supplier = mockSuppliersData.find((supplier) => supplier.id === sku.supplierId);
        if (supplier) {
            sku.supplier = supplier;
        }
    }

    return sku;
};
