/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreSku
 */
export interface StoreSku {
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreSku
     */
    effectiveDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    skuId?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreSku
     */
    stockQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreSku
     */
    supplierId?: string;
}

/**
 * Check if a given object implements the StoreSku interface.
 */
// @ts-ignore
export function instanceOfStoreSku(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function StoreSkuFromJSON(json: any): StoreSku {
    return StoreSkuFromJSONTyped(json, false);
}

// @ts-ignore
export function StoreSkuFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreSku {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'effectiveDate': !exists(json, 'effective_date') ? undefined : (new Date(json['effective_date'])),
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'skuId': !exists(json, 'sku_id') ? undefined : json['sku_id'],
        'stockQuantity': !exists(json, 'stock_quantity') ? undefined : json['stock_quantity'],
        'supplierId': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
    };
}

// @ts-ignore
export function StoreSkuToJSON(value?: StoreSku | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'effective_date': value.effectiveDate === undefined ? undefined : (value.effectiveDate.toISOString()),
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'product_id': value.productId,
        'sku_id': value.skuId,
        'stock_quantity': value.stockQuantity,
        'supplier_id': value.supplierId,
    };
}

