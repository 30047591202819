import {Order} from '@/models/Order';

export enum OrderStatus {
    New = 'NEW',
    Accepted = 'ACCEPTED',
    Dispatched = 'DISPATCHED',
    Rejected = 'REJECTED',
    Completed = 'COMPLETED',
    Canceled = 'CANCELED',
    Submitted = 'SUBMITTED',
    Received = 'RECEIVED',
    Unknown = 'UNKNOWN',
}

export const SupplierOrderStatusTitles: Record<OrderStatus, string> = {
    [OrderStatus.New]: 'Supervisor to Review',
    [OrderStatus.Canceled]: 'Canceled',
    [OrderStatus.Submitted]: 'Order Received',
    [OrderStatus.Accepted]: 'Order Accepted',
    [OrderStatus.Dispatched]: 'Order Dispatched',
    [OrderStatus.Completed]: 'Completed',
    [OrderStatus.Rejected]: 'Order Rejected',
    [OrderStatus.Received]: 'Order Received by Store',
    [OrderStatus.Unknown]: 'Unknown',
};

export const CustomerOrderStatusTitles: Record<OrderStatus, string> = {
    [OrderStatus.New]: 'Supervisor to Review',
    [OrderStatus.Accepted]: 'Accepted by Supplier',
    [OrderStatus.Dispatched]: 'Dispatched by Supplier',
    [OrderStatus.Rejected]: 'Rejected by Supplier',
    [OrderStatus.Completed]: 'Completed',
    [OrderStatus.Canceled]: 'Order Canceled',
    [OrderStatus.Submitted]: 'Submitted to Supplier',
    [OrderStatus.Received]: 'Order Received',
    [OrderStatus.Unknown]: 'Unknown',
};

export const getSupplierOrderStatusTitle = (order: Order): string => {
    if (order.status === OrderStatus.Accepted && order.editedBySupplier) {
        return 'Edited & Accepted by Supplier';
    }
    return SupplierOrderStatusTitles[order.status] || 'Unknown Status';
};

export const getCustomerOrderStatusTitle = (order: Order): string => {
    if (order.status === OrderStatus.Accepted && order.editedBySupplier) {
        return 'Edited & Accepted by Supplier';
    }
    return CustomerOrderStatusTitles[order.status] || 'Unknown Status';
};

export const isSuccessOrderStatus = (status: OrderStatus): boolean => {
    return (
        status === OrderStatus.Accepted ||
        status === OrderStatus.Completed ||
        status === OrderStatus.Received
    );
};
