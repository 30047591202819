export const countryCodes: string[] = import.meta.env.VITE_COUNTRY_CODES.split(',');
export const currencyCodes: string[] = import.meta.env.VITE_CURRENCY_CODES.split(',');
export const staticFilesBaseUrl: string = import.meta.env.VITE_STATIC_FILES_BASE_URL;
export const appVersion: string = APP_VERSION;

interface Language {
    name: string;
    code: string;
    flag: string;
}

export const languages: Language[] = [
    {name: 'English', code: 'en', flag: '/flags/US.svg'},
    {name: 'Thai', code: 'th', flag: '/flags/TH.svg'},
    {name: 'Indonesian', code: 'id', flag: '/flags/ID.svg'},
    {name: 'Filipino', code: 'ph', flag: '/flags/PH.svg'},
];

export const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
