import AbstractApi from '@/api/mocks/AbstractApi';
import {mockProductListsData} from '@/api/mocks/data/productLists';
import {calculatePagination} from '@/api/mocks/Pagination';
import ProductListsApi from '@/api/ProductListsApi';
import {ProductList, ProductLists} from '@/models/ProductList';

class MockProductListsApi extends AbstractApi implements ProductListsApi {

    async listProductLists(offset: number = 0, limit: number = 10): Promise<ProductLists> {
        await this.sleep();
        return calculatePagination(mockProductListsData, offset, limit);
    }

    async findProductLists(query?: string, offset: number = 0, limit: number = 10): Promise<ProductLists> {
        await this.sleep();

        let items = [...mockProductListsData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(productList => productList.name.toLowerCase().includes(queryLowerCase));
        }

        return calculatePagination(items, offset, limit);
    }

    createProductList(_productList: ProductList): void {
    }

    async getProductList(id: string): Promise<ProductList | undefined> {
        await this.sleep();
        const productList = mockProductListsData.find(productList => productList.id === id);
        if (productList) {
            return productList;
        }
        return undefined;
    }

    async updateProductList(_id: string, _productList: ProductList): Promise<void> {
        await this.sleep();
    }

    async deleteProductList(_id: string): Promise<void> {
        await this.sleep();
    }
}

export default MockProductListsApi;
