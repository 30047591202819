import {ProductList} from '@/gen/api';

export const mockProductListsData: ProductList[] = [
    {
        id: '1',
        name: 'Fashion Promotion',
        description: 'Promotion for Fashion Products',
        supplierId: '2',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '2',
        name: 'Bolts and Screws Category',
        description: 'Product List for Bolts and Screws',
        supplierId: '3',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '3',
        name: 'Food and Beverages Sale',
        description: 'Special Offers on Food and Beverages',
        supplierId: '1',
        createdAt: new Date('2024-01-15T10:00:00Z'),
    },
    {
        id: '4',
        name: 'Electronics Clearance',
        description: 'Clearance Sale for Electronics',
        supplierId: '3',
        createdAt: new Date('2024-01-15T10:00:00Z'),
    },
    {
        id: '5',
        name: 'Summer Fashion Collection',
        description: 'New Collection for Summer Fashion',
        supplierId: '2',
        createdAt: new Date('2024-01-16T10:00:00Z'),
    },
    {
        id: '6',
        name: 'Automotive Parts Sale',
        description: 'Sale on Automotive Parts and Accessories',
        supplierId: '3',
        createdAt: new Date('2024-01-16T10:00:00Z'),
    },
    {
        id: '7',
        name: 'Home Decor Discounts',
        description: 'Discounts on Home Decor Products',
        supplierId: '4',
        createdAt: new Date('2024-01-17T10:00:00Z'),
    },
    {
        id: '8',
        name: 'Health and Wellness Sale',
        description: 'Special Offers on Health and Wellness Products',
        supplierId: '5',
        createdAt: new Date('2024-01-17T10:00:00Z'),
    },
    {
        id: '9',
        name: 'Outdoor Adventure Gear',
        description: 'Gear for Outdoor Adventures',
        supplierId: '6',
        createdAt: new Date('2024-01-18T10:00:00Z'),
    },
    {
        id: '10',
        name: 'Tech Gadgets Clearance',
        description: 'Clearance Sale for Tech Gadgets',
        supplierId: '7',
        createdAt: new Date('2024-01-18T10:00:00Z'),
    },
];

export const mockProductListSkusData = [
    {
        skuId: '123654',
        productListId: '3',
    },
    {
        skuId: '123655',
        productListId: '3',
    },
    {
        skuId: '123656',
        productListId: '3',
    },
    {
        skuId: '123657',
        productListId: '3',
    },
    {
        skuId: '123658',
        productListId: '3',
    },
    {
        skuId: '123663',
        productListId: '3',
    },
    {
        skuId: '123664',
        productListId: '3',
    },
    {
        skuId: '123665',
        productListId: '3',
    },
    {
        skuId: '123659',
        productListId: '3',
    },
    {
        skuId: '123660',
        productListId: '3',
    },
    {
        skuId: '123661',
        productListId: '3',
    },
    {
        skuId: '123662',
        productListId: '3',
    },
    {
        skuId: '113654',
        productListId: '1',
    },
    {
        skuId: '113655',
        productListId: '1',
    },
    {
        skuId: '113656',
        productListId: '1',
    },
    {
        skuId: '113657',
        productListId: '1',
    },
    {
        skuId: '113658',
        productListId: '1',
    },
    {
        skuId: '113659',
        productListId: '1',
    },
    {
        skuId: '113660',
        productListId: '1',
    },
    {
        skuId: '113661',
        productListId: '1',
    },
    {
        skuId: '113662',
        productListId: '1',
    },
    {
        skuId: '113663',
        productListId: '1',
    },
    {
        skuId: '113664',
        productListId: '1',
    },
    {
        skuId: '113665',
        productListId: '1',
    },
    {
        skuId: '133654',
        productListId: '2',
    },
    {
        skuId: '133655',
        productListId: '2',
    },
    {
        skuId: '133656',
        productListId: '2',
    },
    {
        skuId: '133657',
        productListId: '2',
    },
    {
        skuId: '133658',
        productListId: '2',
    },
    {
        skuId: '133659',
        productListId: '2',
    },
    {
        skuId: '133660',
        productListId: '2',
    },
    {
        skuId: '133661',
        productListId: '2',
    },
    {
        skuId: '133662',
        productListId: '2',
    },
    {
        skuId: '133663',
        productListId: '2',
    },
    {
        skuId: '133664',
        productListId: '2',
    },
    {
        skuId: '133665',
        productListId: '2',
    },
];
