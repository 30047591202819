import {parse} from 'date-fns/parse';

export interface Time {
    hours: number;
    hours24: number;
    minutes: number;
    seconds: number;
    period: 'AM' | 'PM';
}

export const formatTime = (time: Time): string => {
    const {hours, minutes, seconds} = time;

    const hourStr = hours.toString().padStart(2, '0');
    const minuteStr = minutes.toString().padStart(2, '0');
    const secondStr = seconds.toString().padStart(2, '0');

    return `${hourStr}:${minuteStr}:${secondStr}`;
};

export class TimeImpl implements Time {
    hours: number;
    hours24: number;
    minutes: number;
    seconds: number;
    period: 'AM' | 'PM';

    constructor(hours: number, minutes: number, seconds: number = 0, period: 'AM' | 'PM' = 'AM') {
        this.hours = hours;
        this.hours24 = period === 'PM' ? hours + 12 : hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.period = period;
    }

    static fromString(timeString: string): TimeImpl | undefined {
        const parsedTime = parse(timeString, 'HH:mm:ss', new Date());
        if (isNaN(parsedTime.getHours())) {
            return undefined;
        }

        let hours = parsedTime.getHours();
        let period: 'AM' | 'PM' = 'AM';
        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                hours -= 12;
            }
        } else if (hours === 0) {
            hours = 12;
        }

        return new TimeImpl(hours, parsedTime.getMinutes(), parsedTime.getSeconds(), period);
    }

    static from24Hour(hours24: number, minutes: number, seconds: number = 0): TimeImpl | undefined {
        if (hours24 < 0 || hours24 > 23 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
            return undefined;
        }

        let hours: number;
        let period: 'AM' | 'PM';

        if (hours24 === 0) {
            hours = 12;
            period = 'AM';
        } else if (hours24 === 12) {
            hours = 12;
            period = 'PM';
        } else if (hours24 < 12) {
            hours = hours24;
            period = 'AM';
        } else {
            hours = hours24 - 12;
            period = 'PM';
        }

        return new TimeImpl(hours, minutes, seconds, period);
    }
}
