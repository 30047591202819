/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoodsReceivedNote
 */
export interface GoodsReceivedNote {
    /**
     * 
     * @type {string}
     * @memberof GoodsReceivedNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GoodsReceivedNote
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof GoodsReceivedNote
     */
    orderItemId: string;
    /**
     * 
     * @type {string}
     * @memberof GoodsReceivedNote
     */
    issue: string;
    /**
     * 
     * @type {string}
     * @memberof GoodsReceivedNote
     */
    comment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoodsReceivedNote
     */
    images: Array<string>;
}

/**
 * Check if a given object implements the GoodsReceivedNote interface.
 */
// @ts-ignore
export function instanceOfGoodsReceivedNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "orderItemId" in value;
    isInstance = isInstance && "issue" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

// @ts-ignore
export function GoodsReceivedNoteFromJSON(json: any): GoodsReceivedNote {
    return GoodsReceivedNoteFromJSONTyped(json, false);
}

// @ts-ignore
export function GoodsReceivedNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoodsReceivedNote {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderId': json['order_id'],
        'orderItemId': json['order_item_id'],
        'issue': json['issue'],
        'comment': json['comment'],
        'images': json['images'],
    };
}

// @ts-ignore
export function GoodsReceivedNoteToJSON(value?: GoodsReceivedNote | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'order_id': value.orderId,
        'order_item_id': value.orderItemId,
        'issue': value.issue,
        'comment': value.comment,
        'images': value.images,
    };
}

