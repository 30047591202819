import {staticFilesBaseUrl} from '@/config';
import {Image, Product, Status} from '@/gen/api';

const toImage = (url: string): Image => {
    return {
        url: url,
        type: 'original',
        width: 500,
        height: 500,
        sizes: [{
            url: url,
            type: 'thumbnail',
            width: 500,
            height: 500,
        }],
    };
};

export const mockProductsData: Product[] = [
    /* Motorcycle Parts */
    {
        id: '143654',
        name: 'M4 GP Series Slip-On Exhaust',
        externalCode: 'ABC123',
        description: 'M4 GP Series Slip-On Exhaust',
        status: Status.Active,
        gtin: '1434567890170',
        images: [toImage(staticFilesBaseUrl + '/37.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-exhaust',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143655',
        name: 'Yoshimura R-77 Slip-On Exhaust',
        externalCode: 'ABC123',
        description: 'Yoshimura R-77 Slip-On Exhaust',
        status: Status.Active,
        gtin: '1434567890171',
        images: [toImage(staticFilesBaseUrl + '/38.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-exhaust',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143656',
        name: 'M4 Standard Full System Exhaust',
        externalCode: 'ABC123',
        description: 'M4 Standard Full System Exhaust',
        status: Status.Active,
        gtin: '1434567890172',
        images: [toImage(staticFilesBaseUrl + '/39.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-exhaust',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143657',
        name: 'Akrapovic Slip-On Exhaust',
        externalCode: 'ABC123',
        description: 'Akrapovic Slip-On Exhaust',
        status: Status.Active,
        gtin: '1434567890173',
        images: [toImage(staticFilesBaseUrl + '/40.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-exhaust',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143658',
        name: 'Turner Performance Products Blade Brake Rotor - Front',
        externalCode: 'ABC123',
        description: 'Turner Performance Products Blade Brake Rotor - Front',
        status: Status.Active,
        gtin: '1434567890174',
        images: [toImage(staticFilesBaseUrl + '/41.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-brakes',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143659',
        name: 'Turner Performance Products Blade Brake Rotor - Rear',
        externalCode: 'ABC123',
        description: 'Turner Performance Products Blade Brake Rotor - Rear',
        status: Status.Active,
        gtin: '1434567890175',
        images: [toImage(staticFilesBaseUrl + '/42.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-brakes',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143660',
        name: 'Turner Performance Products Sport Series Motorcycle Brake Rotor - Front',
        externalCode: 'ABC123',
        description: 'Turner Performance Products Sport Series Motorcycle Brake Rotor - Front',
        status: Status.Active,
        gtin: '1434567890176',
        images: [toImage(staticFilesBaseUrl + '/43.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-brakes',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143661',
        name: 'EBC Standard Brake Pads',
        externalCode: 'ABC123',
        description: 'EBC Standard Brake Pads',
        status: Status.Active,
        gtin: '1434567890176',
        images: [toImage(staticFilesBaseUrl + '/44.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-brakes',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143662',
        name: 'Twin Air Air Filter',
        externalCode: 'ABC123',
        description: 'Twin Air Air Filter',
        status: Status.Active,
        gtin: '1434567890177',
        images: [toImage(staticFilesBaseUrl + '/49.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-intake',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143663',
        name: 'Twin Air Pre-Oiled Air Filter',
        externalCode: 'ABC123',
        description: 'Twin Air Pre-Oiled Air Filter',
        status: Status.Active,
        gtin: '1434567890178',
        images: [toImage(staticFilesBaseUrl + '/50.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-intake',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143664',
        name: 'BMC High Performance Air Filter',
        externalCode: 'ABC123',
        description: 'BMC High Performance Air Filter',
        status: Status.Active,
        gtin: '1434567890179',
        images: [toImage(staticFilesBaseUrl + '/51.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-intake',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143665',
        name: 'UNI High Capacity Two Stage Pod Filter',
        externalCode: 'ABC123',
        description: 'Zoom InUNI High Capacity Two Stage Pod Filter',
        status: Status.Active,
        gtin: '1434567890180',
        images: [toImage(staticFilesBaseUrl + '/52.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-intake',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143667',
        name: 'Polisport LMX Halogen Headlight',
        externalCode: 'ABC123',
        description: 'Red CR 2004',
        status: Status.Active,
        gtin: '1434567890181',
        images: [toImage(staticFilesBaseUrl + '/45.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-lights-and-electrical',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143668',
        name: 'Acerbis Universal X-LED CE Certified Tail Light',
        externalCode: 'ABC123',
        description: 'Acerbis Universal X-LED CE Certified Tail Light',
        status: Status.Active,
        gtin: '1434567890182',
        images: [toImage(staticFilesBaseUrl + '/46.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-lights-and-electrical',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143669',
        name: 'Dynojet Power Commander 5 And 6 Auto Tune Kit',
        externalCode: 'ABC123',
        description: 'Dynojet Power Commander 5 And 6 Auto Tune Kit',
        status: Status.Active,
        gtin: '1434567890183',
        images: [toImage(staticFilesBaseUrl + '/47.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-lights-and-electrical',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '143640',
        name: 'Koso DB-01RN Multifunction Gauge',
        externalCode: 'ABC123',
        description: 'Koso DB-01RN Multifunction Gauge',
        status: Status.Active,
        gtin: '1434567890184',
        images: [toImage(staticFilesBaseUrl + '/48.jpg')],
        vatRate: 0,
        productCategoryId: 'motorcycle-lights-and-electrical',
        supplierId: '5',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    /* Food and beverages */
    {
        id: '123654',
        name: 'Coca-Cola Soda Soft Drink Coke, 20 fl oz',
        externalCode: '123654',
        description: 'Refreshing Coca-Cola Soda',
        status: Status.Active,
        gtin: '1234567890123',
        images: [toImage(staticFilesBaseUrl + '/1.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123655',
        name: '7UP Soda, 2 L Bottle',
        externalCode: '123655',
        description: 'Crisp and Clear 7UP Soda',
        status: Status.Active,
        gtin: '1234567890456',
        images: [toImage(staticFilesBaseUrl + '/2.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123656',
        name: '7UP Cherry Flavored Soda, 2 L Bottle',
        externalCode: '123656',
        description: 'Sweet and Tangy Cherry Flavored 7UP Soda',
        status: Status.Active,
        gtin: '1234567890789',
        images: [toImage(staticFilesBaseUrl + '/3.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123657',
        name: 'Dr Pepper Original Soda, 2 L Bottle',
        externalCode: '123657',
        description: 'Bold and Unique Dr Pepper Soda',
        status: Status.Active,
        gtin: '1234567890129',
        images: [toImage(staticFilesBaseUrl + '/4.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {

        id: '123658',
        name: 'Diet Dr Pepper Soda, 2 L Bottle',
        externalCode: '123658',
        description: 'Refreshing Diet Dr Pepper Soda',
        status: Status.Active,
        gtin: '1234567890134',
        images: [toImage(staticFilesBaseUrl + '/5.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123663',
        name: 'Fanta Strawberry Soda, 2 L Bottle',
        externalCode: '123663',
        description: 'Sweet and Fruity Strawberry Flavored Fanta Soda',
        status: Status.Active,
        gtin: '1234567890165',
        images: [toImage(staticFilesBaseUrl + '/6.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123664',
        name: 'Fanta Pineapple Soda, 2 L Bottle',
        externalCode: '123664',
        description: 'Tropical and Refreshing Pineapple Flavored Fanta Soda',
        status: Status.Active,
        gtin: '1234567890170',
        images: [toImage(staticFilesBaseUrl + '/7.jpg')],
        vatRate: 0,
        productCategoryId: 'beverage-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123665',
        name: 'Ben & Jerry\'s Chocolate Chip Cookie Dough Ice Cream 16 oz',
        externalCode: '123665',
        description: 'Delicious Chocolate Chip Cookie Dough flavored ice cream by Ben & Jerry\'s',
        status: Status.Active,
        gtin: '1234567890175',
        images: [toImage(staticFilesBaseUrl + '/8.jpg')],
        vatRate: 0,
        productCategoryId: 'ice-cream-category',
        supplierId: '4',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123659',
        name: 'Lay\'s Classic Potato Chips, 8 oz',
        externalCode: '123659',
        description: 'Crunchy and Classic Potato Chips',
        status: Status.Active,
        gtin: '1234567890145',
        images: [toImage(staticFilesBaseUrl + '/9.jpg')],
        vatRate: 0,
        productCategoryId: 'snack-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123660',
        name: 'Cheetos Crunchy Cheese Flavored Chips Party Size, 15 Oz',
        externalCode: '123660',
        description: 'Irresistible Cheese Flavored Cheetos',
        status: Status.Active,
        gtin: '1234567890150',
        images: [toImage(staticFilesBaseUrl + '/10.jpg')],
        vatRate: 0,
        productCategoryId: 'snack-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123661',
        name: 'Marketside Chocolate Chip Muffins, 14 oz, 4 Count',
        externalCode: '123661',
        description: 'Delicious Chocolate Chip Muffins',
        status: Status.Active,
        gtin: '1234567890155',
        images: [toImage(staticFilesBaseUrl + '/11.jpg')],
        vatRate: 0,
        productCategoryId: 'bakery-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '123662',
        name: 'Marketside Vanilla Brioche, 16.8 oz',
        externalCode: '123662',
        description: 'Soft and Fluffy Vanilla Brioche',
        status: Status.Active,
        gtin: '1234567890160',
        images: [toImage(staticFilesBaseUrl + '/12.jpg')],
        vatRate: 0,
        productCategoryId: 'bakery-category',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    /* Fashion */
    {
        id: '113654',
        name: 'Green Furry Shoes',
        externalCode: '123654',
        description: 'Stylish green furry shoes',
        status: Status.Active,
        gtin: '1234567890123',
        images: [toImage(staticFilesBaseUrl + '/13.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113655',
        name: 'Pink Furry Heels',
        externalCode: '123655',
        description: 'Elegant pink furry heels',
        status: Status.Active,
        gtin: '1234567890124',
        images: [toImage(staticFilesBaseUrl + '/14.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113656',
        name: 'Beige Furry Heels',
        externalCode: '123656',
        description: 'Chic beige furry heels',
        status: Status.Active,
        gtin: '1234567890125',
        images: [toImage(staticFilesBaseUrl + '/15.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113657',
        name: 'Beige Sandals',
        externalCode: '123657',
        description: 'Comfortable beige sandals',
        status: Status.Active,
        gtin: '1234567890126',
        images: [toImage(staticFilesBaseUrl + '/16.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113658',
        name: 'Beige Leather Heels',
        externalCode: '123658',
        description: 'Classy beige leather heels',
        status: Status.Active,
        gtin: '1234567890127',
        images: [toImage(staticFilesBaseUrl + '/17.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113659',
        name: 'Beige Leather Sandals',
        externalCode: '123659',
        description: 'Sophisticated beige leather sandals',
        status: Status.Active,
        gtin: '1234567890128',
        images: [toImage(staticFilesBaseUrl + '/18.jpg')],
        vatRate: 0,
        productCategoryId: 'shoes-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113660',
        name: 'Light Gray Top with Long Sleeves',
        externalCode: '123660',
        description: 'Casual light gray top with long sleeves',
        status: Status.Active,
        gtin: '1234567890129',
        images: [toImage(staticFilesBaseUrl + '/19.jpg')],
        vatRate: 0,
        productCategoryId: 'clothing-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113661',
        name: 'Bright Red Cute Top Short Sleeves',
        externalCode: '123661',
        description: 'Vibrant bright red cute top with short sleeves',
        status: Status.Active,
        gtin: '1234567890130',
        images: [toImage(staticFilesBaseUrl + '/20.jpg')],
        vatRate: 0,
        productCategoryId: 'clothing-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113662',
        name: 'Pink Furry Bag',
        externalCode: '123662',
        description: 'Fashionable pink furry bag',
        status: Status.Active,
        gtin: '1234567890131',
        images: [toImage(staticFilesBaseUrl + '/21.jpg')],
        vatRate: 0,
        productCategoryId: 'bags-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113663',
        name: 'Green Furry Bag',
        externalCode: '123663',
        description: 'Trendy green furry bag',
        status: Status.Active,
        gtin: '1234567890132',
        images: [toImage(staticFilesBaseUrl + '/22.jpg')],
        vatRate: 0,
        productCategoryId: 'bags-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113664',
        name: 'White Shades',
        externalCode: '123664',
        description: 'Stylish white shades',
        status: Status.Active,
        gtin: '1234567890133',
        images: [toImage(staticFilesBaseUrl + '/23.jpg')],
        vatRate: 0,
        productCategoryId: 'sunglasses-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '113665',
        name: 'Brown Shades',
        externalCode: '123665',
        description: 'Classic brown shades',
        status: Status.Active,
        gtin: '1234567890134',
        images: [toImage(staticFilesBaseUrl + '/24.jpg')],
        vatRate: 0,
        productCategoryId: 'sunglasses-category',
        supplierId: '2',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    /* Bolts and screws */
    {
        id: '133654',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M10 x 25mm',
        externalCode: '520-273',
        description: 'Plain stainless steel hex bolt, M10 x 25mm, 1 bag of 25.',
        status: Status.Active,
        gtin: '520273',
        images: [toImage(staticFilesBaseUrl + '/25.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133655',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M10 x 40mm',
        externalCode: '520-295',
        description: 'Plain stainless steel hex bolt, M10 x 40mm, 1 bag of 25.',
        status: Status.Active,
        gtin: '520295',
        images: [toImage(staticFilesBaseUrl + '/26.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133656',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M12 x 50mm',
        externalCode: '520-352',
        description: 'Plain stainless steel hex bolt, M12 x 50mm, 1 bag of 25.',
        status: Status.Active,
        gtin: '520352',
        images: [toImage(staticFilesBaseUrl + '/27.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133657',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 16mm',
        externalCode: '263-2132',
        description: 'Plain stainless steel hex bolt, M4 x 16mm, 1 bag of 100.',
        status: Status.Active,
        gtin: '2632132',
        images: [toImage(staticFilesBaseUrl + '/28.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133658',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 70mm',
        externalCode: '289-023',
        description: 'Plain stainless steel hex bolt, M6 x 70mm, 1 bag of 50.',
        status: Status.Active,
        gtin: '289023',
        images: [toImage(staticFilesBaseUrl + '/29.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133659',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 12mm',
        externalCode: '263-2126',
        description: 'Plain stainless steel hex bolt, M4 x 12mm, 1 bag of 100.',
        status: Status.Active,
        gtin: '2632126',
        images: [toImage(staticFilesBaseUrl + '/30.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133660',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M4 x 10mm',
        externalCode: '520-009',
        description: 'Plain stainless steel hex bolt, M4 x 10mm, 1 bag of 100.',
        status: Status.Active,
        gtin: '520009',
        images: [toImage(staticFilesBaseUrl + '/31.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133661',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 35mm',
        externalCode: '190-204',
        description: 'Plain stainless steel hex bolt, M6 x 35mm, 1 bag of 50.',
        status: Status.Active,
        gtin: '190204',
        images: [toImage(staticFilesBaseUrl + '/32.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133662',
        name: 'Plain Stainless Steel Hex, Hex Bolt, M6 x 20mm',
        externalCode: '190-175',
        description: 'Plain stainless steel hex bolt, M6 x 20mm, 1 bag of 50.',
        status: Status.Active,
        gtin: '190175',
        images: [toImage(staticFilesBaseUrl + '/33.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133663',
        name: 'Plain Stainless Steel, Hex Bolt, M12 x 40mm',
        externalCode: '508-1082',
        description: 'Plain stainless steel hex bolt, M12 x 40mm, 1 bag of 25.',
        status: Status.Active,
        gtin: '5081082',
        images: [toImage(staticFilesBaseUrl + '/34.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133664',
        name: 'Clear Passivated, Zinc Steel, Hex Bolt, M8 x 90mm',
        externalCode: '279-666',
        description: 'Clear passivated zinc steel hex bolt, M8 x 90mm, 1 box of 50.',
        status: Status.Active,
        gtin: '279666',
        images: [toImage(staticFilesBaseUrl + '/35.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
    {
        id: '133665',
        name: 'Bright Zinc Plated Steel, Hex Bolt, M6 x 80mm',
        externalCode: '917-3129',
        description: 'Bright zinc plated steel hex bolt, M6 x 80mm, 1 bag of 50.',
        status: Status.Active,
        gtin: '9173129',
        images: [toImage(staticFilesBaseUrl + '/36.jpg')],
        vatRate: 0,
        productCategoryId: 'bolts-screws-category',
        supplierId: '3',
        createdAt: new Date('2024-01-20T10:00:00Z'),
    },
];

export default mockProductsData;
