import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {Customer as ApiCustomer} from '@/gen/api';
import {Customer as CustomerModel} from '@/models/Customer';

export const toCustomer = (apiCustomer: ApiCustomer): CustomerModel => {
    return {
        ...apiCustomer,
        status: toStatus(apiCustomer.status),
    };
};

export const toApiCustomer = (customer: CustomerModel):  ApiCustomer => {
    return {
        ...customer,
        status: toApiStatus(customer.status),
    };
};
