/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CalendarEventType = {
    Holiday: 'HOLIDAY',
    NonWorkingDay: 'NON_WORKING_DAY',
    Default: 'DEFAULT'
} as const;
export type CalendarEventType = typeof CalendarEventType[keyof typeof CalendarEventType];


// @ts-ignore
export function CalendarEventTypeFromJSON(json: any): CalendarEventType {
    return CalendarEventTypeFromJSONTyped(json, false);
}

// @ts-ignore
export function CalendarEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventType {
    return json as CalendarEventType;
}

// @ts-ignore
export function CalendarEventTypeToJSON(value?: CalendarEventType | null): any {
    return value as any;
}

