import {Status} from '@/models/Status';

export enum UserRole {
    Admin = 'ADMIN',
    Customer = 'CUSTOMER',
    Retailer = 'RETAILER',
    Supplier = 'SUPPLIER',
    Unknown = 'UNKNOWN'
}

export function parseUserRole(roleStr: string): UserRole {
    switch (roleStr.toUpperCase()) {
        case 'ADMIN':
            return UserRole.Admin;
        case 'CUSTOMER':
            return UserRole.Customer;
        case 'RETAILER':
            return UserRole.Retailer;
        case 'SUPPLIER':
            return UserRole.Supplier;
        default:
            return UserRole.Unknown;
    }
}

export const isSupplier = (userRole: UserRole): boolean => userRole === UserRole.Supplier;
export const isAdmin = (userRole: UserRole): boolean => userRole === UserRole.Admin;
export const isCustomer = (userRole: UserRole): boolean => userRole === UserRole.Customer;
export const isRetailer = (userRole: UserRole): boolean => userRole === UserRole.Retailer;

export interface User {
    id: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    role: UserRole;
    mobileCountryCode?: string;
    mobileNumber?: string;
    currencyCode: string;
    languageCode: string;
    timezone: string;
    status: Status;
    settings?: UserSettings;
    organizationId?: string;
    customerId?: string;
    storeId?: string;
    supplierId?: string;
    createdAt?: Date;
}

export interface Users {
    items: User[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export interface NotificationSettings {
    email: boolean;
    sms: boolean;
    orders: boolean;
    promotions: boolean;
}

export interface UserSettings {
    notifications?: NotificationSettings;
}
