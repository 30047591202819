import {Configuration} from '@/api';
import {toApiStatus} from '@/api/mappers/mapStatus';
import {toStore} from '@/api/mappers/mapStore';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {SuppliersApi as ApiSuppliersApi, GetDeliveryWindowsRequest} from '@/gen/api';
import {Customers} from '@/models/Customer';
import {DeliveryWindow, DeliveryWindowImpl} from '@/models/DeliveryWindow';
import {Stores} from '@/models/Store';
import {Supplier, SupplierCreate, Suppliers, SupplierUpdate} from '@/models/Supplier';

export interface SupplierFilters {
    createdByStoreId: string;
}

interface SuppliersApi {
    listSuppliers(offset?: number, limit?: number): Promise<Suppliers>;
    findSuppliers(query?: string, filters?: Partial<SupplierFilters>, offset?: number, limit?: number): Promise<Suppliers>;
    getSupplier(id: string): Promise<Supplier | undefined>;
    getSuppliers(ids: string[]): Promise<Supplier[] | undefined>;
    createSupplier(supplier: SupplierCreate): Promise<void>;
    updateSupplier(id: string, supplier: SupplierUpdate): Promise<void>;
    deleteSupplier(id: string): Promise<void>;
    getSupplierCustomerStores(supplierId: string, customerId: string): Promise<Stores>;
    listAllSupplierStores(id: string): Promise<Stores>;
    listSupplierCustomers(id: string): Promise<Customers>;
    getDeliveryWindows(supplierId: string | string[], storeId: string, lookAheadInWeeks?: number): Promise<DeliveryWindow[]>;
    addStoresToSupplier(supplierId: string, customerId: string, storeIds: string[]): Promise<void>;
    removeStoresFromSupplier(supplierId: string, customerId: string, storeIds: string[]): Promise<void>;
}

export class SuppliersApiImpl implements SuppliersApi {
    private suppliersApi: ApiSuppliersApi;

    constructor(configuration: Configuration) {
        this.suppliersApi = new ApiSuppliersApi(configuration);
    }

    async listSuppliers(offset: number = 0, limit: number = 10): Promise<Suppliers> {
        try {
            const response = await this.suppliersApi.listSuppliers({
                offset,
                limit,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toSupplier),
            };
        } catch (error) {
            console.error('Error listing suppliers:', error);
            throw error;
        }
    }

    async findSuppliers(query?: string, filters?: Partial<SupplierFilters>, offset?: number, limit?: number): Promise<Suppliers> {
        try {
            const response = await this.suppliersApi.listSuppliers({
                query,
                limit,
                offset,
                createdByStoreId: filters?.createdByStoreId,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toSupplier),
            };
        } catch (error) {
            console.error('Error finding suppliers:', error);
            throw error;
        }
    }

    async getSupplier(id: string): Promise<Supplier | undefined> {
        try {
            const response = await this.suppliersApi.getSupplier({id});
            return toSupplier(response);
        } catch (error) {
            console.error('Error getting supplier:', error);
            throw error;
        }
    }

    async getSuppliers(ids: string[]): Promise<Supplier[] | undefined> {
        try {
            const response = await this.suppliersApi.listSuppliers({supplierId: ids});
            return response.items.map(toSupplier);
        } catch (error) {
            console.error('Error getting suppliers:', error);
            throw error;
        }
    }

    async createSupplier(supplier: SupplierCreate): Promise<void> {
        try {
            await this.suppliersApi.createSupplier({
                supplierCreateRequest: {
                    ...supplier,
                    status: toApiStatus(supplier.status),
                },
            });
        } catch (error) {
            console.error('Error creating supplier:', error);
            throw error;
        }
    }

    async updateSupplier(id: string, supplier: SupplierUpdate): Promise<void> {
        try {
            await this.suppliersApi.updateSupplier({
                id,
                supplierUpdateRequest: {
                    ...supplier,
                    status: toApiStatus(supplier.status),
                },
            });
        } catch (error) {
            console.error('Error updating supplier:', error);
            throw error;
        }
    }

    async deleteSupplier(id: string): Promise<void> {
        try {
            await this.suppliersApi.deleteSupplier({id});
        } catch (error) {
            console.error('Error deleting supplier:', error);
            throw error;
        }
    }

    async getSupplierCustomerStores(supplierId: string, customerId: string): Promise<Stores> {
        try {
            const response = await this.suppliersApi.getSupplierCustomerStores({
                supplierId,
                customerId,
            });
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toStore),
            };
        } catch (error) {
            console.error('Error getting supplier customer stores:', error);
            throw error;
        }
    }

    async listAllSupplierStores(_id: string): Promise<Stores> {
        throw new Error('implement');
    }

    async listSupplierCustomers(_id: string): Promise<Customers> {
        throw new Error('implement');
    }

    async getDeliveryWindows(supplierId: string | string[], storeId: string, lookAheadInWeeks?:number): Promise<DeliveryWindow[]> {
        try {
            const request: GetDeliveryWindowsRequest = {
                storeId: storeId,
                supplierId: Array.isArray(supplierId) ? supplierId : [supplierId],
                lookAheadInWeeks: lookAheadInWeeks,
            };
            const response = await this.suppliersApi.getDeliveryWindows(request);
            return response.deliveryWindows.map(DeliveryWindowImpl.fromObject);
        } catch (error) {
            console.error('Error getting delivery windows:', error);
            throw error;
        }
    }

    async addStoresToSupplier(_supplierId: string, _customerId: string, _storeIds: string[]): Promise<void> {
        throw new Error('implement');
    }

    async removeStoresFromSupplier(_supplierId: string, _customerId: string, _storeIds: string[]): Promise<void> {
        throw new Error('implement');
    }
}

export default SuppliersApi;
