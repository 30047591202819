/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Customer } from './Customer';
// @ts-ignore
import {
// @ts-ignore
    CustomerFromJSON,
// @ts-ignore
    CustomerFromJSONTyped,
// @ts-ignore
    CustomerToJSON,
} from './Customer';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { StoreAddress } from './StoreAddress';
// @ts-ignore
import {
// @ts-ignore
    StoreAddressFromJSON,
// @ts-ignore
    StoreAddressFromJSONTyped,
// @ts-ignore
    StoreAddressToJSON,
} from './StoreAddress';
// @ts-ignore
import type { StoreReceivingWindow } from './StoreReceivingWindow';
// @ts-ignore
import {
// @ts-ignore
    StoreReceivingWindowFromJSON,
// @ts-ignore
    StoreReceivingWindowFromJSONTyped,
// @ts-ignore
    StoreReceivingWindowToJSON,
} from './StoreReceivingWindow';
// @ts-ignore
import type { Warehouse } from './Warehouse';
// @ts-ignore
import {
// @ts-ignore
    WarehouseFromJSON,
// @ts-ignore
    WarehouseFromJSONTyped,
// @ts-ignore
    WarehouseToJSON,
} from './Warehouse';

/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    contactNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    description: string;
    /**
     * 
     * @type {Status}
     * @memberof Store
     */
    status: Status;
    /**
     * 
     * @type {Array<StoreAddress>}
     * @memberof Store
     */
    addresses: Array<StoreAddress>;
    /**
     * 
     * @type {Array<StoreReceivingWindow>}
     * @memberof Store
     */
    receivingWindows: Array<StoreReceivingWindow>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Store
     */
    customData: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Store
     */
    dynamicFields: { [key: string]: string; };
    /**
     * 
     * @type {Customer}
     * @memberof Store
     */
    customer?: Customer;
    /**
     * 
     * @type {Warehouse}
     * @memberof Store
     */
    warehouse?: Warehouse;
    /**
     * 
     * @type {Date}
     * @memberof Store
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the Store interface.
 */
// @ts-ignore
export function instanceOfStore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "contactNumber" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "addresses" in value;
    isInstance = isInstance && "receivingWindows" in value;
    isInstance = isInstance && "customData" in value;
    isInstance = isInstance && "dynamicFields" in value;

    return isInstance;
}

// @ts-ignore
export function StoreFromJSON(json: any): Store {
    return StoreFromJSONTyped(json, false);
}

// @ts-ignore
export function StoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Store {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'customerId': json['customer_id'],
        'name': json['name'],
        'email': json['email'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'contactNumber': json['contact_number'],
        'description': json['description'],
        'status': StatusFromJSON(json['status']),
        'addresses': ((json['addresses'] as Array<any>).map(StoreAddressFromJSON)),
        'receivingWindows': ((json['receiving_windows'] as Array<any>).map(StoreReceivingWindowFromJSON)),
        'customData': json['custom_data'],
        'dynamicFields': json['dynamic_fields'],
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'warehouse': !exists(json, 'warehouse') ? undefined : WarehouseFromJSON(json['warehouse']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function StoreToJSON(value?: Store | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'customer_id': value.customerId,
        'name': value.name,
        'email': value.email,
        'external_code': value.externalCode,
        'contact_number': value.contactNumber,
        'description': value.description,
        'status': StatusToJSON(value.status),
        'addresses': ((value.addresses as Array<any>).map(StoreAddressToJSON)),
        'receiving_windows': ((value.receivingWindows as Array<any>).map(StoreReceivingWindowToJSON)),
        'custom_data': value.customData,
        'dynamic_fields': value.dynamicFields,
        'customer': CustomerToJSON(value.customer),
        'warehouse': WarehouseToJSON(value.warehouse),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

