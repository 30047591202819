import {Calendar, CalendarEvent, CalendarEventType} from '@/gen/api';

export const mockCalendarsData: Calendar[] = [
    {
        id: '1',
        name: 'US Public Holidays',
        countryCode: 'US',
        supplierId: '1',
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '2',
        name: 'Company Holidays',
        countryCode: 'US',
        supplierId: '1',
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
];

export const mockCalendarEventsData: CalendarEvent[] = [
    {
        id: '1',
        calendarId: '1',
        name: 'New Year\'s Day',
        type: CalendarEventType.Holiday,
        start: new Date('2024-01-01'),
        end: new Date('2024-01-02'),
        frequency: 'year',
        interval: 1,
        lastDayOfOccurrence: new Date('2024-01-01'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '2',
        calendarId: '1',
        name: 'Martin Luther King Jr. Day',
        type: CalendarEventType.Holiday,
        start: new Date('2024-01-15'),
        end: new Date('2024-01-16'),
        frequency: 'year',
        interval: 1,
        lastDayOfOccurrence: new Date('2024-01-15'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '3',
        calendarId: '1',
        name: 'Memorial Day',
        type: CalendarEventType.Holiday,
        start: new Date('2024-05-27'),
        end: new Date('2024-05-28'),
        frequency: 'year',
        interval: 1,
        lastDayOfOccurrence: new Date('2024-05-27'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '1',
        calendarId: '2',
        name: 'Company Picnic',
        type: CalendarEventType.NonWorkingDay,
        start: new Date('2024-07-04'),
        end: new Date('2024-07-05'),
        interval: 0,
        lastDayOfOccurrence: new Date('2024-07-04'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '2',
        calendarId: '2',
        name: 'Employee Appreciation Day',
        type: CalendarEventType.NonWorkingDay,
        start: new Date('2024-09-01'),
        end: new Date('2024-09-02'),
        interval: 0,
        lastDayOfOccurrence: new Date('2024-09-01'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '3',
        calendarId: '2',
        name: 'End of Year Party',
        type: CalendarEventType.NonWorkingDay,
        start: new Date('2024-12-31'),
        end: new Date('2025-1-1'),
        interval: 0,
        lastDayOfOccurrence: new Date('2024-12-31'),
        numberOfOccurrences: 1,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
];
