import {Customer, Status} from '@/gen/api';

export const mockCustomersData: Customer[] = [
    {
        id: '1',
        organizationId: '1',
        name: 'Pinnacle Distribution Center',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '2',
        organizationId: '1',
        name: 'Eagle Warehouse Solutions',
        status: Status.Active,
        createdAt: new Date('2024-01-15T10:00:00Z'),
    },
    {
        id: '3',
        organizationId: '1',
        name: 'Prime Storage Depot',
        status: Status.Active,
        createdAt: new Date('2024-01-16T10:00:00Z'),
    },
    {
        id: '4',
        organizationId: '1',
        name: 'Zenith Fulfillment Facility',
        status: Status.Active,
        createdAt: new Date('2024-01-17T10:00:00Z'),
    },
    {
        id: '5',
        organizationId: '1',
        name: 'Horizon Supply Hub',
        status: Status.Active,
        createdAt: new Date('2024-01-18T10:00:00Z'),
    },
];
