import {toImage} from '@/api/mappers/mapImage';
import {toPrice} from '@/api/mappers/mapPrice';
import {toApiProduct, toProduct} from '@/api/mappers/mapProduct';
import {toPromotion} from '@/api/mappers/mapPromotion';
import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {Sku as ApiSku} from '@/gen/api/models/Sku';
import {SkuCreateRequest as ApiSkuCreateRequest} from '@/gen/api/models/SkuCreateRequest';
import {SkuListResponse as ApiSkuListResponse} from '@/gen/api/models/SkuListResponse';
import {SkuUpdateRequest as ApiSkuUpdateRequest} from '@/gen/api/models/SkuUpdateRequest';
import {SkuCreate, Sku as SkuModel, Skus, SkuUpdate} from '@/models/Sku';

export function toSku(apiSku: ApiSku): SkuModel {
    return {
        ...apiSku,
        status: toStatus(apiSku.status),
        images: apiSku.images.map(toImage),
        product: apiSku.product && toProduct(apiSku.product),
        prices: apiSku.prices && apiSku.prices.map(toPrice),
        promotions: apiSku.promotions && apiSku.promotions.map(toPromotion),
        supplier: apiSku.supplier ? toSupplier(apiSku.supplier) : undefined,
    };
}

export function toSkus(response: ApiSkuListResponse): Skus {
    return {
        items: response.items.map(toSku),
        totalCount: response.totalCount,
        estimatedTotalCount: response.estimatedTotalCount,
    };
}

export function toApiSkuCreateRequest(sku: SkuCreate): ApiSkuCreateRequest {
    return {
        ...sku,
        status: sku.status ? toApiStatus(sku.status) : undefined,
    };
}

export function toApiSkuUpdateRequest(sku: SkuUpdate): ApiSkuUpdateRequest {
    return {
        ...sku,
        status: sku.status ? toApiStatus(sku.status) : undefined,
        product: sku.product ? toApiProduct(sku.product) : undefined,
    };
}
