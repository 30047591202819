/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  Store,
// @ts-ignore
  StoreCreateRequest,
// @ts-ignore
  StoreInventoryListResponse,
// @ts-ignore
  StoreListResponse,
// @ts-ignore
  StoreUpdateRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    StoreFromJSON,
// @ts-ignore
    StoreToJSON,
// @ts-ignore
    StoreCreateRequestFromJSON,
// @ts-ignore
    StoreCreateRequestToJSON,
// @ts-ignore
    StoreInventoryListResponseFromJSON,
// @ts-ignore
    StoreInventoryListResponseToJSON,
// @ts-ignore
    StoreListResponseFromJSON,
// @ts-ignore
    StoreListResponseToJSON,
// @ts-ignore
    StoreUpdateRequestFromJSON,
// @ts-ignore
    StoreUpdateRequestToJSON,
} from '../models/index';

export interface CreateStoreRequest {
    storeCreateRequest: StoreCreateRequest;
}

export interface DeleteStoreRequest {
    id: string;
}

export interface GetStoreRequest {
    id: string;
    include?: Array<string>;
}

export interface ListStoreInventoryRequest {
    id: string;
}

export interface ListStoresRequest {
    query?: string;
    include?: Array<string>;
    limit?: number;
    offset?: number;
}

export interface UpdateStoreRequest {
    id: string;
    storeUpdateRequest: StoreUpdateRequest;
}

/**
 * 
 */
export class StoresApi extends runtime.BaseAPI {

    /**
     * Create Store
     */
    async createStoreRaw(requestParameters: CreateStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeCreateRequest === null || requestParameters.storeCreateRequest === undefined) {
            throw new runtime.RequiredError('storeCreateRequest','Required parameter requestParameters.storeCreateRequest was null or undefined when calling createStore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/stores`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreCreateRequestToJSON(requestParameters.storeCreateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Store
     */
    async createStore(requestParameters: CreateStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createStoreRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Store by ID
     */
    async deleteStoreRaw(requestParameters: DeleteStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stores/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Store by ID
     */
    async deleteStore(requestParameters: DeleteStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStoreRaw(requestParameters, initOverrides);
    }

    /**
     * Get Store by ID
     */
    async getStoreRaw(requestParameters: GetStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Store>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStore.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stores/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreFromJSON(jsonValue));
    }

    /**
     * Get Store by ID
     */
    async getStore(requestParameters: GetStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Store> {
        const response = await this.getStoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all store inventory entries
     */
    async listStoreInventoryRaw(requestParameters: ListStoreInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreInventoryListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listStoreInventory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stores/{id}/inventory`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreInventoryListResponseFromJSON(jsonValue));
    }

    /**
     * Get all store inventory entries
     */
    async listStoreInventory(requestParameters: ListStoreInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreInventoryListResponse> {
        const response = await this.listStoreInventoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all stores
     */
    async listStoresRaw(requestParameters: ListStoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreListResponseFromJSON(jsonValue));
    }

    /**
     * Get all stores
     */
    async listStores(requestParameters: ListStoresRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreListResponse> {
        const response = await this.listStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Store by ID
     */
    async updateStoreRaw(requestParameters: UpdateStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStore.');
        }

        if (requestParameters.storeUpdateRequest === null || requestParameters.storeUpdateRequest === undefined) {
            throw new runtime.RequiredError('storeUpdateRequest','Required parameter requestParameters.storeUpdateRequest was null or undefined when calling updateStore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/stores/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StoreUpdateRequestToJSON(requestParameters.storeUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Store by ID
     */
    async updateStore(requestParameters: UpdateStoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStoreRaw(requestParameters, initOverrides);
    }

}
