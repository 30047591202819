import {PriceList} from '@/models/PriceList';
import {Sku} from '@/models/Sku';
import {Status} from '@/models/Status';
import {Supplier} from '@/models/Supplier';

export enum PriceAssociation {
    PRICE_LIST = 'priceList',
    SKU = 'sku',
    SUPPLIER = 'supplier',
    ALL = 'all',
}

export interface Price {
    id: string;
    supplierId: string;
    customerId?: string;
    storeId?: string;
    skuId: string;
    price: Amount;
    quantityFrom?: number;
    quantityTo?: number;
    status: Status;
    createdAt: Date;
    priceList?: PriceList;
    sku?: Sku;
    supplier?: Supplier;
}

export interface PriceCreate {
    supplierId: string;
    customerId?: string;
    storeId?: string;
    skuId: string;
    price: Amount;
    quantityFrom?: number;
    quantityTo?: number;
}

export interface Amount {
    currencyCode: string;
    amount: number;
}

export interface Prices {
    items: Price[];
    totalCount?: number;
    estimatedTotalCount?: number;
}
