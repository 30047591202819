import {toOrganization} from '@/api/mappers/mapOrganization';
import AbstractApi from '@/api/mocks/AbstractApi';
import {mockOrganizationsData} from '@/api/mocks/data/organizations';
import {calculatePagination} from '@/api/mocks/Pagination';
import OrganizationsApi from '@/api/OrganizationsApi';
import {Organization, Organizations} from '@/models/Organization';

export default class MockOrganizationsApiImpl extends AbstractApi implements OrganizationsApi {
    async createOrganization(_organization: Organization): Promise<void> {
        await this.sleep();
    }

    async deleteOrganization(_id: string): Promise<void> {
        await this.sleep();
    }

    async getOrganization(id: string): Promise<Organization | undefined> {
        await this.sleep();

        const result = mockOrganizationsData.find(org => org.id === id);

        return result ? toOrganization(result) : undefined;
    }

    async findOrganizations(query?: string, offset: number = 0, limit: number = 10): Promise<Organizations> {
        await this.sleep();

        let items = [...mockOrganizationsData];

        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(org => org.name.toLowerCase().includes(queryLowerCase));
        }

        return calculatePagination(items.map(toOrganization), offset, limit);
    }

    async listOrganizations(offset: number = 0, limit: number = 10): Promise<Organizations> {
        await this.sleep();

        return calculatePagination(mockOrganizationsData.map(toOrganization), offset, limit);
    }

    async updateOrganization(_id: string, _organization: Organization): Promise<void> {
        await this.sleep();
    }
}
