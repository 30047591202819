import {mockPriceListsData} from '@/api/mocks/data/priceLists';
import {mockSkusData} from '@/api/mocks/data/skus';
import {mockSuppliersData} from '@/api/mocks/data/suppliers';
import {Price, Status} from '@/gen/api';
import {PriceAssociation} from '@/models/Price';

export const mockPricesData: Price[] = [
    /* Food and beverages */
    {
        id: '1',
        code: '1',
        skuId: '123654',
        price: {
            currencyCode: 'USD',
            amount: 3.28,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '2',
        code: '2',
        skuId: '123655',
        price: {
            currencyCode: 'USD',
            amount: 3.78,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '3',
        code: '3',
        skuId: '123656',
        price: {
            currencyCode: 'USD',
            amount: 4.38,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '4',
        code: '4',
        skuId: '123657',
        price: {
            currencyCode: 'USD',
            amount: 2.99,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '5',
        code: '5',
        skuId: '123658',
        price: {
            currencyCode: 'USD',
            amount: 3.66,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '6',
        code: '6',
        skuId: '123663',
        price: {
            currencyCode: 'USD',
            amount: 3.66,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '7',
        code: '7',
        skuId: '123664',
        price: {
            currencyCode: 'USD',
            amount: 3.66,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '8',
        code: '8',
        skuId: '123665',
        price: {
            currencyCode: 'USD',
            amount: 6.78,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '9',
        code: '9',
        skuId: '123659',
        price: {
            currencyCode: 'USD',
            amount: 5.68,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '10',
        code: '10',
        skuId: '123660',
        price: {
            currencyCode: 'USD',
            amount: 7.58,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '11',
        code: '11',
        skuId: '123661',
        price: {
            currencyCode: 'USD',
            amount: 6.98,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '12',
        code: '12',
        skuId: '123662',
        price: {
            currencyCode: 'USD',
            amount: 5.68,
        },
        supplierId: '1',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    /* Fashion */
    {
        id: '13',
        code: '13',
        skuId: '113654',
        price: {
            currencyCode: 'USD',
            amount: 69.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '14',
        code: '14',
        skuId: '113655',
        price: {
            currencyCode: 'USD',
            amount: 79.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '15',
        code: '15',
        skuId: '113656',
        price: {
            currencyCode: 'USD',
            amount: 74.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '16',
        code: '16',
        skuId: '113657',
        price: {
            currencyCode: 'USD',
            amount: 39.99,
        },
        supplierId: '2',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '17',
        code: '17',
        skuId: '113658',
        price: {
            currencyCode: 'USD',
            amount: 89.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '18',
        code: '18',
        skuId: '113659',
        price: {
            currencyCode: 'USD',
            amount: 79.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '19',
        code: '19',
        skuId: '113660',
        price: {
            currencyCode: 'USD',
            amount: 49.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '20',
        code: '20',
        skuId: '113661',
        price: {
            currencyCode: 'USD',
            amount: 39.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '21',
        code: '21',
        skuId: '113662',
        price: {
            currencyCode: 'USD',
            amount: 99.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '22',
        code: '22',
        skuId: '113663',
        price: {
            currencyCode: 'USD',
            amount: 109.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '23',
        code: '23',
        skuId: '113664',
        price: {
            currencyCode: 'USD',
            amount: 49.99,
        },
        supplierId: '2',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '24',
        code: '24',
        skuId: '113665',
        price: {
            currencyCode: 'USD',
            amount: 59.99,
        },
        supplierId: '3',
        priceListId: '1',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    /* Bolts and screws */
    {
        id: '25',
        code: '25',
        skuId: '133654',
        price: {
            currencyCode: 'USD',
            amount: 12.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '26',
        code: '26',
        skuId: '133655',
        price: {
            currencyCode: 'USD',
            amount: 14.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '27',
        code: '27',
        skuId: '133656',
        price: {
            currencyCode: 'USD',
            amount: 24.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '28',
        code: '28',
        skuId: '133657',
        price: {
            currencyCode: 'USD',
            amount: 9.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '29',
        code: '29',
        skuId: '133658',
        price: {
            currencyCode: 'USD',
            amount: 19.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '30',
        code: '30',
        skuId: '133659',
        price: {
            currencyCode: 'USD',
            amount: 8.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '31',
        code: '31',
        skuId: '133660',
        price: {
            currencyCode: 'USD',
            amount: 6.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '32',
        code: '32',
        skuId: '133661',
        price: {
            currencyCode: 'USD',
            amount: 17.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '33',
        code: '33',
        skuId: '133662',
        price: {
            currencyCode: 'USD',
            amount: 11.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '34',
        code: '34',
        skuId: '133663',
        price: {
            currencyCode: 'USD',
            amount: 21.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '35',
        code: '35',
        skuId: '133664',
        price: {
            currencyCode: 'USD',
            amount: 29.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    {
        id: '36',
        code: '36',
        skuId: '133665',
        price: {
            currencyCode: 'USD',
            amount: 26.99,
        },
        supplierId: '3',
        priceListId: '2',
        status: Status.Active,
        createdAt: new Date('2024-01-14T10:00:00Z'),
    },
    /* Motorcycle Parts */
    {
        id: '37',
        code: '37',
        skuId: '143654',
        price: {
            currencyCode: 'USD',
            amount: 160.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '38',
        code: '38',
        skuId: '143655',
        price: {
            currencyCode: 'USD',
            amount: 250.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '39',
        code: '39',
        skuId: '143656',
        price: {
            currencyCode: 'USD',
            amount: 395.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '40',
        code: '40',
        skuId: '143657',
        price: {
            currencyCode: 'USD',
            amount: 287.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '41',
        code: '41',
        skuId: '143658',
        price: {
            currencyCode: 'USD',
            amount: 156.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '42',
        code: '42',
        skuId: '143659',
        price: {
            currencyCode: 'USD',
            amount: 141.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '43',
        code: '43',
        skuId: '143660',
        price: {
            currencyCode: 'USD',
            amount: 177.21,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '44',
        code: '44',
        skuId: '143661',
        price: {
            currencyCode: 'USD',
            amount: 145.88,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '45',
        code: '45',
        skuId: '143662',
        price: {
            currencyCode: 'USD',
            amount: 67.42,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '46',
        code: '46',
        skuId: '143663',
        price: {
            currencyCode: 'USD',
            amount: 58.86,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '47',
        code: '47',
        skuId: '143664',
        price: {
            currencyCode: 'USD',
            amount: 56.98,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '48',
        code: '48',
        skuId: '143665',
        price: {
            currencyCode: 'USD',
            amount: 76.44,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '49',
        code: '49',
        skuId: '143667',
        price: {
            currencyCode: 'USD',
            amount: 59.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '50',
        code: '50',
        skuId: '143668',
        price: {
            currencyCode: 'USD',
            amount: 69.99,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '51',
        code: '51',
        skuId: '143669',
        price: {
            currencyCode: 'USD',
            amount: 67.98,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
    {
        id: '52',
        code: '52',
        skuId: '143640',
        price: {
            currencyCode: 'USD',
            amount: 80.00,
        },
        supplierId: '5',
        priceListId: '3',
        status: Status.Active,
        createdAt: new Date('2024-01-11T10:00:00Z'),
    },
];

export const addAssociationsToPrice = (price: Price, include: PriceAssociation[]): Price => {
    const all = include.includes(PriceAssociation.ALL);

    if (all || include.includes(PriceAssociation.PRICE_LIST)) {
        const priceList = mockPriceListsData.find((priceList) => priceList.id === price.priceListId);
        if (priceList) {
            price.priceList = priceList;
        }
    }

    if (all || include.includes(PriceAssociation.SKU)) {
        const sku = mockSkusData.find((sku) => sku.id === price.skuId);
        if (sku) {
            price.sku = sku;
        }
    }

    if (all || include.includes(PriceAssociation.SUPPLIER)) {
        const supplier = mockSuppliersData.find((supplier) => supplier.id === price.supplierId);
        if (supplier) {
            price.supplier = supplier;
        }
    }

    return price;
};
