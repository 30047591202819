import {Configuration} from '@/api';
import {toApiUserCreate, toApiUserUpdate, toUser} from '@/api/mappers/mapUser';
import {UsersApi as ApiUsersApi} from '@/gen/api';
import {User, Users} from '@/models/User';

interface UsersApi {
    createUser(user: User): Promise<void>;
    getUser(id: string): Promise<User | undefined>;
    listUsers(limit?: number, offset?: number): Promise<Users>;
    findUsers(query?: string, limit?: number, offset?: number): Promise<Users>;
    getCurrentUser(): Promise<User>;
    updateCurrentUser(user: User): Promise<void>;
    updateUser(id: string, user: User): Promise<void>;
    verifyUser(id: string, code: string): Promise<void>;
}

export class UsersApiImpl implements UsersApi {
    private usersApi: ApiUsersApi;

    constructor(configuration: Configuration) {
        this.usersApi = new ApiUsersApi(configuration);
    }

    async createUser(user: User): Promise<void> {
        try {
            await this.usersApi.createUser({
                userCreate: toApiUserCreate(user),
            });
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    }

    async getUser(id: string): Promise<User | undefined> {
        try {
            const response = await this.usersApi.getUser({id});
            return toUser(response);
        } catch (error) {
            console.error('Error getting user:', error);
            throw error;
        }
    }

    async listUsers(limit?: number, offset?: number): Promise<Users> {
        try {
            const response = await this.usersApi.listUsers({limit, offset});
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toUser),
            };
        } catch (error) {
            console.error('Error listing users:', error);
            throw error;
        }
    }

    async findUsers(query?: string, limit?: number, offset?: number): Promise<Users> {
        try {
            const response = await this.usersApi.listUsers({query, limit, offset});
            return {
                totalCount: response.totalCount,
                estimatedTotalCount: response.estimatedTotalCount,
                items: response.items.map(toUser),
            };
        } catch (error) {
            console.error('Error finding users:', error);
            throw error;
        }
    }

    async getCurrentUser(): Promise<User> {
        try {
            const response = await this.usersApi.getCurrentUser();
            return toUser(response);
        } catch (error) {
            console.error('Error getting current user:', error);
            throw error;
        }
    }

    async updateCurrentUser(user: User): Promise<void> {
        try {
            await this.usersApi.updateCurrentUser({
                userUpdate: toApiUserUpdate(user),
            });
        } catch (error) {
            console.error('Error updating current user:', error);
            throw error;
        }
    }

    async updateUser(id: string, user: User): Promise<void> {
        try {
            await this.usersApi.updateUser({
                id,
                userUpdate: toApiUserUpdate(user),
            });
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    }

    async verifyUser(id: string, code: string): Promise<void> {
        try {
            await this.usersApi.verifyUser({
                id,
                userVerificationRequest: {
                    code,
                },
            });
        } catch (error) {
            console.error('Error verifying user:', error);
            throw error;
        }
    }
}

export default UsersApi;
