/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';
// @ts-ignore
import type { OrderDeliveryAddress } from './OrderDeliveryAddress';
// @ts-ignore
import {
// @ts-ignore
    OrderDeliveryAddressFromJSON,
// @ts-ignore
    OrderDeliveryAddressFromJSONTyped,
// @ts-ignore
    OrderDeliveryAddressToJSON,
} from './OrderDeliveryAddress';
// @ts-ignore
import type { OrderItem } from './OrderItem';
// @ts-ignore
import {
// @ts-ignore
    OrderItemFromJSON,
// @ts-ignore
    OrderItemFromJSONTyped,
// @ts-ignore
    OrderItemToJSON,
} from './OrderItem';
// @ts-ignore
import type { OrderStatus } from './OrderStatus';
// @ts-ignore
import {
// @ts-ignore
    OrderStatusFromJSON,
// @ts-ignore
    OrderStatusFromJSONTyped,
// @ts-ignore
    OrderStatusToJSON,
} from './OrderStatus';
// @ts-ignore
import type { ProofOfPayment } from './ProofOfPayment';
// @ts-ignore
import {
// @ts-ignore
    ProofOfPaymentFromJSON,
// @ts-ignore
    ProofOfPaymentFromJSONTyped,
// @ts-ignore
    ProofOfPaymentToJSON,
} from './ProofOfPayment';
// @ts-ignore
import type { Store } from './Store';
// @ts-ignore
import {
// @ts-ignore
    StoreFromJSON,
// @ts-ignore
    StoreFromJSONTyped,
// @ts-ignore
    StoreToJSON,
} from './Store';
// @ts-ignore
import type { Supplier } from './Supplier';
// @ts-ignore
import {
// @ts-ignore
    SupplierFromJSON,
// @ts-ignore
    SupplierFromJSONTyped,
// @ts-ignore
    SupplierToJSON,
} from './Supplier';
// @ts-ignore
import type { TimeWindow } from './TimeWindow';
// @ts-ignore
import {
// @ts-ignore
    TimeWindowFromJSON,
// @ts-ignore
    TimeWindowFromJSONTyped,
// @ts-ignore
    TimeWindowToJSON,
} from './TimeWindow';
// @ts-ignore
import type { Warehouse } from './Warehouse';
// @ts-ignore
import {
// @ts-ignore
    WarehouseFromJSON,
// @ts-ignore
    WarehouseFromJSONTyped,
// @ts-ignore
    WarehouseToJSON,
} from './Warehouse';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    code: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof Order
     */
    status: OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    externalCode?: string;
    /**
     * 
     * @type {TimeWindow}
     * @memberof Order
     */
    deliveryWindow?: TimeWindow;
    /**
     * 
     * @type {TimeWindow}
     * @memberof Order
     */
    pickupWindow?: TimeWindow;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    pickupWindowCutoffTime?: Date;
    /**
     * 
     * @type {TimeWindow}
     * @memberof Order
     */
    actualDeliveryDate?: TimeWindow;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerCode?: string;
    /**
     * 
     * @type {OrderDeliveryAddress}
     * @memberof Order
     */
    deliveryAddress: OrderDeliveryAddress;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    currencyCode: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {ProofOfPayment}
     * @memberof Order
     */
    proofOfPayment?: ProofOfPayment;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    rejectReason?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Order
     */
    customData?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    poNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    editedBySupplier?: boolean;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order
     */
    items: Array<OrderItem>;
    /**
     * 
     * @type {Amount}
     * @memberof Order
     */
    grandTotal?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Order
     */
    subtotal?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Order
     */
    totalVatAmount?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Order
     */
    totalDiscountAmount?: Amount;
    /**
     * 
     * @type {Supplier}
     * @memberof Order
     */
    supplier?: Supplier;
    /**
     * 
     * @type {Warehouse}
     * @memberof Order
     */
    warehouse?: Warehouse;
    /**
     * 
     * @type {Store}
     * @memberof Order
     */
    store?: Store;
}

/**
 * Check if a given object implements the Order interface.
 */
// @ts-ignore
export function instanceOfOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "deliveryAddress" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "storeId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

// @ts-ignore
export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'status': OrderStatusFromJSON(json['status']),
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'deliveryWindow': !exists(json, 'delivery_window') ? undefined : TimeWindowFromJSON(json['delivery_window']),
        'pickupWindow': !exists(json, 'pickup_window') ? undefined : TimeWindowFromJSON(json['pickup_window']),
        'pickupWindowCutoffTime': !exists(json, 'pickup_window_cutoff_time') ? undefined : (new Date(json['pickup_window_cutoff_time'])),
        'actualDeliveryDate': !exists(json, 'actual_delivery_date') ? undefined : TimeWindowFromJSON(json['actual_delivery_date']),
        'customerCode': !exists(json, 'customer_code') ? undefined : json['customer_code'],
        'deliveryAddress': OrderDeliveryAddressFromJSON(json['delivery_address']),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'currencyCode': json['currency_code'],
        'paymentDueDate': !exists(json, 'payment_due_date') ? undefined : (new Date(json['payment_due_date'])),
        'proofOfPayment': !exists(json, 'proof_of_payment') ? undefined : ProofOfPaymentFromJSON(json['proof_of_payment']),
        'note': json['note'],
        'rejectReason': !exists(json, 'reject_reason') ? undefined : json['reject_reason'],
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
        'supplierId': json['supplier_id'],
        'warehouseId': !exists(json, 'warehouse_id') ? undefined : json['warehouse_id'],
        'storeId': json['store_id'],
        'poNumber': !exists(json, 'po_number') ? undefined : json['po_number'],
        'createdAt': (new Date(json['created_at'])),
        'editedBySupplier': !exists(json, 'edited_by_supplier') ? undefined : json['edited_by_supplier'],
        'items': ((json['items'] as Array<any>).map(OrderItemFromJSON)),
        'grandTotal': !exists(json, 'grand_total') ? undefined : AmountFromJSON(json['grand_total']),
        'subtotal': !exists(json, 'subtotal') ? undefined : AmountFromJSON(json['subtotal']),
        'totalVatAmount': !exists(json, 'total_vat_amount') ? undefined : AmountFromJSON(json['total_vat_amount']),
        'totalDiscountAmount': !exists(json, 'total_discount_amount') ? undefined : AmountFromJSON(json['total_discount_amount']),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'warehouse': !exists(json, 'warehouse') ? undefined : WarehouseFromJSON(json['warehouse']),
        'store': !exists(json, 'store') ? undefined : StoreFromJSON(json['store']),
    };
}

// @ts-ignore
export function OrderToJSON(value?: Order | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'status': OrderStatusToJSON(value.status),
        'external_code': value.externalCode,
        'delivery_window': TimeWindowToJSON(value.deliveryWindow),
        'pickup_window': TimeWindowToJSON(value.pickupWindow),
        'pickup_window_cutoff_time': value.pickupWindowCutoffTime === undefined ? undefined : (value.pickupWindowCutoffTime.toISOString()),
        'actual_delivery_date': TimeWindowToJSON(value.actualDeliveryDate),
        'customer_code': value.customerCode,
        'delivery_address': OrderDeliveryAddressToJSON(value.deliveryAddress),
        'source': value.source,
        'currency_code': value.currencyCode,
        'payment_due_date': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString()),
        'proof_of_payment': ProofOfPaymentToJSON(value.proofOfPayment),
        'note': value.note,
        'reject_reason': value.rejectReason,
        'custom_data': value.customData,
        'supplier_id': value.supplierId,
        'warehouse_id': value.warehouseId,
        'store_id': value.storeId,
        'po_number': value.poNumber,
        'created_at': (value.createdAt.toISOString()),
        'edited_by_supplier': value.editedBySupplier,
        'items': ((value.items as Array<any>).map(OrderItemToJSON)),
        'grand_total': AmountToJSON(value.grandTotal),
        'subtotal': AmountToJSON(value.subtotal),
        'total_vat_amount': AmountToJSON(value.totalVatAmount),
        'total_discount_amount': AmountToJSON(value.totalDiscountAmount),
        'supplier': SupplierToJSON(value.supplier),
        'warehouse': WarehouseToJSON(value.warehouse),
        'store': StoreToJSON(value.store),
    };
}

