/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface OrderItemCreate
 */
export interface OrderItemCreate {
    /**
     * 
     * @type {string}
     * @memberof OrderItemCreate
     */
    skuId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemCreate
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemCreate
     */
    remarks?: string;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItemCreate
     */
    unitPrice?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItemCreate
     */
    lineItemPrice?: Amount;
    /**
     * 
     * @type {number}
     * @memberof OrderItemCreate
     */
    vatRate?: number;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItemCreate
     */
    vatAmount?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof OrderItemCreate
     */
    discountAmount?: Amount;
}

/**
 * Check if a given object implements the OrderItemCreate interface.
 */
// @ts-ignore
export function instanceOfOrderItemCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "skuId" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

// @ts-ignore
export function OrderItemCreateFromJSON(json: any): OrderItemCreate {
    return OrderItemCreateFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderItemCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemCreate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuId': json['sku_id'],
        'quantity': json['quantity'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'unitPrice': !exists(json, 'unit_price') ? undefined : AmountFromJSON(json['unit_price']),
        'lineItemPrice': !exists(json, 'line_item_price') ? undefined : AmountFromJSON(json['line_item_price']),
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'vatAmount': !exists(json, 'vat_amount') ? undefined : AmountFromJSON(json['vat_amount']),
        'discountAmount': !exists(json, 'discount_amount') ? undefined : AmountFromJSON(json['discount_amount']),
    };
}

// @ts-ignore
export function OrderItemCreateToJSON(value?: OrderItemCreate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sku_id': value.skuId,
        'quantity': value.quantity,
        'remarks': value.remarks,
        'unit_price': AmountToJSON(value.unitPrice),
        'line_item_price': AmountToJSON(value.lineItemPrice),
        'vat_rate': value.vatRate,
        'vat_amount': AmountToJSON(value.vatAmount),
        'discount_amount': AmountToJSON(value.discountAmount),
    };
}

