import {OrderPredictionResponse} from '@/gen/api';
import {OrderPrediction} from '@/models/OrderPrediction';
import {OrderPredictionItem} from '@/models/OrderPredictionItem';
import {Cart, CartImpl} from '@/modules/retailer/models/Cart';
import {CartItemImpl} from '@/modules/retailer/models/CartItem';

export const toOrderPrediction = (response: OrderPredictionResponse): OrderPrediction => {
    const items: OrderPredictionItem[] = response.items.map((item) => {
        return {
            predictionId: response.predictionId,
            productId: item.productId,
            skuId: item.skuId,
            supplierId: item.supplierId,
            predictedSales: item.predictedSales,
            currentStock: item.currentStock,
            minimumStock: item.minimumStock,
            inTransit: item.inTransit,
        };
    });

    return {
        id: response.predictionId,
        date: response.predictionDate,
        storeId: response.storeId,
        deliveryDate: response.deliveryDate,
        predictionEndDate: response.predictionEndDate,
        items: items,
    };
};

export const toCarts = (response: OrderPredictionResponse): Cart[] => {
    const carts: { [supplierId: string]: Cart } = {};

    response.items.forEach(item => {
        // Calculate quantity based on predicted sales, current stock, and minimum stock
        const quantity = item.predictedSales -
            (item.currentStock || 0) +
            (item.minimumStock || 0);

        // Create a new CartItem object
        const cartItem = new CartItemImpl(
            item.skuId,
            item.supplierId,
            quantity,
            item.productId,
            {
                predictionId: response.predictionId,
                productId: item.productId,
                supplierId: item.supplierId,
                skuId: item.skuId,
                predictedSales: item.predictedSales,
                currentStock: item.currentStock,
                minimumStock: item.minimumStock,
                inTransit: item.inTransit,
            },
        );

        if (!carts[item.supplierId]) {
            carts[item.supplierId] = new CartImpl(item.supplierId, cartItem);
        } else {
            carts[item.supplierId].addItem(cartItem);
        }
    });

    return Object.values(carts);
};
