/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { OrderItemCreate } from './OrderItemCreate';
// @ts-ignore
import {
// @ts-ignore
    OrderItemCreateFromJSON,
// @ts-ignore
    OrderItemCreateFromJSONTyped,
// @ts-ignore
    OrderItemCreateToJSON,
} from './OrderItemCreate';
// @ts-ignore
import type { ProofOfPayment } from './ProofOfPayment';
// @ts-ignore
import {
// @ts-ignore
    ProofOfPaymentFromJSON,
// @ts-ignore
    ProofOfPaymentFromJSONTyped,
// @ts-ignore
    ProofOfPaymentToJSON,
} from './ProofOfPayment';
// @ts-ignore
import type { TimeWindow } from './TimeWindow';
// @ts-ignore
import {
// @ts-ignore
    TimeWindowFromJSON,
// @ts-ignore
    TimeWindowFromJSONTyped,
// @ts-ignore
    TimeWindowToJSON,
} from './TimeWindow';

/**
 * 
 * @export
 * @interface OrderCreate
 */
export interface OrderCreate {
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    externalCode?: string;
    /**
     * 
     * @type {TimeWindow}
     * @memberof OrderCreate
     */
    deliveryWindow?: TimeWindow;
    /**
     * 
     * @type {TimeWindow}
     * @memberof OrderCreate
     */
    pickupWindow?: TimeWindow;
    /**
     * 
     * @type {Date}
     * @memberof OrderCreate
     */
    pickupWindowCutoffTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    currencyCode: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderCreate
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {ProofOfPayment}
     * @memberof OrderCreate
     */
    proofOfPayment?: ProofOfPayment;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    note: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrderCreate
     */
    customData?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreate
     */
    poNumber?: string;
    /**
     * 
     * @type {Array<OrderItemCreate>}
     * @memberof OrderCreate
     */
    items: Array<OrderItemCreate>;
}

/**
 * Check if a given object implements the OrderCreate interface.
 */
// @ts-ignore
export function instanceOfOrderCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "storeId" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

// @ts-ignore
export function OrderCreateFromJSON(json: any): OrderCreate {
    return OrderCreateFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreate {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'deliveryWindow': !exists(json, 'delivery_window') ? undefined : TimeWindowFromJSON(json['delivery_window']),
        'pickupWindow': !exists(json, 'pickup_window') ? undefined : TimeWindowFromJSON(json['pickup_window']),
        'pickupWindowCutoffTime': !exists(json, 'pickup_window_cutoff_time') ? undefined : (new Date(json['pickup_window_cutoff_time'])),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'currencyCode': json['currency_code'],
        'paymentDueDate': !exists(json, 'payment_due_date') ? undefined : (new Date(json['payment_due_date'])),
        'proofOfPayment': !exists(json, 'proof_of_payment') ? undefined : ProofOfPaymentFromJSON(json['proof_of_payment']),
        'note': json['note'],
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
        'supplierId': json['supplier_id'],
        'warehouseId': !exists(json, 'warehouse_id') ? undefined : json['warehouse_id'],
        'storeId': json['store_id'],
        'poNumber': !exists(json, 'po_number') ? undefined : json['po_number'],
        'items': ((json['items'] as Array<any>).map(OrderItemCreateFromJSON)),
    };
}

// @ts-ignore
export function OrderCreateToJSON(value?: OrderCreate | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_code': value.externalCode,
        'delivery_window': TimeWindowToJSON(value.deliveryWindow),
        'pickup_window': TimeWindowToJSON(value.pickupWindow),
        'pickup_window_cutoff_time': value.pickupWindowCutoffTime === undefined ? undefined : (value.pickupWindowCutoffTime.toISOString()),
        'source': value.source,
        'currency_code': value.currencyCode,
        'payment_due_date': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString()),
        'proof_of_payment': ProofOfPaymentToJSON(value.proofOfPayment),
        'note': value.note,
        'custom_data': value.customData,
        'supplier_id': value.supplierId,
        'warehouse_id': value.warehouseId,
        'store_id': value.storeId,
        'po_number': value.poNumber,
        'items': ((value.items as Array<any>).map(OrderItemCreateToJSON)),
    };
}

