/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { NonWorkingDay } from './NonWorkingDay';
// @ts-ignore
import {
// @ts-ignore
    NonWorkingDayFromJSON,
// @ts-ignore
    NonWorkingDayFromJSONTyped,
// @ts-ignore
    NonWorkingDayToJSON,
} from './NonWorkingDay';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { WarehouseAddress } from './WarehouseAddress';
// @ts-ignore
import {
// @ts-ignore
    WarehouseAddressFromJSON,
// @ts-ignore
    WarehouseAddressFromJSONTyped,
// @ts-ignore
    WarehouseAddressToJSON,
} from './WarehouseAddress';
// @ts-ignore
import type { WarehouseOperatingWindow } from './WarehouseOperatingWindow';
// @ts-ignore
import {
// @ts-ignore
    WarehouseOperatingWindowFromJSON,
// @ts-ignore
    WarehouseOperatingWindowFromJSONTyped,
// @ts-ignore
    WarehouseOperatingWindowToJSON,
} from './WarehouseOperatingWindow';

/**
 * 
 * @export
 * @interface Warehouse
 */
export interface Warehouse {
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    contactNumber: string;
    /**
     * 
     * @type {WarehouseAddress}
     * @memberof Warehouse
     */
    address: WarehouseAddress;
    /**
     * 
     * @type {Status}
     * @memberof Warehouse
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    supplierId?: string;
    /**
     * 
     * @type {Array<WarehouseOperatingWindow>}
     * @memberof Warehouse
     */
    operatingWindows: Array<WarehouseOperatingWindow>;
    /**
     * 
     * @type {Array<NonWorkingDay>}
     * @memberof Warehouse
     */
    nonWorkingDays: Array<NonWorkingDay>;
    /**
     * 
     * @type {Date}
     * @memberof Warehouse
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the Warehouse interface.
 */
// @ts-ignore
export function instanceOfWarehouse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "contactNumber" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "operatingWindows" in value;
    isInstance = isInstance && "nonWorkingDays" in value;

    return isInstance;
}

// @ts-ignore
export function WarehouseFromJSON(json: any): Warehouse {
    return WarehouseFromJSONTyped(json, false);
}

// @ts-ignore
export function WarehouseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Warehouse {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'contactNumber': json['contact_number'],
        'address': WarehouseAddressFromJSON(json['address']),
        'status': StatusFromJSON(json['status']),
        'supplierId': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'operatingWindows': ((json['operating_windows'] as Array<any>).map(WarehouseOperatingWindowFromJSON)),
        'nonWorkingDays': ((json['non_working_days'] as Array<any>).map(NonWorkingDayFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function WarehouseToJSON(value?: Warehouse | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'external_code': value.externalCode,
        'contact_number': value.contactNumber,
        'address': WarehouseAddressToJSON(value.address),
        'status': StatusToJSON(value.status),
        'supplier_id': value.supplierId,
        'operating_windows': ((value.operatingWindows as Array<any>).map(WarehouseOperatingWindowToJSON)),
        'non_working_days': ((value.nonWorkingDays as Array<any>).map(NonWorkingDayToJSON)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

