import CustomersApi from '@/api/CustomersApi';
import {toCustomer} from '@/api/mappers/mapCustomer';
import AbstractApi from '@/api/mocks/AbstractApi';
import {mockCustomersData} from '@/api/mocks/data/customers';
import {mockSupplierToCustomerData} from '@/api/mocks/data/suppliers';
import {calculatePagination} from '@/api/mocks/Pagination';
import {Customer, CustomerCreate, Customers, CustomerUpdate} from '@/models/Customer';

export default class MockCustomersApiImpl extends AbstractApi implements CustomersApi {
    async listCustomers(offset: number = 0, limit: number = 10): Promise<Customers> {
        await this.sleep();
        return calculatePagination(mockCustomersData.map(toCustomer), offset, limit);
    }

    async findCustomers(query?: string, offset: number = 0, limit: number = 10): Promise<Customers> {
        await this.sleep();
        let items = [...mockCustomersData];
        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(customer => customer.name.toLowerCase().includes(queryLowerCase));
        }
        return calculatePagination(items.map(toCustomer), offset, limit);
    }

    async createCustomer(_customer: CustomerCreate): Promise<void> {
        await this.sleep();
    }

    async getCustomer(id: string): Promise<Customer | undefined> {
        await this.sleep();
        const customer = mockCustomersData.find(customer => customer.id === id);
        if (customer) {
            return toCustomer(customer);
        }
        return undefined;
    }

    async updateCustomer(_id: string, _customer: CustomerUpdate): Promise<void> {
        await this.sleep();
    }

    async deleteCustomer(_id: string): Promise<void> {
        await this.sleep();
    }

    async listCustomersBySupplier(supplierId: string, offset: number = 0, limit: number = 10): Promise<Customers> {
        await this.sleep();
        let items = [...mockCustomersData];
        items = items.filter(customer => {
            return mockSupplierToCustomerData.some(item => item.supplierId === supplierId && item.customerId === customer.id);
        });
        return calculatePagination(items.map(toCustomer), offset, limit);
    }

    async findCustomersBySupplier(supplierId: string, query?: string, offset: number = 0, limit: number = 10): Promise<Customers> {
        await this.sleep();
        let items = [...mockCustomersData];
        items = items.filter(customer => {
            return mockSupplierToCustomerData.some(item => item.supplierId === supplierId && item.customerId === customer.id);
        });
        if (query) {
            const queryLowerCase = query.toLowerCase();
            items = items.filter(customer => customer.name.toLowerCase().includes(queryLowerCase));
        }
        return calculatePagination(items.map(toCustomer), offset, limit);
    }
}
