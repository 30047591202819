import {toStatus} from '@/api/mappers/mapStatus';
import {Promotion as ApiPromotion, PromotionDiscountTypeEnum} from '@/gen/api';
import {DiscountType, Promotion as PromotionModel} from '@/models/Promotion';

const toDiscountType = (apiDiscountType: PromotionDiscountTypeEnum): DiscountType => {
    switch (apiDiscountType) {
        case 'fixed_amount':
            return DiscountType.FixedAmount;
        case 'percentage':
            return DiscountType.Percentage;
    }
};

export const toPromotion = (apiPromotion: ApiPromotion): PromotionModel => {
    return {
        ...apiPromotion,
        discountType: toDiscountType(apiPromotion.discountType),
        status: apiPromotion.status ? toStatus(apiPromotion.status) : 'UNKNOWN',
    };
};
