/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrRemoveStoresToSupplier
 */
export interface AddOrRemoveStoresToSupplier {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddOrRemoveStoresToSupplier
     */
    storeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddOrRemoveStoresToSupplier
     */
    warehouseId: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrRemoveStoresToSupplier
     */
    operation: AddOrRemoveStoresToSupplierOperationEnum;
}


/**
 * @export
 */
export const AddOrRemoveStoresToSupplierOperationEnum = {
    Add: 'add',
    Remove: 'remove'
} as const;
export type AddOrRemoveStoresToSupplierOperationEnum = typeof AddOrRemoveStoresToSupplierOperationEnum[keyof typeof AddOrRemoveStoresToSupplierOperationEnum];


/**
 * Check if a given object implements the AddOrRemoveStoresToSupplier interface.
 */
// @ts-ignore
export function instanceOfAddOrRemoveStoresToSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storeIds" in value;
    isInstance = isInstance && "warehouseId" in value;
    isInstance = isInstance && "operation" in value;

    return isInstance;
}

// @ts-ignore
export function AddOrRemoveStoresToSupplierFromJSON(json: any): AddOrRemoveStoresToSupplier {
    return AddOrRemoveStoresToSupplierFromJSONTyped(json, false);
}

// @ts-ignore
export function AddOrRemoveStoresToSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrRemoveStoresToSupplier {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeIds': json['store_ids'],
        'warehouseId': json['warehouse_id'],
        'operation': json['operation'],
    };
}

// @ts-ignore
export function AddOrRemoveStoresToSupplierToJSON(value?: AddOrRemoveStoresToSupplier | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'store_ids': value.storeIds,
        'warehouse_id': value.warehouseId,
        'operation': value.operation,
    };
}

