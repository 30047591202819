/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    confirmPassword: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    mobileCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    businessName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    street1: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    street2: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    countryCode: string;
}

/**
 * Check if a given object implements the SignUpRequest interface.
 */
// @ts-ignore
export function instanceOfSignUpRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "confirmPassword" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "businessName" in value;
    isInstance = isInstance && "street1" in value;
    isInstance = isInstance && "street2" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

// @ts-ignore
export function SignUpRequestFromJSON(json: any): SignUpRequest {
    return SignUpRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SignUpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'confirmPassword': json['confirm_password'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'mobileCountryCode': !exists(json, 'mobile_country_code') ? undefined : json['mobile_country_code'],
        'mobileNumber': !exists(json, 'mobile_number') ? undefined : json['mobile_number'],
        'role': json['role'],
        'businessName': json['business_name'],
        'street1': json['street1'],
        'street2': json['street2'],
        'city': json['city'],
        'countryCode': json['country_code'],
    };
}

// @ts-ignore
export function SignUpRequestToJSON(value?: SignUpRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'confirm_password': value.confirmPassword,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'mobile_country_code': value.mobileCountryCode,
        'mobile_number': value.mobileNumber,
        'role': value.role,
        'business_name': value.businessName,
        'street1': value.street1,
        'street2': value.street2,
        'city': value.city,
        'country_code': value.countryCode,
    };
}

