/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  ProductCategory,
// @ts-ignore
  ProductCategoryCreateRequest,
// @ts-ignore
  ProductCategoryListResponse,
// @ts-ignore
  ProductCategoryUpdateRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    ProductCategoryFromJSON,
// @ts-ignore
    ProductCategoryToJSON,
// @ts-ignore
    ProductCategoryCreateRequestFromJSON,
// @ts-ignore
    ProductCategoryCreateRequestToJSON,
// @ts-ignore
    ProductCategoryListResponseFromJSON,
// @ts-ignore
    ProductCategoryListResponseToJSON,
// @ts-ignore
    ProductCategoryUpdateRequestFromJSON,
// @ts-ignore
    ProductCategoryUpdateRequestToJSON,
} from '../models/index';

export interface CreateProductCategoryRequest {
    productCategoryCreateRequest: ProductCategoryCreateRequest;
}

export interface DeleteProductCategoryRequest {
    id: string;
}

export interface GetProductCategoryRequest {
    id: string;
}

export interface ListProductCategoriesRequest {
    query?: string;
    offset?: number;
    limit?: number;
    supplierId?: Array<string>;
}

export interface UpdateProductCategoryRequest {
    id: string;
    productCategoryUpdateRequest: ProductCategoryUpdateRequest;
}

/**
 * 
 */
export class ProductCategoriesApi extends runtime.BaseAPI {

    /**
     * Create a new product category
     */
    async createProductCategoryRaw(requestParameters: CreateProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCategoryCreateRequest === null || requestParameters.productCategoryCreateRequest === undefined) {
            throw new runtime.RequiredError('productCategoryCreateRequest','Required parameter requestParameters.productCategoryCreateRequest was null or undefined when calling createProductCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCategoryCreateRequestToJSON(requestParameters.productCategoryCreateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new product category
     */
    async createProductCategory(requestParameters: CreateProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createProductCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a product category by ID
     */
    async deleteProductCategoryRaw(requestParameters: DeleteProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProductCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a product category by ID
     */
    async deleteProductCategory(requestParameters: DeleteProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Get details of a specific product category
     */
    async getProductCategoryRaw(requestParameters: GetProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCategoryFromJSON(jsonValue));
    }

    /**
     * Get details of a specific product category
     */
    async getProductCategory(requestParameters: GetProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCategory> {
        const response = await this.getProductCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of product categories
     */
    async listProductCategoriesRaw(requestParameters: ListProductCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCategoryListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.supplierId) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCategoryListResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of product categories
     */
    async listProductCategories(requestParameters: ListProductCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCategoryListResponse> {
        const response = await this.listProductCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a product category by ID
     */
    async updateProductCategoryRaw(requestParameters: UpdateProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProductCategory.');
        }

        if (requestParameters.productCategoryUpdateRequest === null || requestParameters.productCategoryUpdateRequest === undefined) {
            throw new runtime.RequiredError('productCategoryUpdateRequest','Required parameter requestParameters.productCategoryUpdateRequest was null or undefined when calling updateProductCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/products/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCategoryUpdateRequestToJSON(requestParameters.productCategoryUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a product category by ID
     */
    async updateProductCategory(requestParameters: UpdateProductCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProductCategoryRaw(requestParameters, initOverrides);
    }

}
