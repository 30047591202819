/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface DiscountFixedAmount
 */
export interface DiscountFixedAmount {
    /**
     * 
     * @type {Amount}
     * @memberof DiscountFixedAmount
     */
    amount: Amount;
    /**
     * 
     * @type {number}
     * @memberof DiscountFixedAmount
     */
    quantityFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountFixedAmount
     */
    quantityTo?: number;
}

/**
 * Check if a given object implements the DiscountFixedAmount interface.
 */
// @ts-ignore
export function instanceOfDiscountFixedAmount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

// @ts-ignore
export function DiscountFixedAmountFromJSON(json: any): DiscountFixedAmount {
    return DiscountFixedAmountFromJSONTyped(json, false);
}

// @ts-ignore
export function DiscountFixedAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountFixedAmount {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': AmountFromJSON(json['amount']),
        'quantityFrom': !exists(json, 'quantity_from') ? undefined : json['quantity_from'],
        'quantityTo': !exists(json, 'quantity_to') ? undefined : json['quantity_to'],
    };
}

// @ts-ignore
export function DiscountFixedAmountToJSON(value?: DiscountFixedAmount | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': AmountToJSON(value.amount),
        'quantity_from': value.quantityFrom,
        'quantity_to': value.quantityTo,
    };
}

