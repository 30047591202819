export enum CalendarEventType {
    HOLIDAY = 'HOLIDAY',
    NON_WORKING_DAY = 'NON_WORKING_DAY',
    DEFAULT = 'DEFAULT',
}

export const CalendarEventTypeTitles: Record<CalendarEventType, string> = {
    [CalendarEventType.HOLIDAY]: 'Holiday',
    [CalendarEventType.NON_WORKING_DAY]: 'Non-Working Day',
    [CalendarEventType.DEFAULT]: '',
};

export type CalendarEventFrequency = 'day' | 'week' | 'month' | 'year'

export interface CalendarEvent {
    id: string;
    calendarId: string;
    name: string;
    type: CalendarEventType;
    start: Date;
    end: Date;
    frequency?: CalendarEventFrequency;
    interval?: number;
    lastDayOfOccurrence: Date;
    occurrence?: CalendarEventOccurrence;
    numberOfOccurrences: number;
    createdAt: Date;
}

export interface CalendarEventOccurrence {
    day?: number; // 1 to 31
    month?: number; // 1 to 12 (from january to december)
    year?: number;
    dayOfWeek?: number; // 1 to 7 (from monday to sunday)
    dayOfWeekInMonth?: number; // 1 to 5 (the nth occurrence of the specified weekday within the month)
    firstDayOfWeekInMonth?: number; // 1 to 7 (from Monday to Sunday) representing the first occurrence of the specified weekday within the month
    lastDayOfWeekInMonth?: number; // 1 to 7 (from Monday to Sunday) representing the last occurrence of the specified weekday within the month
}

export interface CalendarEvents {
    items: CalendarEvent[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export interface CalendarEventCreate {
    calendarId: string;
    name: string;
    type: CalendarEventType;
    start: Date;
    end: Date;
    frequency?: CalendarEventFrequency;
    interval?: number;
    occurrence?: CalendarEventOccurrence;
}

export interface CalendarEventUpdate {
    name: string;
    type: CalendarEventType;
    start: Date;
    end: Date;
    frequency?: CalendarEventFrequency;
    interval?: number;
    occurrence?: CalendarEventOccurrence;
}
