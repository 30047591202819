/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { StoreAddress } from './StoreAddress';
// @ts-ignore
import {
// @ts-ignore
    StoreAddressFromJSON,
// @ts-ignore
    StoreAddressFromJSONTyped,
// @ts-ignore
    StoreAddressToJSON,
} from './StoreAddress';
// @ts-ignore
import type { StoreReceivingWindow } from './StoreReceivingWindow';
// @ts-ignore
import {
// @ts-ignore
    StoreReceivingWindowFromJSON,
// @ts-ignore
    StoreReceivingWindowFromJSONTyped,
// @ts-ignore
    StoreReceivingWindowToJSON,
} from './StoreReceivingWindow';

/**
 * 
 * @export
 * @interface StoreCreateRequest
 */
export interface StoreCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreCreateRequest
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateRequest
     */
    externalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateRequest
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<StoreAddress>}
     * @memberof StoreCreateRequest
     */
    addresses?: Array<StoreAddress>;
    /**
     * 
     * @type {Array<StoreReceivingWindow>}
     * @memberof StoreCreateRequest
     */
    receivingWindows?: Array<StoreReceivingWindow>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoreCreateRequest
     */
    customData?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoreCreateRequest
     */
    dynamicFields?: { [key: string]: string; };
}

/**
 * Check if a given object implements the StoreCreateRequest interface.
 */
// @ts-ignore
export function instanceOfStoreCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

// @ts-ignore
export function StoreCreateRequestFromJSON(json: any): StoreCreateRequest {
    return StoreCreateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function StoreCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreCreateRequest {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'name': json['name'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'contactNumber': !exists(json, 'contact_number') ? undefined : json['contact_number'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'addresses': !exists(json, 'addresses') ? undefined : ((json['addresses'] as Array<any>).map(StoreAddressFromJSON)),
        'receivingWindows': !exists(json, 'receiving_windows') ? undefined : ((json['receiving_windows'] as Array<any>).map(StoreReceivingWindowFromJSON)),
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
        'dynamicFields': !exists(json, 'dynamic_fields') ? undefined : json['dynamic_fields'],
    };
}

// @ts-ignore
export function StoreCreateRequestToJSON(value?: StoreCreateRequest | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer_id': value.customerId,
        'name': value.name,
        'external_code': value.externalCode,
        'contact_number': value.contactNumber,
        'description': value.description,
        'addresses': value.addresses === undefined ? undefined : ((value.addresses as Array<any>).map(StoreAddressToJSON)),
        'receiving_windows': value.receivingWindows === undefined ? undefined : ((value.receivingWindows as Array<any>).map(StoreReceivingWindowToJSON)),
        'custom_data': value.customData,
        'dynamic_fields': value.dynamicFields,
    };
}

