/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Amount } from './Amount';
// @ts-ignore
import {
// @ts-ignore
    AmountFromJSON,
// @ts-ignore
    AmountFromJSONTyped,
// @ts-ignore
    AmountToJSON,
} from './Amount';
// @ts-ignore
import type { PriceList } from './PriceList';
// @ts-ignore
import {
// @ts-ignore
    PriceListFromJSON,
// @ts-ignore
    PriceListFromJSONTyped,
// @ts-ignore
    PriceListToJSON,
} from './PriceList';
// @ts-ignore
import type { Sku } from './Sku';
// @ts-ignore
import {
// @ts-ignore
    SkuFromJSON,
// @ts-ignore
    SkuFromJSONTyped,
// @ts-ignore
    SkuToJSON,
} from './Sku';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';
// @ts-ignore
import type { Supplier } from './Supplier';
// @ts-ignore
import {
// @ts-ignore
    SupplierFromJSON,
// @ts-ignore
    SupplierFromJSONTyped,
// @ts-ignore
    SupplierToJSON,
} from './Supplier';

/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    supplierId: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    priceListId?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    skuId: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    storeId?: string;
    /**
     * 
     * @type {Amount}
     * @memberof Price
     */
    price: Amount;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    quantityFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    quantityTo?: number;
    /**
     * 
     * @type {Status}
     * @memberof Price
     */
    status: Status;
    /**
     * 
     * @type {Date}
     * @memberof Price
     */
    createdAt: Date;
    /**
     * 
     * @type {PriceList}
     * @memberof Price
     */
    priceList?: PriceList;
    /**
     * 
     * @type {Sku}
     * @memberof Price
     */
    sku?: Sku;
    /**
     * 
     * @type {Supplier}
     * @memberof Price
     */
    supplier?: Supplier;
}

/**
 * Check if a given object implements the Price interface.
 */
// @ts-ignore
export function instanceOfPrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "skuId" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function PriceFromJSON(json: any): Price {
    return PriceFromJSONTyped(json, false);
}

// @ts-ignore
export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'supplierId': json['supplier_id'],
        'priceListId': !exists(json, 'price_list_id') ? undefined : json['price_list_id'],
        'skuId': json['sku_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'price': AmountFromJSON(json['price']),
        'quantityFrom': !exists(json, 'quantity_from') ? undefined : json['quantity_from'],
        'quantityTo': !exists(json, 'quantity_to') ? undefined : json['quantity_to'],
        'status': StatusFromJSON(json['status']),
        'createdAt': (new Date(json['created_at'])),
        'priceList': !exists(json, 'price_list') ? undefined : PriceListFromJSON(json['price_list']),
        'sku': !exists(json, 'sku') ? undefined : SkuFromJSON(json['sku']),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
    };
}

// @ts-ignore
export function PriceToJSON(value?: Price | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'supplier_id': value.supplierId,
        'price_list_id': value.priceListId,
        'sku_id': value.skuId,
        'customer_id': value.customerId,
        'store_id': value.storeId,
        'price': AmountToJSON(value.price),
        'quantity_from': value.quantityFrom,
        'quantity_to': value.quantityTo,
        'status': StatusToJSON(value.status),
        'created_at': (value.createdAt.toISOString()),
        'price_list': PriceListToJSON(value.priceList),
        'sku': SkuToJSON(value.sku),
        'supplier': SupplierToJSON(value.supplier),
    };
}

