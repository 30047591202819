/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageSize
 */
export interface ImageSize {
    /**
     * URL of the image
     * @type {string}
     * @memberof ImageSize
     */
    url: string;
    /**
     * Width of the image in pixels
     * @type {number}
     * @memberof ImageSize
     */
    width: number;
    /**
     * Height of the image in pixels
     * @type {number}
     * @memberof ImageSize
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof ImageSize
     */
    type: ImageSizeTypeEnum;
}


/**
 * @export
 */
export const ImageSizeTypeEnum = {
    Thumbnail: 'thumbnail',
    Original: 'original',
    Resized: 'resized'
} as const;
export type ImageSizeTypeEnum = typeof ImageSizeTypeEnum[keyof typeof ImageSizeTypeEnum];


/**
 * Check if a given object implements the ImageSize interface.
 */
// @ts-ignore
export function instanceOfImageSize(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

// @ts-ignore
export function ImageSizeFromJSON(json: any): ImageSize {
    return ImageSizeFromJSONTyped(json, false);
}

// @ts-ignore
export function ImageSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageSize {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'width': json['width'],
        'height': json['height'],
        'type': json['type'],
    };
}

// @ts-ignore
export function ImageSizeToJSON(value?: ImageSize | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'width': value.width,
        'height': value.height,
        'type': value.type,
    };
}

