export interface PickupWindow {
    cutoffTime: Date;
    start: Date;
    end: Date;

    equals(other: PickupWindow): boolean;
}

export class PickupWindowImpl implements PickupWindow {
    readonly cutoffTime: Date;
    readonly start: Date;
    readonly end: Date;

    constructor(cutoffTime: Date, start: Date, end: Date) {
        this.cutoffTime = cutoffTime;
        this.start = start;
        this.end = end;
    }

    equals(other: PickupWindow): boolean {
        return (
            this.cutoffTime.getTime() === other.cutoffTime.getTime() &&
            this.start.getTime() === other.start.getTime() &&
            this.end.getTime() === other.end.getTime()
        );
    }

    static fromObject(obj: { cutoffTime: Date | string; start: Date | string; end: Date | string }): PickupWindowImpl {
        const cutoffTime = typeof obj.cutoffTime === 'string' ? new Date(obj.cutoffTime) : obj.cutoffTime;
        const start = typeof obj.start === 'string' ? new Date(obj.start) : obj.start;
        const end = typeof obj.end === 'string' ? new Date(obj.end) : obj.end;
        return new PickupWindowImpl(cutoffTime, start, end);
    }
}
