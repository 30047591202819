/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  ChangePasswordRequest,
// @ts-ignore
  LoginRequest,
// @ts-ignore
  LoginResult,
// @ts-ignore
  RefreshTokenRequest,
// @ts-ignore
  SignUpRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    ChangePasswordRequestFromJSON,
// @ts-ignore
    ChangePasswordRequestToJSON,
// @ts-ignore
    LoginRequestFromJSON,
// @ts-ignore
    LoginRequestToJSON,
// @ts-ignore
    LoginResultFromJSON,
// @ts-ignore
    LoginResultToJSON,
// @ts-ignore
    RefreshTokenRequestFromJSON,
// @ts-ignore
    RefreshTokenRequestToJSON,
// @ts-ignore
    SignUpRequestFromJSON,
// @ts-ignore
    SignUpRequestToJSON,
} from '../models/index';

export interface ChangePasswordOperationRequest {
    id: string;
    changePasswordRequest: ChangePasswordRequest;
}

export interface LoginOperationRequest {
    loginRequest: LoginRequest;
}

export interface RefreshTokenOperationRequest {
    refreshTokenRequest: RefreshTokenRequest;
}

export interface SignUpOperationRequest {
    signUpRequest: SignUpRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Change password
     */
    async changePasswordRaw(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePassword.');
        }

        if (requestParameters.changePasswordRequest === null || requestParameters.changePasswordRequest === undefined) {
            throw new runtime.RequiredError('changePasswordRequest','Required parameter requestParameters.changePasswordRequest was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/change-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters.changePasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change password
     */
    async changePassword(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Authenticate user and obtain tokens
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResult>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultFromJSON(jsonValue));
    }

    /**
     * Authenticate user and obtain tokens
     */
    async login(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResult> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh access tokens using refresh token
     */
    async refreshTokenRaw(requestParameters: RefreshTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResult>> {
        if (requestParameters.refreshTokenRequest === null || requestParameters.refreshTokenRequest === undefined) {
            throw new runtime.RequiredError('refreshTokenRequest','Required parameter requestParameters.refreshTokenRequest was null or undefined when calling refreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.refreshTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultFromJSON(jsonValue));
    }

    /**
     * Refresh access tokens using refresh token
     */
    async refreshToken(requestParameters: RefreshTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResult> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign up a new user
     */
    async signUpRaw(requestParameters: SignUpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signUpRequest === null || requestParameters.signUpRequest === undefined) {
            throw new runtime.RequiredError('signUpRequest','Required parameter requestParameters.signUpRequest was null or undefined when calling signUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpRequestToJSON(requestParameters.signUpRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sign up a new user
     */
    async signUp(requestParameters: SignUpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signUpRaw(requestParameters, initOverrides);
    }

}
