import {mapDayOfTheWeek, toApiDayOfTheWeek} from '@/api/mappers/mapDayOfTheWeek';
import {toApiStatus, toStatus} from '@/api/mappers/mapStatus';
import {
    Warehouse as ApiWarehouse,
    WarehouseOperatingWindow as ApiWarehouseOperatingWindow,
} from '@/gen/api';
import {formatTime, TimeImpl} from '@/models/Time';
import {
    Warehouse as WarehouseModel,
    WarehouseOperatingWindow as WarehouseOperatingWindowModel,
} from '@/models/Warehouse';

const toOperatingWindows = (apiStoreOperatingWindows: ApiWarehouseOperatingWindow[]): WarehouseOperatingWindowModel[] => {
    const operatingWindows: (WarehouseOperatingWindowModel | undefined)[] = apiStoreOperatingWindows.map((apiWarehouseOperatingWindow) => {
        const day = mapDayOfTheWeek(apiWarehouseOperatingWindow.day);
        const startTime = TimeImpl.fromString(apiWarehouseOperatingWindow.startTime);
        const endTime = TimeImpl.fromString(apiWarehouseOperatingWindow.endTime);
        const cutoffTime = TimeImpl.fromString(apiWarehouseOperatingWindow.cutoffTime);
        if (!day || !startTime || !endTime || !cutoffTime) {
            return undefined;
        }
        return {
            day: day,
            startTime: startTime,
            endTime: endTime,
            cutoffTime: cutoffTime,
        };
    });

    return operatingWindows.filter((item) => item !== undefined) as WarehouseOperatingWindowModel[];
};

const toApiOperatingWindow = (operatingWindow: WarehouseOperatingWindowModel): ApiWarehouseOperatingWindow => {
    return {
        ...operatingWindow,
        day: toApiDayOfTheWeek(operatingWindow.day),
        startTime: formatTime(operatingWindow.startTime),
        endTime: formatTime(operatingWindow.endTime),
        cutoffTime: formatTime(operatingWindow.cutoffTime),
    };
};

export const toWarehouse = (apiWarehouse: ApiWarehouse): WarehouseModel => {
    return {
        ...apiWarehouse,
        id: apiWarehouse.id as string,
        status: toStatus(apiWarehouse.status),
        createdAt: apiWarehouse.createdAt || new Date(0),
        operatingWindows: toOperatingWindows(apiWarehouse.operatingWindows),
    };
};

export const toApiWarehouse = (warehouse: WarehouseModel): ApiWarehouse => {
    return {
        ...warehouse,
        status: toApiStatus(warehouse.status),
        operatingWindows: warehouse.operatingWindows.map(toApiOperatingWindow),
        address: {
            ...warehouse.address,
            warehouseId: warehouse.address.warehouseId || warehouse.id || '',
        },
    };
};
