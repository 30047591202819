import {toApiStatus} from '@/api/mappers/mapStatus';
import {Product as ApiProduct} from '@/gen/api';
import {Product} from '@/models/Product';

export function toProduct(apiProduct: ApiProduct): Product {
    return {
        ...apiProduct,
    };
}

export function toApiProduct(product: Product): ApiProduct {
    return {
        ...product,
        status: toApiStatus(product.status),
    };
}
