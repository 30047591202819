/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { DayOfTheWeek } from './DayOfTheWeek';
// @ts-ignore
import {
// @ts-ignore
    DayOfTheWeekFromJSON,
// @ts-ignore
    DayOfTheWeekFromJSONTyped,
// @ts-ignore
    DayOfTheWeekToJSON,
} from './DayOfTheWeek';

/**
 * 
 * @export
 * @interface StoreReceivingWindow
 */
export interface StoreReceivingWindow {
    /**
     * 
     * @type {string}
     * @memberof StoreReceivingWindow
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreReceivingWindow
     */
    storeId?: string;
    /**
     * 
     * @type {DayOfTheWeek}
     * @memberof StoreReceivingWindow
     */
    day: DayOfTheWeek;
    /**
     * 
     * @type {string}
     * @memberof StoreReceivingWindow
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof StoreReceivingWindow
     */
    endTime: string;
}

/**
 * Check if a given object implements the StoreReceivingWindow interface.
 */
// @ts-ignore
export function instanceOfStoreReceivingWindow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "day" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;

    return isInstance;
}

// @ts-ignore
export function StoreReceivingWindowFromJSON(json: any): StoreReceivingWindow {
    return StoreReceivingWindowFromJSONTyped(json, false);
}

// @ts-ignore
export function StoreReceivingWindowFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreReceivingWindow {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'storeId': !exists(json, 'store_id') ? undefined : json['store_id'],
        'day': DayOfTheWeekFromJSON(json['day']),
        'startTime': json['start_time'],
        'endTime': json['end_time'],
    };
}

// @ts-ignore
export function StoreReceivingWindowToJSON(value?: StoreReceivingWindow | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'store_id': value.storeId,
        'day': DayOfTheWeekToJSON(value.day),
        'start_time': value.startTime,
        'end_time': value.endTime,
    };
}

