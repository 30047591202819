import Configuration from '@/api/Configuration';
import {toApiSkuCreateRequest, toApiSkuUpdateRequest, toSku, toSkus} from '@/api/mappers/mapSku';
import {toApiStatus} from '@/api/mappers/mapStatus';
import Settings from '@/api/Settings';
import {
    Status as ApiStatus,
    BulkUpdateSkusRequest,
    CreateSkuRequest,
    DeleteSkuRequest,
    GetSkuRequest,
    ListSkusRequest, SKUsApi,
    UpdateSkuRequest,
} from '@/gen/api';
import {Image, ImageType} from '@/models/Image';
import {Sku, SkuAssociation, SkuCreate, Skus, SkuUpdate} from '@/models/Sku';

export interface UploadProgress {
    uploadedSize: number;
    totalSize: number;
    percentage: number;
}

export type ProgressCallback = (progress: UploadProgress) => void;

export interface SkuFilters {
    supplierId: string;
    productId: string;
    warehouseId: string;
    storeId: string;
    productCategoryId: string | string[];
    productListId: string | string[];
    promotions: boolean;
}

interface SkusApi {
    listSkus(
        offset?: number,
        limit?: number,
        include?: SkuAssociation[],
    ): Promise<Skus>;
    findSkus(
        query?: string,
        filters?: Partial<SkuFilters>,
        offset?: number,
        limit?: number,
        include?: SkuAssociation[],
    ): Promise<Skus>;
    createSku(sku: SkuCreate): Promise<Sku | undefined>;
    deleteSku(id: string): Promise<void>;
    getSku(id: string, include?: SkuAssociation[]): Promise<Sku | undefined>;
    getSkus(ids: string[], include?: SkuAssociation[]): Promise<Sku[]>;
    updateSku(id: string, sku: SkuUpdate): Promise<Sku | undefined>;
    bulkUpdateSkus(supplierId: string, skus: (SkuCreate | SkuUpdate)[] | File): Promise<void>;
    uploadSkuImage(id: string, file: File, progressCallback: ProgressCallback): Promise<Image>;
}

export class SkusApiImpl implements SkusApi {
    private configuration: Configuration;
    private skusApi: SKUsApi;
    private settings: Settings | undefined;

    constructor(configuration: Configuration, settings?: Settings) {
        this.configuration = configuration;
        this.skusApi = new SKUsApi(configuration);
        this.settings = settings;
    }

    async listSkus(offset?: number, limit?: number, include?: SkuAssociation[]): Promise<Skus> {
        const requestParams: ListSkusRequest = {
            offset,
            limit,
            include: include?.map(value => value.toString()),
            currencyCode: this.settings?.currencyCode,
            languageCode: this.settings?.languageCode,
        };

        try {
            const response = await this.skusApi.listSkus(requestParams);
            return toSkus(response);
        } catch (error) {
            console.error('Error listing SKUs:', error);
            throw error;
        }
    }

    async findSkus(query?: string, filters?: Partial<SkuFilters>, offset?: number, limit?: number, include?: SkuAssociation[]): Promise<Skus> {
        const requestParams: ListSkusRequest = {
            query,
            offset,
            limit,
            include: include?.map(value => value.toString()),
            currencyCode: this.settings?.currencyCode,
            languageCode: this.settings?.languageCode,
            ...filters,
        };

        try {
            const response = await this.skusApi.listSkus(requestParams);
            return toSkus(response);
        } catch (error) {
            console.error('Error finding SKUs:', error);
            throw error;
        }
    }

    async getSku(id: string, include?: SkuAssociation[]): Promise<Sku | undefined> {
        const requestParams: GetSkuRequest = {
            id: id.toString(),
            include: include?.map(value => value.toString()),
            currencyCode: this.settings?.currencyCode,
            languageCode: this.settings?.languageCode,
        };

        try {
            const response = await this.skusApi.getSku(requestParams);
            return toSku(response);
        } catch (error) {
            console.error('Error getting SKU:', error);
            return undefined;
        }
    }

    async getSkus(ids: string[], include?: SkuAssociation[]): Promise<Sku[]> {
        if (ids.length === 0) {
            return [];
        }

        const requestParams: ListSkusRequest = {
            include: include?.map(value => value.toString()),
            limit: ids.length,
            skuId: ids,
            currencyCode: this.settings?.currencyCode,
            languageCode: this.settings?.languageCode,
        };

        try {
            const response = await this.skusApi.listSkus(requestParams);
            return response.items.map(toSku);
        } catch (error) {
            console.error('Error listing SKUs:', error);
            return [];
        }
    }

    async createSku(sku: SkuCreate): Promise<Sku | undefined> {
        const requestParams: CreateSkuRequest = {
            skuCreateRequest: toApiSkuCreateRequest(sku),
        };

        try {
            const response = await this.skusApi.createSku(requestParams);
            return toSku(response);
        } catch (error) {
            console.error('Error creating SKU:', error);
            return undefined;
        }
    }

    async updateSku(id: string, sku: SkuUpdate): Promise<Sku | undefined> {
        const requestParams: UpdateSkuRequest = {
            id,
            skuUpdateRequest: toApiSkuUpdateRequest(sku),
        };

        try {
            const response = await this.skusApi.updateSku(requestParams);
            return toSku(response);
        } catch (error) {
            console.error('Error updating SKU:', error);
            return undefined;
        }
    }

    async deleteSku(id: string): Promise<void> {
        const requestParams: DeleteSkuRequest = {
            id,
        };

        try {
            await this.skusApi.deleteSku(requestParams);
        } catch (error) {
            console.error('Error deleting SKU:', error);
            return;
        }
    }

    uploadSkuImage(_id: string, _file: File, _progressCallback: ProgressCallback): Promise<Image> {
        return Promise.resolve({
            url: '',
            width: 0,
            height: 0,
            type: ImageType.Original,
            sizes: [],
        });
    }

    async bulkUpdateSkus(supplierId: string, skus: (SkuCreate | SkuUpdate)[] | File): Promise<void> {
        if (skus instanceof File) {
            const requestParams = {
                supplierId,
                bulkSkuUpdateRequest: {
                    supplierId,
                    skus: [],
                },
            };

            const contentTypeMap: Record<string, string> = {
                'text/csv': 'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            };

            const contentType = contentTypeMap[skus.type] || '';

            try {
                await this.skusApi.bulkUpdateSkus(requestParams, {
                    body: skus,
                    headers: {
                        ...this.configuration.headers,
                        'Content-Type': contentType,
                    },
                });
            } catch (error) {
                console.error('Error uploading file for SKU bulk update:', error);
            }
            return;
        }

        const requestParams: BulkUpdateSkusRequest = {
            bulkSkuUpdateRequest: {
                supplierId,
                skus: skus.map(sku => ({
                    id: '',
                    ...sku,
                    status: sku.status ? toApiStatus(sku.status) : ApiStatus.Active,
                    product: sku.product ? {
                        ...sku.product,
                        status: sku.product.status ? toApiStatus(sku.product.status) : ApiStatus.Active,
                    } : undefined,
                })),
            },
            supplierId,
        };

        try {
            await this.skusApi.bulkUpdateSkus(requestParams);
        } catch (error) {
            console.error('Error updating SKUs:', error);
        }
    }
}

export default SkusApi;
