/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    entityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    actionById?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    actionByName?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    actionByRole?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    readAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Notification interface.
 */
// @ts-ignore
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

// @ts-ignore
export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

// @ts-ignore
export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'entityId': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entityCode': !exists(json, 'entity_code') ? undefined : json['entity_code'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionById': !exists(json, 'action_by_id') ? undefined : json['action_by_id'],
        'actionByName': !exists(json, 'action_by_name') ? undefined : json['action_by_name'],
        'actionByRole': !exists(json, 'action_by_role') ? undefined : json['action_by_role'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'readAt': !exists(json, 'read_at') ? undefined : (new Date(json['read_at'])),
        'createdAt': (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function NotificationToJSON(value?: Notification | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'type': value.type,
        'entity': value.entity,
        'entity_id': value.entityId,
        'entity_code': value.entityCode,
        'status': value.status,
        'action': value.action,
        'action_by_id': value.actionById,
        'action_by_name': value.actionByName,
        'action_by_role': value.actionByRole,
        'user_id': value.userId,
        'read_at': value.readAt === undefined ? undefined : (value.readAt.toISOString()),
        'created_at': (value.createdAt.toISOString()),
    };
}

