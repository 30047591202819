import {toStatus} from '@/api/mappers/mapStatus';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {PriceList as ApiPriceList} from '@/gen/api';
import {PriceList as PriceListModel} from '@/models/PriceList';

export const toPriceList = (apiPriceList: ApiPriceList): PriceListModel => {
    return {
        ...apiPriceList,
        status: apiPriceList.status ? toStatus(apiPriceList.status) : 'UNKNOWN',
        supplier: apiPriceList.supplier ? toSupplier(apiPriceList.supplier) : undefined,
    };
};
