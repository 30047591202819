/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NonWorkingDay
 */
export interface NonWorkingDay {
    /**
     * 
     * @type {string}
     * @memberof NonWorkingDay
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof NonWorkingDay
     */
    date: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NonWorkingDay
     */
    isPublicHoliday: boolean;
    /**
     * 
     * @type {string}
     * @memberof NonWorkingDay
     */
    calendarEventId?: string;
}

/**
 * Check if a given object implements the NonWorkingDay interface.
 */
// @ts-ignore
export function instanceOfNonWorkingDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "isPublicHoliday" in value;

    return isInstance;
}

// @ts-ignore
export function NonWorkingDayFromJSON(json: any): NonWorkingDay {
    return NonWorkingDayFromJSONTyped(json, false);
}

// @ts-ignore
export function NonWorkingDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonWorkingDay {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'date': (new Date(json['date'])),
        'isPublicHoliday': json['is_public_holiday'],
        'calendarEventId': !exists(json, 'calendar_event_id') ? undefined : json['calendar_event_id'],
    };
}

// @ts-ignore
export function NonWorkingDayToJSON(value?: NonWorkingDay | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'date': (value.date.toISOString().substring(0,10)),
        'is_public_holiday': value.isPublicHoliday,
        'calendar_event_id': value.calendarEventId,
    };
}

