import {toCarts, toOrderPrediction} from '@/api/mappers/mapOrderPrediction';
import AbstractApi from '@/api/mocks/AbstractApi';
import mockOrderPredictionResponseData from '@/api/mocks/data/orderPredictions';
import OrderPredictionsApi from '@/api/OrderPredictionsApi';
import {OrderPrediction} from '@/models/OrderPrediction';
import {Cart} from '@/modules/retailer/models/Cart';

export default class MockOrderPredictionsApiImpl extends AbstractApi implements OrderPredictionsApi {

    async generateOrderPrediction(
        _supplierId: string,
        _storeId: string,
        _deliveryDate: Date,
        _predictionEndDate: Date,
    ): Promise<OrderPrediction> {
        await this.sleep(3, 6);
        return toOrderPrediction(mockOrderPredictionResponseData);
    }

    async generateCart(
        _supplierIds: string[] | null,
        _storeId: string | null,
        _deliveryDate: Date | null,
        _predictionEndDate: Date | null,
        _skuIds?: string[],
    ): Promise<Cart[]> {
        await this.sleep(3, 6);
        return toCarts(mockOrderPredictionResponseData);
    }

    async getOrderPrediction(_id: string): Promise<OrderPrediction> {
        await this.sleep();
        return toOrderPrediction(mockOrderPredictionResponseData);
    }
}
