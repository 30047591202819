/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Geolocation } from './Geolocation';
// @ts-ignore
import {
// @ts-ignore
    GeolocationFromJSON,
// @ts-ignore
    GeolocationFromJSONTyped,
// @ts-ignore
    GeolocationToJSON,
} from './Geolocation';

/**
 * 
 * @export
 * @interface WarehouseAddress
 */
export interface WarehouseAddress {
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    warehouseId: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    building: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    street1: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    street2: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAddress
     */
    countryCode: string;
    /**
     * 
     * @type {Geolocation}
     * @memberof WarehouseAddress
     */
    location: Geolocation;
}

/**
 * Check if a given object implements the WarehouseAddress interface.
 */
// @ts-ignore
export function instanceOfWarehouseAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "warehouseId" in value;
    isInstance = isInstance && "building" in value;
    isInstance = isInstance && "street1" in value;
    isInstance = isInstance && "street2" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

// @ts-ignore
export function WarehouseAddressFromJSON(json: any): WarehouseAddress {
    return WarehouseAddressFromJSONTyped(json, false);
}

// @ts-ignore
export function WarehouseAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): WarehouseAddress {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'warehouseId': json['warehouse_id'],
        'building': json['building'],
        'street1': json['street1'],
        'street2': json['street2'],
        'postalCode': json['postal_code'],
        'city': json['city'],
        'region': json['region'],
        'countryCode': json['country_code'],
        'location': GeolocationFromJSON(json['location']),
    };
}

// @ts-ignore
export function WarehouseAddressToJSON(value?: WarehouseAddress | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'warehouse_id': value.warehouseId,
        'building': value.building,
        'street1': value.street1,
        'street2': value.street2,
        'postal_code': value.postalCode,
        'city': value.city,
        'region': value.region,
        'country_code': value.countryCode,
        'location': GeolocationToJSON(value.location),
    };
}

