import {OrderPredictionItem, OrderPredictionResponse} from '@/gen/api';

const mockOrderPredictionItemsData: OrderPredictionItem[] = [
    /* Motorcycle Parts */
    {
        productId: '199e00c7-ad6a-4a8a-9e00-e14268d088c1',
        skuId: '91288228-684c-41b5-862f-a852d22f86a7',
        supplierId: '60b34a03-6972-4758-af0c-5daa4da701b4',
        predictedSales: 3,
        currentStock: 1,
        minimumStock: 3,
        inTransit: 0,
    },
    {
        productId: 'dadfd7b0-7f14-4778-b057-04932bd92422',
        skuId: '9fd0183e-c2b9-4e32-bafe-76b1fd3b18d1',
        supplierId: '60b34a03-6972-4758-af0c-5daa4da701b4',
        predictedSales: 1,
        currentStock: 0,
        minimumStock: 2,
        inTransit: 0,
    },
    {
        productId: 'bde7f3d5-fddb-4b10-a19a-5554cf8f879c',
        skuId: '407e63bc-106f-4c02-9c1d-9c6912cf99ae',
        supplierId: '60b34a03-6972-4758-af0c-5daa4da701b4',
        predictedSales: 5,
        currentStock: 3,
        minimumStock: 2,
        inTransit: 0,
    },
    {
        productId: 'e3dd43c5-790c-4e7e-b36e-28a7cfb259e7',
        skuId: 'be0bf339-c345-4c9e-81de-5aa1d975a3c5',
        supplierId: '60b34a03-6972-4758-af0c-5daa4da701b4',
        predictedSales: 5,
        currentStock: 2,
        minimumStock: 3,
        inTransit: 1,
    },
    {
        productId: '88415560-8db8-4f43-a251-ff26a9a27470',
        skuId: '88415560-8db8-4f43-a251-ff26a9a27470',
        supplierId: '60b34a03-6972-4758-af0c-5daa4da701b4',
        predictedSales: 10,
        currentStock: 5,
        minimumStock: 3,
        inTransit: 1,
    },
    /* Fashion */
    {
        productId: '91b47729-ef05-4fb8-8a72-06ee7f154e75',
        skuId: '3c35aa89-34c3-483c-861e-ab97501ccf76',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 25,
        currentStock: 10,
        minimumStock: 2,
        inTransit: 2,
    },
    {
        productId: 'aa247dea-d937-4f49-97a6-6473a8aabfec',
        skuId: 'c99b337e-62c8-4c33-bb4a-2fe7a54cb647',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 21,
        currentStock: 8,
        minimumStock: 2,
        inTransit: 2,
    },
    {
        productId: '690fb964-70a6-499f-a246-f4caea2cf520',
        skuId: 'b22c104f-cc46-44cc-b2ba-c7a523e65c82',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 28,
        currentStock: 6,
        minimumStock: 2,
        inTransit: 2,
    },
    {
        productId: 'dcb403e5-f68f-4645-882c-5d5133e69e40',
        skuId: '5d9a133d-7948-4ca8-90b4-d4bcb78ceca2',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 10,
        currentStock: 3,
        minimumStock: 3,
        inTransit: 2,
    },
    {
        productId: 'de59e54a-4fc8-42a6-af5b-ad46770e6236',
        skuId: '2e38c895-0fd9-44f4-b4ab-67f7a46aaa77',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 15,
        currentStock: 10,
        minimumStock: 5,
        inTransit: 0,
    },
    {
        productId: '34a38b41-5d76-4b11-9dcc-cdfcb696261a',
        skuId: 'a6ccec7b-cb0c-4b8e-8bcb-880f00c20af8',
        supplierId: 'dceb85f7-a4c7-4e43-ad33-259b8de3c760',
        predictedSales: 22,
        currentStock: 18,
        minimumStock: 5,
        inTransit: 0,
    },
    /* Bolts and screws */
    {
        productId: '2f6683d5-d942-4d58-9613-1842fc9dbe46',
        skuId: '0050cddc-df14-4958-b940-7639e1a64739',
        supplierId: 'cf1298da-6718-450f-9b40-52c3d5378c9d',
        predictedSales: 35,
        currentStock: 10,
        minimumStock: 2,
        inTransit: 2,
    },
    {
        productId: '68754613-0237-498d-ac44-713a147e554d',
        skuId: '2e251c6c-80b8-492e-a56e-164593a7a531',
        supplierId: 'cf1298da-6718-450f-9b40-52c3d5378c9d',
        predictedSales: 32,
        currentStock: 18,
        minimumStock: 2,
        inTransit: 5,
    },
    {
        productId: '4895c3b0-4684-4d84-a39c-ef787e81d5ef',
        skuId: '37bcea7c-c349-4cba-86e9-8b906ed8579a',
        supplierId: 'cf1298da-6718-450f-9b40-52c3d5378c9d',
        predictedSales: 28,
        currentStock: 6,
        minimumStock: 2,
        inTransit: 1,
    },
    {
        productId: '734c327d-b598-4b95-b0f8-40127301be34',
        skuId: '0d69b623-7e02-4585-8af9-17d646b7607b',
        supplierId: 'cf1298da-6718-450f-9b40-52c3d5378c9d',
        predictedSales: 20,
        currentStock: 4,
        minimumStock: 3,
        inTransit: 2,
    },
    /* Food and beverages */
    {
        productId: '426a5864-77d2-495d-98d4-d501e6832ffe',
        skuId: 'd2aeb38a-3cce-407c-8804-e1fde99b023a',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 65,
        currentStock: 10,
        minimumStock: 3,
        inTransit: 10,
    },
    {
        productId: '093be640-c2c9-4cd9-83e1-ebf3bd448d18',
        skuId: '095c097a-f681-4bbd-be71-ccea0aeec241',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 61,
        currentStock: 8,
        minimumStock: 3,
        inTransit: 3,
    },
    {
        productId: 'dd014f2f-4da5-4237-b2c3-ce1c747c6669',
        skuId: 'f40f1837-7b24-46d0-8b15-c4aa5974d3c5',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 28,
        currentStock: 6,
        minimumStock: 3,
        inTransit: 3,
    },
    {
        productId: 'b8567518-6489-4089-8d6e-728d011c09e0',
        skuId: '1a7ecd59-bc95-4a6f-a45e-aee8b4381df1',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 20,
        currentStock: 12,
        minimumStock: 3,
        inTransit: 3,
    },
    {
        productId: 'a8a7640b-8924-4f89-a36d-72b232566e8b',
        skuId: '476aa1f0-2446-4c35-86ce-eae16f470abf',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 35,
        currentStock: 10,
        minimumStock: 3,
        inTransit: 3,
    },
    {
        productId: '07d4cec9-e831-46b2-b0c5-df3bf79942ec',
        skuId: '84689b4e-6a17-49bf-b3e5-dc8f81781e44',
        supplierId: 'd013f936-7a69-4a80-84ea-f67b11b2032a',
        predictedSales: 32,
        currentStock: 18,
        minimumStock: 3,
        inTransit: 3,
    },
];

const mockOrderPredictionResponseData: OrderPredictionResponse = {
    predictionId: 'c13dbb70-d012-4fc9-847c-aa7fb926560a',
    predictionDate: new Date('2024-02-01'),
    storeId: '91576fcf-7d27-4218-8a4c-fad172f1fdb2',
    deliveryDate: new Date('2024-02-15'),
    predictionEndDate: new Date('2024-02-20'),
    items: mockOrderPredictionItemsData,
};

export default mockOrderPredictionResponseData;
