/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { exists, mapValues } from '../runtime';
// @ts-ignore
import type { Status } from './Status';
// @ts-ignore
import {
// @ts-ignore
    StatusFromJSON,
// @ts-ignore
    StatusFromJSONTyped,
// @ts-ignore
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * 
     * @type {Status}
     * @memberof Organization
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    timezone: string;
    /**
     * 
     * @type {Date}
     * @memberof Organization
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the Organization interface.
 */
// @ts-ignore
export function instanceOfOrganization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "timezone" in value;

    return isInstance;
}

// @ts-ignore
export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

// @ts-ignore
export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
// @ts-ignore
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'status': StatusFromJSON(json['status']),
        'countryCode': json['country_code'],
        'timezone': json['timezone'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

// @ts-ignore
export function OrganizationToJSON(value?: Organization | null): any {
// @ts-ignore
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': StatusToJSON(value.status),
        'country_code': value.countryCode,
        'timezone': value.timezone,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

